import React from 'react';
import { Stack } from '@mui/material';

import { DateTimeFormat } from '@ocx-app/lib/date/date.types';
import { formatDateTime } from '@ocx-app/lib/date/date';
import { SizingMultiplier, Typography, Paper } from '@ocx/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoyaltyCurrencyChange } from '@ocx/feat-loyalty-currency';

import { PointsChangeReasonCard } from '../components/points-change-reason-card';

export type PointsChangeReceiptProps = {
  quantity: number;
  date: string;
  reason: string;
};

export const PointsChangeReceipt: React.FC<PointsChangeReceiptProps> = (props) => {
  const { quantity, date, reason } = props;

  return (
    <Stack spacing={SizingMultiplier.sm} p={SizingMultiplier.sm}>
      <Paper sx={{ p: SizingMultiplier.md, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LoyaltyCurrencyChange
          points={quantity}
          template="value-icon"
          iconSize="large"
          iconVariant="multiple"
          typographyProps={{ variant: 'h2' }}
        />
        <Typography textAlign="center" variant="body2" component="span" color="text.disabled">
          {formatDateTime(new Date(date), DateTimeFormat.DateTimeFull)}
        </Typography>
      </Paper>
      <PointsChangeReasonCard title={reason} />
    </Stack>
  );
};
