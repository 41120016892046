import React, { useMemo } from 'react';

import { MembershipContextValue, MembershipContext } from './membership.context';
import { useGetMembershipQuery } from '../useGetMembershipQuery';

import type { ReactNode } from 'react';

export type MembershipProviderProps = {
  children: ReactNode;
};

export const MembershipProvider = ({ children }: MembershipProviderProps) => {
  const { membership, loading, refetch } = useGetMembershipQuery();

  const contextValue = useMemo<MembershipContextValue>(
    () => ({
      membership,
      refetch,
      loading,
    }),
    [loading, membership, refetch],
  );

  return <MembershipContext.Provider value={contextValue}>{children}</MembershipContext.Provider>;
};
