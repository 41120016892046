import { Box, CircularProgress, Stack } from '@mui/material';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import IosShareOutlined from '@mui/icons-material/IosShareOutlined';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { Button, SizingMultiplier, Typography } from '@ocx/ui';
import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';

import referralIllustration from './assets/refer-a-friend-illustration.svg';
import { referAFriendMessages } from './refer-a-friend.messages';

export type ReferAFriendProps = {
  loading: boolean;
  canShare: boolean | null;
  instructions: string;
  onCopyLinkClick: () => void;
  onShareLinkClick: () => void;
  onCloseClick: () => void;
};

export const ReferAFriend = (props: ReferAFriendProps) => {
  const { loading, instructions, canShare, onShareLinkClick, onCopyLinkClick, onCloseClick } = props;
  const shouldShowSharingButtons = canShare !== null && instructions;
  return (
    <>
      <Box
        component="img"
        width="100%"
        src={referralIllustration}
        alt=""
        sx={(theme) => ({
          borderTopRightRadius: SizingMultiplier.xl * theme.shape.borderRadius,
          borderTopLeftRadius: SizingMultiplier.xl * theme.shape.borderRadius,
        })}
      />
      {loading ? (
        <Box m="auto">
          <CircularProgress sx={{ m: SizingMultiplier.xl }} />
        </Box>
      ) : (
        <Stack p={SizingMultiplier.lg} spacing={SizingMultiplier.md}>
          <Box>
            <Typography variant="h5" component="h2" gutterBottom>
              <FormattedMessage {...referAFriendMessages.modalTitle} />
            </Typography>
            <Typography variant="body2">{instructions}</Typography>
          </Box>
          {shouldShowSharingButtons &&
            (canShare ? (
              <Button onClick={onShareLinkClick} endIcon={<IosShareOutlined />}>
                <FormattedMessage {...referAFriendMessages.shareLinkButtonTitle} />
              </Button>
            ) : (
              <Button onClick={onCopyLinkClick} endIcon={<ContentCopyOutlined />}>
                <FormattedMessage {...referAFriendMessages.copyLinkButtonTitle} />
              </Button>
            ))}
          <Button variant="text" onClick={onCloseClick}>
            <FormattedMessage {...buttonTextMessages['button-text:close']} />
          </Button>
        </Stack>
      )}
    </>
  );
};
