import { Radio, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';

import {
  Typography,
  List,
  ListItemAction,
  ListItemButton,
  ListItemStartIcon,
  ListItemText,
  Button,
  WalletSizeLimitHint,
  IconButton,
} from '@ocx/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { getPaymentInstrumentListItemInfo } from '@ocx/data-payment-instruments';
import { AlertCircleIcon } from '@ocx/ui-icons';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import {
  messages as walletMessages,
  paymentInstrumentBalanceLabel,
  paymentInstrumentExpiredMessages,
} from '../../../modules/wallet/wallet.messages';
import { messages } from './payment-method-selector-popup.messages';
import { PaymentMethodSelectorPopupProps } from './payment-method-selector-popup.types';

export const PaymentMethodSelectorPopupController: React.FC<PaymentMethodSelectorPopupProps> = (props) => {
  const walletSizeLimit = useConfiguration('wallet.sizeLimit');
  const modal = useModal();
  const { paymentInstrumentsListSelector, onAddPaymentMethodClick } = props;
  const { listItems, selectedListItem, handleSelect } = paymentInstrumentsListSelector;

  const paymentInstruments = useMemo(
    () =>
      listItems.map((instrument) => {
        const isSelected = instrument.uuid === selectedListItem?.uuid;
        const { hiddenCardNumber, displayName, formattedBalance } = getPaymentInstrumentListItemInfo(instrument);
        const secondaryText = formattedBalance ? (
          <FormattedMessage
            {...paymentInstrumentBalanceLabel['payments:payment-instrument:balance']}
            values={{ balance: formattedBalance }}
          />
        ) : (
          hiddenCardNumber
        );

        const onItemClick = () => {
          if (instrument.isExpired) {
            return;
          }
          handleSelect(instrument);
          modal.hide();
        };

        return (
          <ListItemButton
            key={instrument.uuid}
            disableRipple={instrument.isExpired}
            onClick={onItemClick}
            selected={isSelected}>
            {instrument.paymentType && (
              <ListItemStartIcon>
                <PaymentInstrumentIcon type={instrument.paymentType} />
              </ListItemStartIcon>
            )}
            <ListItemText
              secondary={
                instrument.isExpired ? (
                  <Typography variant="body2" color="error">
                    <FormattedMessage {...paymentInstrumentExpiredMessages.paymentInstrumentExpiredErrorMessages} />
                  </Typography>
                ) : (
                  secondaryText
                )
              }>
              {displayName}
            </ListItemText>
            <ListItemAction>
              {instrument.isExpired ? (
                <IconButton disabled>
                  <AlertCircleIcon color="error" />
                </IconButton>
              ) : (
                <Radio checked={isSelected} />
              )}
            </ListItemAction>
          </ListItemButton>
        );
      }),
    [handleSelect, listItems, modal, selectedListItem?.uuid],
  );

  return (
    <ModalDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <Stack spacing={SizingMultiplier.md} p={SizingMultiplier.lg}>
        <Typography variant="h5" textTransform="uppercase">
          <FormattedMessage {...messages['payment-method-selector-popup:title-text']} />
        </Typography>
        <List disabledPaddings>{paymentInstruments}</List>
        <Button onClick={onAddPaymentMethodClick} fullWidth>
          <FormattedMessage {...walletMessages['wallet:add-payment-instrument-button:title']} />
        </Button>
        <WalletSizeLimitHint sizeLimit={walletSizeLimit} />
      </Stack>
    </ModalDrawer>
  );
};
