import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePaymentProvider } from '@ocx/data-payment-provider';
import { ModalFullScreenDrawer, ModalFullScreenHeader } from '../components';
import { AddCardControllerProps, AddCardController } from '../../../pages/AddCard/AddCardController';
import { AddCardIFrameBasedController } from '../../../pages/AddCardIFrameBased/add-card-iframe-based.controller';

export type AddPaymentInstrumentPopupProps = {
  type: AddCardControllerProps['type'];
  onCreated?(params: { paymentInstrumentId: string | null }): void;
};

export const AddPaymentInstrumentPopupController = (props: AddPaymentInstrumentPopupProps) => {
  const { type, onCreated } = props;
  const paymentProvider = usePaymentProvider();

  const modal = useModal();
  const handleCreated = useCallback(
    (params: { paymentInstrumentId: string | null }) => {
      onCreated?.(params);
      modal.hide();
    },
    [modal, onCreated],
  );

  return (
    <ModalFullScreenDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <ModalFullScreenHeader onBackClick={modal.hide} />
      {paymentProvider.tokenizationMechanism === 'SDK' && <AddCardController type={type} onCardAdded={handleCreated} />}
      {paymentProvider.tokenizationMechanism === 'IFRAME' && (
        <AddCardIFrameBasedController onCardAdded={handleCreated} />
      )}
    </ModalFullScreenDrawer>
  );
};
