import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const MapIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.32 2.0503L13.32 0.0502989H13.25C13.2035 0.0456205 13.1566 0.0456205 13.11 0.0502989H12.88H12.75H12.68L7 2.0003L1.32 0.0502989C1.16962 0.000710417 1.00961 -0.0124558 0.853139 0.0118837C0.696671 0.0362232 0.548219 0.0973726 0.420003 0.190299C0.290762 0.282315 0.185268 0.403762 0.112238 0.544606C0.039208 0.68545 0.000736954 0.841649 3.33354e-06 1.0003V15.0003C-0.000537828 15.2099 0.0648223 15.4145 0.186845 15.5849C0.308868 15.7554 0.481382 15.8832 0.680003 15.9503L6.68 17.9503C6.88145 18.016 7.09856 18.016 7.3 17.9503L13 16.0503L18.68 18.0003C18.7862 18.0147 18.8938 18.0147 19 18.0003C19.2091 18.0032 19.4132 17.9364 19.58 17.8103C19.7092 17.7183 19.8147 17.5968 19.8878 17.456C19.9608 17.3151 19.9993 17.1589 20 17.0003V3.0003C20.0005 2.79066 19.9352 2.58614 19.8132 2.41567C19.6911 2.2452 19.5186 2.11738 19.32 2.0503ZM6 15.6103L2 14.2803V2.3903L6 3.7203V15.6103ZM12 14.2803L8 15.6103V3.7203L12 2.3903V14.2803ZM18 15.6103L14 14.2803V2.3903L18 3.7203V15.6103Z" />
    </SvgIcon>
  );
};
