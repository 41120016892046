import { ApplePayCardNetwork, GooglePayCardNetwork } from '@ocx/graphql';
import { ApplePayLineItemType, ApplePayCardNetwork as ApplePayPluginCardNetwork } from '@ocx/cap-apple-pay';
import { GooglePayDisplayItemStatus, GooglePayCardNetwork as GooglePayPluginCardNetwork } from '@ocx/cap-google-pay';

import { PaymentItemStatus } from '../payment-provider.types';

export const googlePayPaymentStatusMap: Record<PaymentItemStatus, GooglePayDisplayItemStatus> = {
  FINAL: 'FINAL',
  PENDING: 'PENDING',
};

export const applePayPaymentStatusMap: Record<PaymentItemStatus, ApplePayLineItemType> = {
  FINAL: 'final',
  PENDING: 'pending',
};

export const applePayCardNetworkMap: Record<ApplePayCardNetwork, ApplePayPluginCardNetwork> = {
  [ApplePayCardNetwork.Amex]: 'amex',
  [ApplePayCardNetwork.CartesBancaires]: 'cartesBancaires',
  [ApplePayCardNetwork.ChinaUnionPay]: 'chinaUnionPay',
  [ApplePayCardNetwork.Discover]: 'discover',
  [ApplePayCardNetwork.Eftpos]: 'eftpos',
  [ApplePayCardNetwork.Electron]: 'electron',
  [ApplePayCardNetwork.Elo]: 'elo',
  [ApplePayCardNetwork.Interac]: 'interac',
  [ApplePayCardNetwork.Jcb]: 'jcb',
  [ApplePayCardNetwork.Mada]: 'mada',
  [ApplePayCardNetwork.Maestro]: 'maestro',
  [ApplePayCardNetwork.MasterCard]: 'masterCard',
  [ApplePayCardNetwork.Mastercard]: 'masterCard',
  [ApplePayCardNetwork.PrivateLabel]: 'privateLabel',
  [ApplePayCardNetwork.Visa]: 'visa',
  [ApplePayCardNetwork.VPay]: 'vPay',
};

export const googlePayCardNetworkMap: Record<GooglePayCardNetwork, GooglePayPluginCardNetwork> = {
  [GooglePayCardNetwork.Amex]: 'AMEX',
  [GooglePayCardNetwork.Discover]: 'DISCOVER',
  [GooglePayCardNetwork.Electron]: 'ELECTRON',
  [GooglePayCardNetwork.Elo]: 'ELO',
  [GooglePayCardNetwork.EloDebit]: 'ELO_DEBIT',
  [GooglePayCardNetwork.Interac]: 'INTERAC',
  [GooglePayCardNetwork.Jcb]: 'JCB',
  [GooglePayCardNetwork.Maestro]: 'MAESTRO',
  [GooglePayCardNetwork.Mastercard]: 'MASTERCARD',
  [GooglePayCardNetwork.Visa]: 'VISA',
};
