import { GooglePayButtonColor, GooglePayEnvironmentNative, GooglePayEnvironmentWeb } from './google-pay-plugin.types';

export const GPAY_SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js';

export const googlePayEnvironmentWebToNativeMap: Record<GooglePayEnvironmentWeb, GooglePayEnvironmentNative> = {
  TEST: 3,
  PRODUCTION: 1,
};

export const GOOGLE_PAY_TRANSACTION_CANCELED_CODE_WEB = 'CANCELED';
export const GOOGLE_PAY_TRANSACTION_CANCELED_CODE_NATIVE = '100';

export const MAX_LOAD_ATTEMPTS = 10;
export const LOAD_ATTEMPT_TIMEOUT_MS = 1000;

export const DEFAULT_ANDROID_BUTTON_COLOR: GooglePayButtonColor = 'black';
