import { Navigate } from 'react-router-dom';

import { RouteLoading } from '@ocx/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RedirectToRequestLink } from '@ocx-app/pages/Signup/RedirectToRequestLink';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';

import { useMembershipContext } from './membership.context';
import { Membership } from '../membership.types';

import type { ReactNode } from 'react';

export type MembershipGuardPros = {
  children: ReactNode;
  _isRedirectRequired?(membership: Membership): boolean;
  redirectToPath?: string;
};

export const MembershipGuard = (props: MembershipGuardPros) => {
  const { children, _isRedirectRequired, redirectToPath = AppRoutePath.Home } = props;
  const { membership, loading } = useMembershipContext();

  if (loading && !membership) {
    return <RouteLoading />;
  }

  if (!membership) {
    return <RedirectToRequestLink />;
  }

  if (_isRedirectRequired?.(membership)) {
    return <Navigate to={redirectToPath} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
