import { addBreadcrumb } from '@sentry/react';

import { ParPayTokenizationSession } from '@ocx/graphql';

import { PaymentProviderIFrameBasedInterface, PaymentProviderIFrameBased } from '../payment-provider-iframe-based';
import { ParPayCardType, ParPaySessionGetter, TokenizationResponseCode } from './parpay-payment-provider.types';
import {
  PaymentProviderIFrameBasedAddEventListenersParams,
  PaymentProviderIFrameBasedRenderParams,
  PaymentProviderIFrameBasedSubmitParams,
} from '../payment-provider-iframe-based/payment-provider-iframe-based.types';
import { cartTypePaymentInstrumentTypeMap, IFrameMessages } from './parpay-payment-provider.constants';
import { PaymentProviderSDKBasedApplePayConstructorParams } from '../payment-provider-sdk-based/payment-provider-sdk-based.apple-pay';
import { PaymentProviderSDKBasedGooglePayConstructorParams } from '../payment-provider-sdk-based/payment-provider-sdk-based.google-pay';

export class ParPayPaymentProvider extends PaymentProviderIFrameBased implements PaymentProviderIFrameBasedInterface {
  public tokenizationMechanism = 'IFRAME' as const;

  private getParPaySession: ParPaySessionGetter;
  private parPaySession: ParPayTokenizationSession | null = null;
  private eventHandler: ((event: MessageEvent) => void) | null = null;

  constructor(params: {
    getParPaySession: ParPaySessionGetter;
    applePay: PaymentProviderSDKBasedApplePayConstructorParams | null;
    googlePay: PaymentProviderSDKBasedGooglePayConstructorParams | null;
  }) {
    super({
      applePay: params.applePay,
      googlePay: params.googlePay,
    });
    this.getParPaySession = params.getParPaySession;
  }

  async render(params: PaymentProviderIFrameBasedRenderParams) {
    const { containerId, iFrameId } = params;

    const container = document.getElementById(containerId);
    if (!container) {
      throw new Error(`[ParPayPaymentProvider] Container with id ${containerId} not found`);
    }

    const existingIframe = document.getElementById(iFrameId);
    if (existingIframe) {
      existingIframe.parentNode?.removeChild(existingIframe);
    }

    const frame = document.createElement('iframe');
    frame.id = iFrameId;

    // Update iframe styles
    frame.style.border = 'none';
    frame.style.width = '100%';

    const parPaySession = await this.getParPaySession();
    frame.src = parPaySession.iframeUrl;
    container.appendChild(frame);

    this.parPaySession = parPaySession;
  }

  removeEventListeners() {
    if (this.eventHandler) {
      window.removeEventListener('message', this.eventHandler);
      this.eventHandler = null;
    }
  }

  addEventListeners(params: PaymentProviderIFrameBasedAddEventListenersParams) {
    this.removeEventListeners();
    this.eventHandler = (event: MessageEvent) => {
      this.handleIFrameMessage({
        event,
        iFrameId: params.iFrameId,
        onPaymentInstrumentTokenized: params.onPaymentInstrumentTokenized,
        onSubmitError: params.onSubmitError,
        onValidationError: params.onValidationError,
        onIFrameLoaded: params.onIFrameLoaded,
        onIsSubmitButtonEnabled: params.onIsSubmitButtonEnabled,
      });
    };
    window.addEventListener('message', this.eventHandler);
  }

  async submit(params: PaymentProviderIFrameBasedSubmitParams): Promise<void> {
    if (!this.parPaySession) {
      throw new Error(`[ParPayPaymentProvider] ParPay session not initialized`);
    }

    const { iFrameId } = params;

    const frame = document.getElementById(iFrameId) as HTMLIFrameElement | null;
    if (!frame) {
      throw new Error(`[ParPayPaymentProvider] iFrame with id ${iFrameId} not found`);
    }
    if (!frame.contentWindow) {
      throw new Error(`[ParPayPaymentProvider] iFrame.contentWindow not found`);
    }

    // post message into Iframe to start tokenization
    const iFrameURL = new URL(this.parPaySession.iframeUrl);
    const iFrameOrigin = iFrameURL.origin;
    frame.contentWindow.postMessage('aurus-token', iFrameOrigin);
  }

  private handleIFrameMessage(params: {
    iFrameId: string;
    event: MessageEvent;
    onPaymentInstrumentTokenized: PaymentProviderIFrameBasedAddEventListenersParams['onPaymentInstrumentTokenized'];
    onSubmitError: PaymentProviderIFrameBasedAddEventListenersParams['onSubmitError'];
    onValidationError: PaymentProviderIFrameBasedAddEventListenersParams['onValidationError'];
    onIFrameLoaded: PaymentProviderIFrameBasedAddEventListenersParams['onIFrameLoaded'];
    onIsSubmitButtonEnabled: PaymentProviderIFrameBasedAddEventListenersParams['onIsSubmitButtonEnabled'];
  }) {
    if (!this.parPaySession) {
      return params.onSubmitError('[ParPayPaymentProvider] ParPay session not initialized');
    }

    const iFrameURL = new URL(this.parPaySession.iframeUrl);
    if (iFrameURL.origin !== params.event.origin) {
      return params.onSubmitError('[ParPayPaymentProvider] Unknown message origin');
    }

    if (params.event.data.startsWith(IFrameMessages.iFrameLoaded)) {
      params.onIFrameLoaded();
      return;
    }

    if (params.event.data.startsWith(IFrameMessages.iFrameHeight)) {
      const value = params.event.data.split('=')[1];
      const iFrame = document.getElementById(params.iFrameId) as HTMLIFrameElement;
      if (!iFrame) {
        return params.onSubmitError(`[ParPayPaymentProvider] iFrame with id ${params.iFrameId} not found`);
      }
      iFrame.style.height = value;
      return undefined;
    }

    if (params.event.data.startsWith(IFrameMessages.submitButtonState)) {
      const splt = params.event.data.split('=');
      const json = splt[1];
      const enabled = json === 'true';
      params.onIsSubmitButtonEnabled(enabled);
      return;
    }

    if (params.event.data.startsWith(IFrameMessages.validation)) {
      const validation: {
        isValid: boolean;
        field?: 'cardNumber' | 'expMonth' | 'expYear' | 'cvv';
        errorType?: 'blank' | 'invalid';
        errorMsg?: string;
      } = JSON.parse(params.event.data.split('=')[1]);
      return params.onValidationError?.(validation.errorMsg || 'Validation not passed');
    }

    if (params.event.data.startsWith(IFrameMessages.tokenizationResponse)) {
      const response: {
        response_code: TokenizationResponseCode;
        response_text: string;
        masked_card_num: string;
        card_type: ParPayCardType;
        card_expiry_date: string;
        one_time_token: string;
        card_holder_name: string;
      } = JSON.parse(params.event.data.split('=')[1]);

      if (response.response_code !== TokenizationResponseCode.SUCCESS) {
        return params.onSubmitError(response.response_text);
      }

      return params.onPaymentInstrumentTokenized({
        sessionId: this.parPaySession.sessionId,
        oneTimeToken: response.one_time_token,
        maskedCardNumber: response.masked_card_num,
        cardType: cartTypePaymentInstrumentTypeMap[response.card_type],
        cardExpirationDate: response.card_expiry_date,
      });
    }

    addBreadcrumb({
      message: '[ParPayPaymentProvider] unhandled IFrame message',
    });

    return undefined;
  }
}
