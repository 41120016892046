import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { QRCodeIcon, TabBarHomeIcon, TabBarPayAtPumpIcon } from '@ocx/ui-icons';
import { useStartPayAtPump } from './useStartPayAtPump';
import { useGeolocation } from '../../lib/geolocation/useGeolocation';
import { useStartPayInside } from './useStartPayInside';
import { useConfiguration } from '../configuration/useConfiguration';
import { BottomNavigationTab } from '../../components/navigation/BottomNavigation';
import { messages } from './messages';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { AppRoutePath } from '../../pages/AppRoutePath';
import { SurveysTabBarIconWithBadgeController } from './SurveysTabBarIconWithBadge.controller';

export type UseBottomNavigationController = (params: { hasEmail: boolean }) => {
  tabs: BottomNavigationTab[];
};
export const useBottomNavigationController: UseBottomNavigationController = ({ hasEmail }) => {
  const intl = useIntl();
  const { refreshState: refreshGeolocation } = useGeolocation({ requestPermissions: true });
  const {
    config: { payAtPump, payInside, wallet },
  } = useConfiguration();

  const { isEmailCompleted, isPayAtPumpEnabled, isPayInsideEnabled } = useMemo(
    () => ({
      isEmailCompleted: hasEmail,
      isPayAtPumpEnabled: payAtPump.enabled,
      isPayInsideEnabled: payInside.enabled && wallet.enabled,
    }),
    [hasEmail, payAtPump.enabled, payInside.enabled, wallet.enabled],
  );

  const startPayAtPump = useStartPayAtPump({ refreshGeolocation, isEmailCompleted });
  const startPayInside = useStartPayInside({ isEmailCompleted });
  const { pushToSurveys, pushToHome } = useGetNavigationActions();

  return useMemo(() => {
    const tabs: BottomNavigationTab[] = [
      {
        key: 'tabBar:homeButtonText',
        label: intl.formatMessage(messages['tabBar:homeButtonText']),
        Icon: TabBarHomeIcon,
        routerPath: AppRoutePath.Home,
        onClick: pushToHome,
      },
      {
        key: 'tabBar:surveysButtonText',
        label: intl.formatMessage(messages['tabBar:surveysButtonText']),
        Icon: SurveysTabBarIconWithBadgeController,
        onClick: pushToSurveys,
        routerPath: AppRoutePath.Surveys,
      },
    ];

    if (isPayAtPumpEnabled) {
      tabs.push({
        key: 'tabBar:payAtPumpButtonText',
        label: intl.formatMessage(messages['tabBar:payAtPumpButtonText']),
        Icon: TabBarPayAtPumpIcon,
        onClick: startPayAtPump,
        routerPath: AppRoutePath.PayAtPump,
      });
    }
    if (isPayInsideEnabled) {
      tabs.push({
        key: 'tabBar:payInsideButtonText',
        label: intl.formatMessage(messages['tabBar:payInsideButtonText']),
        Icon: QRCodeIcon,
        onClick: startPayInside,
        routerPath: AppRoutePath.PayInside,
      });
    }
    return { tabs };
  }, [intl, isPayAtPumpEnabled, isPayInsideEnabled, pushToHome, pushToSurveys, startPayAtPump, startPayInside]);
};
