import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePaymentProvider, RequestDigitalWalletPaymentReturns } from '@ocx/data-payment-provider';
import {
  PrimaryTransactionStatus,
  StartTransactionMutation,
  TransactionType,
  useStartTransactionMutation,
  useStorePumpInfoQuery,
} from '@ocx/graphql';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PAYMENT_INSTRUMENT_TYPE, PaymentInstrument } from '@ocx/data-payment-instruments';
import { sanitizeStorePumpInfo } from '../../../../modules/stores/utils';
import { IStoreCarWashOption, IStorePumpInfo } from '../../../../modules/stores/types';
import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';
import { useLoader } from '../../../../lib/loader/LoaderProvider';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { buildCarWashInput, buildPaymentItems } from './utils';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import { useCarWashController } from './useCarWashController';
import { usePaymentInstrumentsController } from './usePaymentInstrumentsController';
import { buildTransactionStartInput, getKountSessionId } from '../../../../modules/transaction/utils';
import { ListSelector } from '../../../../hooks/useListController';
import { useWalletPinCodePopup } from '../../../../components/modals';

export type UseSelectPumpControllerReturns = {
  // region | Pump info
  selectedPump: string | null;
  shouldPrintReceipt: boolean;
  carWashListController: ListSelector<IStoreCarWashOption>;
  selectPump(pump: string): void;
  deselectPump(): void;
  togglePrintReceipt(value?: boolean): void;
  // endregion
  // region | Payment instruments
  paymentInstrumentsListSelector: ListSelector<PaymentInstrument>;
  isCardsAvailable: boolean;
  isPaymentInstrumentsLoading: boolean;
  selectedPaymentInstrumentType: PAYMENT_INSTRUMENT_TYPE | null;
  handlePurchase(type: PAYMENT_INSTRUMENT_TYPE, paymentInstrumentId: string): void;
  isWalletSizeLimitReached: boolean;
  isWalletEnabled: boolean;
  // endregion
  // region | Common
  loading: boolean;
  store: IStorePumpInfo | null;
  // endregion
};

export type UseSelectPumpControllerParams = {
  onSiteLoadFailed: () => void;
  onError?: (error: unknown) => void;
};

export const useSelectPumpController = ({
  onSiteLoadFailed,
  onError,
}: UseSelectPumpControllerParams): UseSelectPumpControllerReturns => {
  const intl = useIntl();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { turnLoader } = useLoader();
  const { triggerEvent } = useRudderStack();
  const { replaceToFuelingStatus } = useGetNavigationActions();
  const { open: openWalletPinCodePopup } = useWalletPinCodePopup();
  const paymentProvider = usePaymentProvider();

  const { storeId = '' } = useParams<{ storeId: string }>();

  const [selectedPump, setSelectedPump] = useState<string | null>(null);
  const [shouldPrintReceipt, setShouldPrintReceipt] = useState(true);

  const togglePrintReceipt = useCallback(
    (value = !shouldPrintReceipt) => setShouldPrintReceipt(value),
    [shouldPrintReceipt],
  );

  const onTransactionStarted = useCallback(
    (data: StartTransactionMutation) => {
      if (data.transaction === null || data.transaction.primaryStatus === PrimaryTransactionStatus.Failed) {
        showGenericErrorSnackbar();
        return;
      }
      replaceToFuelingStatus(data.transaction.uuid);
    },
    [replaceToFuelingStatus, showGenericErrorSnackbar],
  );

  const { data, loading } = useStorePumpInfoQuery({ variables: { id: storeId }, onError: onSiteLoadFailed });
  const [startTransaction] = useStartTransactionMutation({ onCompleted: onTransactionStarted });
  const store = data?.stores?.edges?.[0]?.node;

  const storePumpInfo = useMemo<UseSelectPumpControllerReturns['store']>(
    () => sanitizeStorePumpInfo(intl, store),
    [intl, store],
  );

  const rawCarWashOptions = useMemo(() => storePumpInfo?.carWashOptions || [], [storePumpInfo?.carWashOptions]);
  const { carWashListController } = useCarWashController({
    rawCarWashOptions,
  });

  const paymentItems = useMemo(
    () => buildPaymentItems({ selectedCarWashOption: carWashListController.selectedListItem }),
    [carWashListController.selectedListItem],
  );

  const selectPump = useCallback<UseSelectPumpControllerReturns['selectPump']>(
    (pumpNumber) => {
      triggerEvent('pay_at_pump_select_pump');
      setSelectedPump(pumpNumber);
    },
    [triggerEvent],
  );
  const deselectPump = useCallback<UseSelectPumpControllerReturns['deselectPump']>(
    () => setSelectedPump(null),
    [setSelectedPump],
  );

  const {
    paymentInstruments,
    paymentInstrumentsListSelector,
    selectedPaymentInstrumentType,
    isPaymentInstrumentsLoading,
    isCardsAvailable,
    isWalletSizeLimitReached,
    isWalletEnabled,
  } = usePaymentInstrumentsController();

  const handlePurchase = useCallback<UseSelectPumpControllerReturns['handlePurchase']>(
    async (type, paymentInstrumentId) => {
      try {
        // TODO: handle if (!storePumpInfo) return;
        if (!storePumpInfo) {
          return;
        }

        const paymentInstrument = paymentInstruments.find(({ uuid }) => uuid === paymentInstrumentId);
        if (!paymentInstrument) {
          return;
        }

        turnLoader(true);

        // region Digital Wallet payment
        let digitalWalletPayment: RequestDigitalWalletPaymentReturns = {
          token: '',
          nonce: null,
          canceled: false,
        };
        if (type === PAYMENT_INSTRUMENT_TYPE.APPLE_PAY) {
          digitalWalletPayment = await paymentProvider.requestApplePayPayment({ paymentItems });
        }
        if (type === PAYMENT_INSTRUMENT_TYPE.GOOGLE_PAY) {
          digitalWalletPayment = await paymentProvider.requestGooglePayPayment({ paymentItems });
        }

        if (digitalWalletPayment.canceled) {
          turnLoader(false);
          return;
        }
        // endregion

        const kountSessionId = await getKountSessionId();

        let pinCode: string | null = null;
        if (type === PAYMENT_INSTRUMENT_TYPE.CARD) {
          turnLoader(false);
          pinCode = await openWalletPinCodePopup();
          if (!pinCode) {
            return;
          }
        }

        try {
          turnLoader(true);
          const transactionInput = buildTransactionStartInput({
            transactionType: TransactionType.AtPump,
            // Fueling section
            siteId: storePumpInfo.siteId,
            locationId: storePumpInfo.id,
            fuelingPosition: selectedPump,
            carWash: buildCarWashInput(carWashListController.selectedListItem),
            // Payment sections
            token: digitalWalletPayment.token,
            nonce: digitalWalletPayment.nonce,
            pinCode,
            kountSessionId,
            paymentType: type,
            paymentInstrument,
            // Default car wash option is "No Car Wash".
            // We must always print receipt when car-wash is not "No Car Wash". Receipt is going to be printed even if we send false
            printReceipt: carWashListController.selectedListItem ? true : shouldPrintReceipt,
          });
          await startTransaction({
            variables: {
              input: transactionInput,
            },
          });
        } catch (e) {
          await digitalWalletPayment.cancelPayment?.();
          throw e;
        }

        await digitalWalletPayment.completePayment?.();
      } catch (error) {
        onError?.(error);
      } finally {
        turnLoader(false);
      }
    },
    [
      paymentProvider,
      storePumpInfo,
      paymentInstruments,
      turnLoader,
      paymentItems,
      openWalletPinCodePopup,
      selectedPump,
      carWashListController.selectedListItem,
      shouldPrintReceipt,
      startTransaction,
      onError,
    ],
  );

  return {
    // region | Pump info
    selectedPump,
    shouldPrintReceipt,
    carWashListController,
    selectPump,
    deselectPump,
    togglePrintReceipt,
    // endregion
    // region | Payment instruments
    paymentInstrumentsListSelector,
    selectedPaymentInstrumentType,
    isPaymentInstrumentsLoading,
    isCardsAvailable,
    handlePurchase,
    isWalletSizeLimitReached,
    isWalletEnabled,
    // endregion
    // region | Common
    loading,
    store: storePumpInfo,
    // endregion
  };
};
