import { Membership } from './membership.types';
import { isMembershipCustomDataValid } from './isMembershipCustomDataValid';
import { SanitizedCurrentCustomer } from './current-customer.dto';

export const getMembershipFromCurrentCustomer = ({
  uuid: customerId,
  hasPasscode,
  communicationPreference,
  membership,
}: SanitizedCurrentCustomer): Membership => {
  const { __typename, customData, homeStores, incomingReferral, ...fragment } = membership;

  return {
    ...fragment,
    incomingReferralStatus: incomingReferral?.status || null,
    customData: isMembershipCustomDataValid(customData) ? customData : null,
    customerId,
    firstName: fragment.firstName || '',
    lastName: fragment.lastName || '',
    // TODO: hasPasscode should be non nullable in the OCX GQL Schema
    hasPasscode: hasPasscode || false,
    hasEmail: Boolean(fragment.email),
    communicationPreference,
    homeStores: homeStores?.map((store) => store.locationUuid) ?? [],
    fuelAndConvenienceProfile: {
      restrictionVerification: fragment.fuelAndConvenienceProfile?.restrictionVerification,
      pendingVerification: fragment.fuelAndConvenienceProfile?.pendingVerification,
      memberDataShare: fragment.fuelAndConvenienceProfile?.memberDataShare,
      preferredFuelProductGroup: fragment.fuelAndConvenienceProfile?.preferredFuelProductGroup,
    },
    primaryAddress: fragment.primaryAddress
      ? {
          address1: fragment.primaryAddress.address1,
          address2: fragment.primaryAddress.address2,
          city: fragment.primaryAddress.city,
          state: fragment.primaryAddress.state,
          zipCode: fragment.primaryAddress.zipcode,
        }
      : null,
  };
};
