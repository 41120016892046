import localforage from 'localforage';
import { useMemo } from 'react';

import { useMembership } from './use-membership.hook';

export const appStateStorage = localforage.createInstance({
  name: 'Experience App State',
  storeName: 'ocx-app-state',
  description: 'Stores app state data and membership actions',
});

export const useMembershipStorage = () => {
  const { membership } = useMembership();
  return useMemo(() => {
    return {
      set: <T>(key: string, value: T): Promise<T> => {
        return appStateStorage.setItem(`${membership.id}:${key}`, value);
      },
      get: <T>(key: string): Promise<T | null> => {
        return appStateStorage.getItem(`${membership.id}:${key}`);
      },
    };
  }, [membership.id]);
};

export const useMembershipStorageNoMembershipHook = () => {
  return useMemo(() => {
    return {
      set: <T>(membershipId: string, key: string, value: T): Promise<T> => {
        return appStateStorage.setItem(`${membershipId}:${key}`, value);
      },
      get: <T>(membershipId: string, key: string): Promise<T | null> => {
        return appStateStorage.getItem(`${membershipId}:${key}`);
      },
    };
  }, []);
};
