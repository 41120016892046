import { TypePolicies } from '@apollo/client';
import { membershipTypePolicy } from '@ocx/data-membership';
import { awardTypePolicy } from './award.type-policy';
import { externalOffersTypePolicy } from './external-offers.type-policy';

import { surveyTypePolicy } from './survey.type-policy';

export const apolloCacheTypePolicies: TypePolicies = {
  Customer: {
    // Needed to make cache working properly
    keyFields: ['uuid'],
  },
  CommunicationPreference: {
    keyFields: false,
  },
  SiteSystemCarWashItem: {
    // SiteSystemCarWashItem does not have any ID fields for caching
    keyFields: ['productCode', 'posCode', 'posCodeModifier'],
  },
  LoyaltyEvent: { keyFields: ['eventType', 'createdAt'] },
  TotalAvailableRollback: { keyFields: ['locationUuid', 'fuelProductGroup', ['id']] },
  ...awardTypePolicy,
  ...membershipTypePolicy,
  ...externalOffersTypePolicy,
  ...surveyTypePolicy,
};
