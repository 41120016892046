import { useMemo } from 'react';

import { useMembership } from '@ocx/data-membership';
import { PinCodeAction } from './wallet-pin-code-popup.types';

import { useVerifyFlow } from './hooks/use-veify-flow';
import { useSetFlow } from './hooks/use-set-flow';
import { useGetDisplayTitle } from './hooks/use-get-display-title';

export type UsePinCodeControllerParams = {
  onComplete(pinCode: string | null): void;
};

export type UsePinCodeControllerReturns = {
  action: PinCodeAction;
  title: string;
  loading: boolean;
  validationError: string | null;
  handleSubmit(pinCode: string): Promise<void> | void;
  handleReset?(): Promise<void>;
};

export const usePinCodeController = (params: UsePinCodeControllerParams) => {
  const { onComplete } = params;
  const { membership } = useMembership();
  const initPinCodeAction = useMemo<PinCodeAction>(
    () => (membership.hasPasscode ? PinCodeAction.VERIFY : PinCodeAction.SET),
    [membership.hasPasscode],
  );

  const getDisplayTitle = useGetDisplayTitle();
  const verifyFlow = useVerifyFlow({ onComplete });
  const setFlow = useSetFlow({ onComplete });

  return useMemo<UsePinCodeControllerReturns>(() => {
    if (initPinCodeAction === PinCodeAction.VERIFY) {
      return {
        action: PinCodeAction.VERIFY,
        title: getDisplayTitle(PinCodeAction.VERIFY),
        loading: verifyFlow.loading,
        validationError: verifyFlow.validationError,
        handleSubmit: verifyFlow.handleSubmit,
        handleReset: verifyFlow.handleReset,
      };
    }
    return {
      action: setFlow.action,
      title: getDisplayTitle(setFlow.action),
      loading: setFlow.loading,
      validationError: setFlow.validationError,
      handleSubmit: setFlow.handleSubmit,
    };
  }, [
    getDisplayTitle,
    initPinCodeAction,
    setFlow.action,
    setFlow.handleSubmit,
    setFlow.loading,
    setFlow.validationError,
    verifyFlow.handleReset,
    verifyFlow.handleSubmit,
    verifyFlow.loading,
    verifyFlow.validationError,
  ]);
};
