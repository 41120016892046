import { ApplePayCardNetwork } from '@ocx/graphql';
import {
  ApplePay,
  ApplePaySessionGetter,
  ApplePayCardNetwork as ApplePayPluginCardNetwork,
  ApplePayLineItem,
} from '@ocx/cap-apple-pay';

import { RequestDigitalWalletPaymentReturns } from './payment-provider-sdk-based.types';
import { applePayCardNetworkMap } from './payment-provider-sdk-based.constants';

export type PaymentProviderSDKBasedApplePayConstructorParams = {
  merchantId: string;
  merchantName: string | null;
  supportedNetworks: ApplePayCardNetwork[];
  getApplePaySession: ApplePaySessionGetter;
};

export class PaymentProviderSDKBasedApplePay {
  private readonly options: {
    merchantId: string;
    merchantName: string | null;
    supportedNetworks: ApplePayPluginCardNetwork[];
  };

  private readonly getApplePaySession: ApplePaySessionGetter;

  constructor(params: PaymentProviderSDKBasedApplePayConstructorParams) {
    this.options = {
      merchantId: params.merchantId,
      merchantName: params.merchantName,
      supportedNetworks: params.supportedNetworks.map((network) => applePayCardNetworkMap[network]),
    };
    this.getApplePaySession = params.getApplePaySession;
  }

  async getIsAvailable() {
    const isAvailable = await ApplePay.canMakeRequest({
      supportedNetworks: this.options.supportedNetworks,
      merchantId: this.options.merchantId,
    });
    return isAvailable;
  }

  async requestPayment(params: { lineItems: ApplePayLineItem[] }): Promise<RequestDigitalWalletPaymentReturns> {
    const result = await ApplePay.showPaymentRequest({
      requestInfo: {
        lineItems: params.lineItems,
        supportedNetworks: this.options.supportedNetworks,
        merchantName: this.options.merchantName || '',
      },
      configuration: {
        merchantId: this.options.merchantId,
        getApplePaySession: this.getApplePaySession,
      },
    });

    if (result.isCanceled) {
      return { token: '', nonce: null, canceled: true };
    }

    const { transactionData, requestId } = result;
    return {
      token: transactionData,
      nonce: null,
      canceled: false,
      completePayment: () => ApplePay.completePayment(requestId),
      cancelPayment: () => ApplePay.cancelPayment(requestId),
    };
  }
}
