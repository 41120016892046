import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';

import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';
import { useDeletePaymentInstrumentMutation } from '@ocx/graphql';

type UseDeletePaymentInstrumentParams = {
  onError?: (error: ApolloError) => void;
  onCompleted?: () => void;
};

export const useDeletePaymentInstrument = (params: UseDeletePaymentInstrumentParams = {}) => {
  const { onError, onCompleted } = params;
  const { triggerEvent } = useRudderStack();

  const [paymentInstrumentDeleteMutation, { loading: isDeleting }] = useDeletePaymentInstrumentMutation({
    // refetch currentCustomer to keep hasPasscode updated in case if "rest pin on last payment method deleted" enabled
    refetchQueries: ['getPaymentInstruments', 'currentCustomer'],
    awaitRefetchQueries: true,
    onError,
    onCompleted,
  });

  const deletePaymentInstrument = useCallback(
    (uuid: string) => {
      paymentInstrumentDeleteMutation({
        variables: {
          uuid,
        },
      });
      triggerEvent('delete_payment');
    },
    [paymentInstrumentDeleteMutation, triggerEvent],
  );

  return {
    isDeleting,
    deletePaymentInstrument,
  };
};
