import React, { ReactNode } from 'react';
import { Box, Divider, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { CarWashIcon } from '@ocx/ui-icons';
import { BorderedSection } from '../../../../components/BorderedSection/BorderedSection';

const StyledCarWashIcon = styled(CarWashIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type CarWashProps = {
  children: ReactNode;
};

export const CarWash: React.FC<CarWashProps> = ({ children }) => (
  <BorderedSection>
    <StyledCarWashIcon />
    <Typography flexGrow={1} variant="caption">
      <FormattedMessage
        id="receipt:carWashCodeTitle"
        defaultMessage="Car Wash Code"
        description="Receipt page: car wash code text"
      />
    </Typography>
    <Box display="flex">
      <Divider flexItem sx={{ my: -2, mx: 1 }} variant="dashed" orientation="vertical" />
      <Typography mx={2} variant="h5">
        {children}
      </Typography>
    </Box>
  </BorderedSection>
);
