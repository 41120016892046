import { Fiserv } from '@external/payments-fiserv';

import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';

import { FiservClientTokenGetter, FiservPaymentProviderConstructorParams } from './fiserv-payment-provider.types';
import {
  PaymentProviderSDKBased,
  PaymentProviderSDKBasedInterface,
  TokenizePaymentInstrumentParams,
  TokenizePaymentInstrumentReturns,
} from '../payment-provider-sdk-based';
import { getPaymentInstrumentTypeByCardType } from '../payment-provider.utils';
import { prepareCreditTokenizationParams, preparePrepaidTokenizationParams } from './fiserv-payment-provider.utils';

export class FiservPaymentProvider extends PaymentProviderSDKBased implements PaymentProviderSDKBasedInterface {
  private readonly getFiservClientToken: FiservClientTokenGetter;

  constructor(params: FiservPaymentProviderConstructorParams) {
    super({
      applePay: params.applePay,
      googlePay: params.googlePay,
    });
    this.getFiservClientToken = params.getFiservClientToken;
  }

  async tokenizePaymentInstrument(params: TokenizePaymentInstrumentParams): Promise<TokenizePaymentInstrumentReturns> {
    const clientToken = await this.getFiservClientToken();

    if (params.accountType === PaymentAccountType.Credit) {
      const tokenizationParams = prepareCreditTokenizationParams({
        token: clientToken,
        paymentInstrumentParams: params,
      });
      const { token } = await Fiserv.tokenizeCreditPaymentInstrument(tokenizationParams);
      return {
        oAuthToken: clientToken.oAuthToken,
        nonce: token,
        accountType: params.accountType,
        paymentInstrumentType: getPaymentInstrumentTypeByCardType(params.cardType),
        externalRequestData: null,
      };
    }

    if (params.accountType === PaymentAccountType.Prepaid) {
      const tokenizationParams = preparePrepaidTokenizationParams({
        token: clientToken,
        paymentInstrumentParams: params,
      });
      const { token } = await Fiserv.tokenizePrepaidPaymentInstrument(tokenizationParams);
      return {
        oAuthToken: clientToken.oAuthToken,
        nonce: token,
        accountType: params.accountType,
        paymentInstrumentType: PaymentInstrumentType.Prepaid,
        externalRequestData: null,
      };
    }

    throw new Error(`[FiservPaymentProvider]: Unsupported account type is provided`);
  }
}
