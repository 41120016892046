import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cardExpiredPopupTitle: {
    id: 'card-expired-popup:title',
    defaultMessage: 'Card is expired',
    description: 'Confirmation Prompt title for expired card',
  },
  cardExpiredPopupDescription: {
    id: 'card-expired-popup:description',
    defaultMessage: 'This payment method has expired. Please consider removing it from your wallet.',
    description: 'Confirmation Prompt description for expired card',
  },
  cardExpiredPopupConfirmButtonText: {
    id: 'card-expired-popup:confirm-button-text',
    defaultMessage: 'Remove payment method',
    description: 'Confirmation Prompt confirm button text',
  },
});
