import { Capacitor } from '@capacitor/core';

import { ApplePayPlugin } from './apple-pay-plugin';
import { createCanMakeRequest, createPaymentRequest, isApplePayTransactionCanceled } from './apple-pay-plugin.utils';
import {
  ApplePayCardNetwork,
  ApplePayLineItem,
  ApplePaySessionGetter,
  ShowPaymentRequestReturns,
} from './apple-pay-plugin.types';

export class ApplePay {
  static async canMakeRequest(params: {
    merchantId: string;
    supportedNetworks: ApplePayCardNetwork[];
  }): Promise<boolean> {
    const request = createCanMakeRequest({
      supportedNetworks: params.supportedNetworks,
      merchantId: params.merchantId,
    });
    const { available } = await ApplePayPlugin.canMakePayments(request);
    return available;
  }

  static async showPaymentRequest(params: {
    requestInfo: {
      lineItems: ApplePayLineItem[];
      supportedNetworks: ApplePayCardNetwork[];
      merchantName: string;
    };
    configuration: {
      merchantId: string;
      getApplePaySession: ApplePaySessionGetter;
    };
  }): Promise<(ShowPaymentRequestReturns & { isCanceled: false }) | { isCanceled: true }> {
    const request = createPaymentRequest(params.requestInfo);

    await ApplePayPlugin.configure({ merchantIdentifier: params.configuration.merchantId });
    try {
      const { requestId, transactionData, transactionId } = Capacitor.isNativePlatform()
        ? await ApplePayPlugin.showPaymentRequest(request)
        : await ApplePayPlugin.showPaymentRequestWeb(request, params.configuration.getApplePaySession);
      return { requestId, transactionData, transactionId, isCanceled: false };
    } catch (e: unknown) {
      if (isApplePayTransactionCanceled(e)) {
        return { isCanceled: true };
      }
      throw e;
    }
  }

  static async completePayment(requestId: string) {
    await ApplePayPlugin.updateTransactionStatus({ requestId, statusCode: 0 });
  }

  static async cancelPayment(requestId: string) {
    await ApplePayPlugin.updateTransactionStatus({ requestId, statusCode: 1 });
  }
}
