import { useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';
import { Membership } from '@ocx/data-membership';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useReferralReceiverStatusModal } from '@ocx/feat-referrals';
import { logInDev } from '@ocx/utils';

export type ReferralStatusControllerProps = Pick<Membership, 'id' | 'createdAt' | 'incomingReferralStatus'>;
export const ReferralStatusController = ({ id, createdAt, incomingReferralStatus }: ReferralStatusControllerProps) => {
  const { showStatusModalIfNeeded } = useReferralReceiverStatusModal();
  useEffect(() => {
    const timer = setTimeout(() => {
      showStatusModalIfNeeded({
        id,
        createdAt,
        incomingReferralStatus,
      }).catch((e) => {
        addBreadcrumb({
          message: 'HomeController > showStatusModalIfNeeded threw an exception.',
          data: e,
        });
        logInDev(e);
      });
    }, 700);
    return () => {
      clearTimeout(timer);
    };
  }, [createdAt, id, incomingReferralStatus, showStatusModalIfNeeded]);
  return null;
};
