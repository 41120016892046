import { useEffect, useMemo } from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  getSelectedPaymentInstrumentType,
  usePaymentInstruments,
  PaymentInstrument,
} from '@ocx/data-payment-instruments';
import { useListController } from '../../../../hooks/useListController';

export const usePaymentInstrumentsController = () => {
  const {
    defaultPaymentInstrumentId,
    refetch: refetchPaymentInstruments,
    loading: isPaymentInstrumentsLoading,
    paymentInstruments,
    isWalletSizeLimitReached,
    walletSizeLimit,
    isWalletEnabled,
  } = usePaymentInstruments({
    canBeUsedToPayEquals: true,
  });

  const paymentInstrumentsListSelector = useListController<PaymentInstrument>({ listItems: paymentInstruments });

  const selectedPaymentInstrumentType = useMemo(
    () =>
      getSelectedPaymentInstrumentType({ selectedId: paymentInstrumentsListSelector.selectedListItem?.uuid || null }),
    [paymentInstrumentsListSelector.selectedListItem?.uuid],
  );

  // Hook watching "defaultPaymentInstrumentId" updates
  // and "selecting" default payment instrument
  useEffect(() => {
    if (!defaultPaymentInstrumentId) {
      return;
    }
    if (paymentInstrumentsListSelector.selectedListItem?.uuid === defaultPaymentInstrumentId) {
      return;
    }
    const defaultPaymentInstrument = paymentInstruments.find(({ uuid }) => uuid === defaultPaymentInstrumentId);
    if (!defaultPaymentInstrument) {
      return;
    }
    paymentInstrumentsListSelector.handleSelect(defaultPaymentInstrument);

    // Use single dependency of defaultId allows handle and update selected payment instrument correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPaymentInstrumentId]);

  return {
    paymentInstruments,
    paymentInstrumentsListSelector,
    selectedPaymentInstrumentType,
    isPaymentInstrumentsLoading,
    refetchPaymentInstruments,
    isCardsAvailable: isWalletEnabled,
    isWalletSizeLimitReached,
    walletSizeLimit,
    isWalletEnabled,
  };
};
