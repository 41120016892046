import {
  CreateIsReadyToPayRequestParams,
  IsReadyToPayRequest,
  CardAuthMethod,
  CreatePaymentRequestParams,
  PaymentRequest,
} from './google-pay-plugin.types';
import {
  GOOGLE_PAY_TRANSACTION_CANCELED_CODE_NATIVE,
  GOOGLE_PAY_TRANSACTION_CANCELED_CODE_WEB,
} from './google-pay-plugin.constants';

export const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

export const allowedAuthMethods: CardAuthMethod[] = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

export const createIsReadyToPayRequest = (params: CreateIsReadyToPayRequestParams): IsReadyToPayRequest => {
  return {
    ...baseRequest,
    existingPaymentMethodRequired: true,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods,
          allowedCardNetworks: params.allowedCardNetworks,
        },
      },
    ],
  };
};

export const createPaymentRequest = (params: CreatePaymentRequestParams): PaymentRequest => {
  const merchantInfo: google.payments.api.MerchantInfo = {
    merchantId: params.merchantId,
    merchantName: params.merchantName || undefined,
  };

  const transactionInfo: google.payments.api.TransactionInfo = {
    countryCode: 'US',
    currencyCode: 'USD',
    displayItems: params.displayItems,

    // This field is required if 'totalPriceStatus' is 'ESTIMATED' or 'FINAL'
    totalPrice: undefined as unknown as string,
    totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
    totalPriceLabel: 'Total', // 'totalPriceLabel' is required for 'displayItems'
  };

  const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: params.gatewayParameters,
  };

  const allowedPaymentMethods: google.payments.api.PaymentMethodSpecification[] = [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods,
        allowedCardNetworks: params.allowedCardNetworks,
      },
      tokenizationSpecification,
    },
  ];

  return {
    ...baseRequest,
    allowedPaymentMethods,
    transactionInfo,
    merchantInfo,
  };
};

export const isGooglePayTransactionCanceled = (e: unknown) => {
  // Web app throws `statusCode`
  const isGooglePayTransactionCanceledInWeb =
    (e as { statusCode?: unknown })?.statusCode === GOOGLE_PAY_TRANSACTION_CANCELED_CODE_WEB;

  // Native app throws `code`
  const isGooglePayTransactionCanceledInNative =
    (e as { code?: unknown })?.code === GOOGLE_PAY_TRANSACTION_CANCELED_CODE_NATIVE;

  return isGooglePayTransactionCanceledInWeb || isGooglePayTransactionCanceledInNative;
};
