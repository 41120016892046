import React, { useMemo } from 'react';

import { useMembership } from '@ocx/data-membership';
import { Offer } from '../../../modules/offers/offer.types';
import { useGetOfferOnClick } from '../features/useGetOfferOnClick';
import { useGetOfferLoyaltyAction } from '../features/useGetOfferOnLoyaltyActionClick';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import {
  getOfferCardAccessoryType,
  getOfferCardInteractionStatus,
  getOfferCardMarketingContent,
  getOfferCardMemberProgress,
  getOfferCardPointsDiff,
} from '../../../components/OfferCard/offer-card.utils';
import { getOfferLabel } from '../../../components/OfferCard/getOfferLabel';
import { OfferCardProps } from '../../../components/OfferCard';
import { diffDateTime, durationDateTime } from '../../../lib/date/date';

export const getOfferExpiresInHumanized = (offerExpiresAt: string): string => {
  const offerExpiresAtDate = new Date(offerExpiresAt);
  const diffMinutes = Math.abs(diffDateTime(new Date(), offerExpiresAtDate, 'minute'));
  if (diffMinutes < 60) {
    return '1 hour';
  }

  return durationDateTime(diffMinutes, 'minute');
};

export type OfferCardControllerProps = {
  children: (value: OfferCardProps) => React.ReactElement;
  offer: Offer;
};

export const OfferCardController = ({ children, offer }: OfferCardControllerProps) => {
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');
  const { membership } = useMembership();

  const getOfferLoyaltyAction = useGetOfferLoyaltyAction({
    membershipIsCompleted: membership.isCompleted,
  });
  const getOfferOnClick = useGetOfferOnClick({
    membershipPoints: membership.points,
    pointsStrategy,
  });

  const value: OfferCardProps = useMemo(() => {
    const onClick = getOfferOnClick(offer);
    const [onActionClick, actionSubmitting] = getOfferLoyaltyAction(offer);

    const expiresIn = offer.expireAt ? getOfferExpiresInHumanized(offer.expireAt) : null;
    const marketingContent = getOfferCardMarketingContent(offer);
    const label = getOfferLabel(offer);
    const memberOfferProgress = getOfferCardMemberProgress(offer);
    const pointsDiff = getOfferCardPointsDiff(offer, { pointsStrategy });
    const actionVariant = getOfferCardAccessoryType({
      hasOnClick: !!onClick,
      hasOnActionClick: !!onActionClick,
      activationStatus: offer.activationStatus,
      hasProgress: !!memberOfferProgress?.canShowMemberProgress,
      loading: actionSubmitting,
    });

    const { shouldDisableInteractions, shouldDisableLoyaltyAction } = getOfferCardInteractionStatus(offer);

    return {
      disabled: shouldDisableInteractions,
      accessoryButtonDisabled: shouldDisableLoyaltyAction,
      loading: actionSubmitting,
      expiresIn,
      marketingContent,
      label,
      progress: memberOfferProgress,
      pointsDiff,
      onClick,
      accessoryType: actionVariant,
      /*
       * It's done that way, because `onClick` should handle eligibility for this case
       */
      onAccessoryButtonClick: shouldDisableInteractions ? onClick : onActionClick,
      pointsStrategy,
    };
  }, [getOfferLoyaltyAction, getOfferOnClick, offer, pointsStrategy]);

  return children(value);
};
