import Payment from 'payment';
import { object, string, literal, z } from 'zod';

import { PaymentAccountType } from '@ocx/graphql';
import { intl } from '../../lib/translation/TranslationProvider';
import { validationMessages } from '../../modules/localization/validationMessages';

export const ZIP_CODE_LENGTH = 5;
export const PREPAID_SECURITY_CODE_LENGTH = 4;
export const PREPAID_CARD_NUMBER_LENGTH = 16;

const cardNumberErrorMessage = intl.formatMessage(validationMessages['validation:form:card-number:general']);

const cardNumberSchema = string({ required_error: cardNumberErrorMessage }).refine(
  (value) => (value ? Payment.fns.validateCardNumber(value) : false),
  cardNumberErrorMessage,
);

const isValidExpirationFormat = ({ month, year }: { month: number; year: number }): boolean => {
  // valid year is 4 digits
  // valid month is from 1 to 12
  return month >= 1 && month <= 12 && year >= 1000 && year <= 9999;
};

export const CreditCardSchema = object({
  accountType: literal(PaymentAccountType.Credit).default(PaymentAccountType.Credit),
  cardNumber: cardNumberSchema,
  expirationDate: string()
    .default('')
    .superRefine((value, ctx) => {
      if (!isValidExpirationFormat(Payment.fns.cardExpiryVal(value || ''))) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage(validationMessages['validation:form:card-expiration:general']),
        });
      }
      if (!Payment.fns.validateCardExpiry(value)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intl.formatMessage(validationMessages['validation:form:card-expiration:expired']),
        });
      }
      return true;
    }),
  cvv: string()
    .default('')
    .refine(
      (value) => (value ? Payment.fns.validateCardCVC(value) : false),
      intl.formatMessage(validationMessages['validation:form:card-cvv:general']),
    ),
  cardholderName: string({
    message: intl.formatMessage(validationMessages['validation:form:cardholder-name:required']),
  }),
  zipCode: string()
    .regex(
      new RegExp(`^\\d{${ZIP_CODE_LENGTH}}$`),
      intl.formatMessage(validationMessages['validation:form:zip-code:general']),
    )
    .default(''),
});

export const PrepaidCardSchema = object({
  accountType: literal(PaymentAccountType.Prepaid).default(PaymentAccountType.Prepaid),
  cardNumber: string().regex(new RegExp(`^\\d{${PREPAID_CARD_NUMBER_LENGTH}}$`), cardNumberErrorMessage),
  securityCode: string().regex(
    new RegExp(`^\\d{${PREPAID_SECURITY_CODE_LENGTH}}$`),
    intl.formatMessage(validationMessages['validation:form:prepaid-card-pin-code:general']),
  ),
});
