import { ParPayTokenizationSession } from '@ocx/graphql';

export type ParPaySessionGetter = () => Promise<ParPayTokenizationSession>;

export type ParPayCardType =
  | 'VIC' // Visa credit
  | 'VID' // Visa Debit
  | 'MCC' // Mastercard credit
  | 'MCD' // Mastercard debit
  | 'AXC' // Amex
  | 'DCC' // Diners Credit
  | 'NVC' // Discover credit
  | 'NVD' // Discover debit
  | 'JBC' // JCB credit
  | 'JBD' // JCB debit
  | 'JCB' // JCB credit
  | 'GCC'; // Gift card

export enum TokenizationResponseCode {
  SUCCESS = '00000',
}
