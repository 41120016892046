import { Typography, Markdown, SizingMultiplier } from '@ocx/ui';

export const LegalMarkdown = (props: { content: string }) => {
  const { content } = props;
  return (
    <Typography
      component={Markdown}
      variant="caption"
      color="text.secondary"
      bgcolor="background.box"
      borderRadius={SizingMultiplier.md}
      p={SizingMultiplier.md}>
      {content}
    </Typography>
  );
};
