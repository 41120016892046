import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const MinusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 4" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.920898 3.5293V0.855957H13.0425V3.5293H0.920898Z" />
    </SvgIcon>
  );
};
