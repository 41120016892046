import { Button } from '@ocx/ui';

import { useSupportAction } from './use-support-action.hook';

export type SupportButtonProps = {
  onClick?: () => void;
};

export const SupportButton = ({ onClick }: SupportButtonProps) => {
  const supportAction = useSupportAction();
  const onButtonClick = () => {
    onClick?.();
    supportAction.onClick?.();
  };

  if (supportAction.type === 'external-url') {
    return (
      <Button onClick={onButtonClick} component="a" target="_blank" rel="noreferrer" href={supportAction.href}>
        Contact support center
      </Button>
    );
  }
  return <Button onClick={onButtonClick}>Contact support center</Button>;
};
