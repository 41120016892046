import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import { generatePath } from 'react-router-dom';

import { List, ListItemText, ListItemStartIcon, ListItemNavLink } from '@ocx/ui';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';
import { SendOutlinedIcon } from '@ocx/ui-icons';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';

import { profileMessages } from '../profile.messages';

// TODO: it's probably a time to create a new reusable component.
export const ReferAFriendMenuItem = () => {
  const { triggerEvent } = useRudderStack();
  return (
    <Paper elevation={0} sx={{ overflow: 'hidden' }}>
      <List>
        <ListItemNavLink
          onClick={() => triggerEvent('profile_refer_a_friend_pressed')}
          to={generatePath(AppRoutePath.ReferAFriend)}>
          <ListItemStartIcon>
            <SendOutlinedIcon />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...profileMessages['profile:refer-a-friend:title']} />
          </ListItemText>
        </ListItemNavLink>
      </List>
    </Paper>
  );
};
