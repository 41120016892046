import { Capacitor } from '@capacitor/core';

import { isAndroidPlatform } from '@ocx/utils-device';

import {
  CreateIsReadyToPayRequestParams,
  CreatePaymentRequestParams,
  GooglePayEnvironmentWeb,
  PaymentRequestReturns,
  ButtonOptions,
  GooglePayButtonColor,
} from './google-pay-plugin.types';
import { GooglePayPlugin } from './google-pay-plugin';
import {
  createIsReadyToPayRequest,
  createPaymentRequest,
  isGooglePayTransactionCanceled,
} from './google-pay-plugin.utils';
import { DEFAULT_ANDROID_BUTTON_COLOR, googlePayEnvironmentWebToNativeMap } from './google-pay-plugin.constants';
import { GooglePayPluginWeb } from './google-pay-plugin.web';
import styles from './google-pay-plugin.styles.module.css';

export class GooglePay {
  static environment: GooglePayEnvironmentWeb | null = null;

  static async init(environment: GooglePayEnvironmentWeb) {
    this.environment = environment;
    if (Capacitor.isNativePlatform()) {
      return Promise.resolve();
    }
    return GooglePayPlugin.init(environment);
  }

  static async isReadyToPay(params: CreateIsReadyToPayRequestParams): Promise<boolean> {
    if (!this.environment) throw new Error('[GooglePay] Environment is not configured');

    const isReadyToPayRequest = createIsReadyToPayRequest(params);
    const { success } = await GooglePayPlugin.isReadyToPay({
      environment: googlePayEnvironmentWebToNativeMap[this.environment],
      isReadyToPayRequest,
    });
    return success;
  }

  static async requestPay(params: {
    displayItems: CreatePaymentRequestParams['displayItems'];
    merchantInfo: Omit<CreatePaymentRequestParams, 'displayItems'>;
  }): Promise<(PaymentRequestReturns & { isCanceled: false }) | { isCanceled: true }> {
    if (!this.environment) throw new Error('[GooglePay] Environment is not configured');

    try {
      const paymentRequest = createPaymentRequest({
        ...params.merchantInfo,
        displayItems: params.displayItems,
      });
      const result = await GooglePayPlugin.requestPay({
        environment: googlePayEnvironmentWebToNativeMap[this.environment],
        paymentRequest,
      });
      return { ...result, isCanceled: false };
    } catch (e: unknown) {
      if (isGooglePayTransactionCanceled(e)) {
        return { isCanceled: true };
      }
      throw e;
    }
  }

  // region GooglePay Button
  static async createButton(params: {
    onClick?: () => void;
    buttonColor?: GooglePayButtonColor;
  }): Promise<HTMLElement | null> {
    const { onClick, buttonColor = 'white' } = params;
    const paymentsClient = await this.getPaymentsClient();

    const options: ButtonOptions = {
      buttonColor: isAndroidPlatform() ? DEFAULT_ANDROID_BUTTON_COLOR : buttonColor,
      buttonType: 'plain',
      buttonSizeMode: 'fill',
      onClick: () => null,
    };

    if (onClick) {
      options.onClick = onClick;
    }

    const button = paymentsClient.createButton(options);
    button.classList.add(styles['buttonContainer']);

    if (isAndroidPlatform()) {
      button.classList.add(styles['androidNativeButtonContainer']);
    }
    return button;
  }

  private static async getPaymentsClient() {
    if (!this.environment) {
      throw new Error('[GooglePay] Environment is not configured');
    }

    if (!Capacitor.isNativePlatform()) {
      return GooglePayPlugin.getPaymentsClient();
    }

    // Creating encapsulated instance of Web plugin in Native App
    // Web Plugin has "paymentsClient" property which is not available in Native Plugin
    // "paymentsClient" is required for generating GooglePay Button
    const plugin = new GooglePayPluginWeb();
    await plugin.init(this.environment); // Web plugin does not work without any environment
    return plugin.getPaymentsClient();
  }
  // endregion
}
