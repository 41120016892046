import { defineMessages } from 'react-intl';

export const homeMessages = defineMessages({
  'home:offerActivationFailedErrorMessageText': {
    defaultMessage: 'This offer cannot be activated at this time. Please try again later.',
    description: 'Error message, when offer can not be activated.',
    id: 'home:offerActivationFailedErrorMessageText',
  },
  'home:pointsBox:customerGreeting': {
    id: 'home:pointsBox:customerGreeting',
    defaultMessage: 'Welcome!',
    description: 'Customer greeting title in points component',
  },
  'home:pointsBox:customerGreetingWithName': {
    id: 'home:pointsBox:customerGreetingWithName',
    defaultMessage: 'Welcome, {customerName}!',
    description: 'Customer greeting title in points component with customer name',
  },
  'home:rewards:readyToUseRewards': {
    id: 'home:rewards:readyToUseRewards',
    defaultMessage: '{rewardsQuantity, plural, one {# Reward} other {# Rewards}}',
    description: 'Rewards usage prompt when total available rollback is also displayed.',
  },
  'home:rewards:readyToUseRewardsFull': {
    id: 'home:rewards:readyToUseRewardsFull',
    defaultMessage: '{rewardsQuantity, plural, one {# Ready to Use Reward} other {# Ready to Use Rewards}}',
    description: 'Rewards usage prompt to fill entire rewards section',
  },
  'home:rewards:totalAvailableRollback': {
    id: 'home:rewards:totalAvailableRollback',
    defaultMessage: 'Saving {moneyAmount}/gallon',
    description: 'Total available rollback prompt when rewards is also displayed.',
  },
  'home:rewards:totalAvailableRollbackFull': {
    id: 'home:rewards:totalAvailableRollbackFull',
    defaultMessage: 'Saving {moneyAmount}/gal on the Next Fuel Up',
    description: 'Total available rollback prompt to fill entire rewards section',
  },
});
