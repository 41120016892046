import { useEffect, useMemo, useState } from 'react';

import { OfferFragment, useOffersQuery } from '@ocx/graphql';
import { useMembership } from '@ocx/data-membership';
import { mapFragmentsToSortedOffersList } from './offer.dto';
import { OPTIMIZED_HOME_OFFERS_FILTER } from './offers.constants';

import { currentCustomerCache } from './offer.cache';
import { onCacheLoadFailed } from '../cache-layer/utils';
import { useConfiguration } from '../configuration/useConfiguration';

export const useOffers = () => {
  const { homePageOffersLimit } = useConfiguration('loyaltyProgram');
  const { membership } = useMembership();
  const [fragments, setFragments] = useState<OfferFragment[]>([]);
  const { data, loading, refetch } = useOffersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ...OPTIMIZED_HOME_OFFERS_FILTER, first: homePageOffersLimit },
  });

  useEffect(() => {
    let shouldHandle = true;
    currentCustomerCache
      .getOffers()
      .then((cachedFragments) => {
        if (!shouldHandle) {
          return;
        }
        if (cachedFragments && !fragments.length) {
          setFragments(cachedFragments);
        }
      })
      .catch(onCacheLoadFailed);
    return () => {
      shouldHandle = false;
    };
  }, [fragments.length]);

  useEffect(() => {
    if (!data?.customer?.membership?.offers) {
      setFragments([]);
      currentCustomerCache.cacheOffers([]).catch(onCacheLoadFailed);
    } else {
      const newFragments = data.customer.membership.offers.edges
        .filter((edge): edge is { node: OfferFragment } => !!edge?.node)
        .map(({ node }) => node);
      currentCustomerCache.cacheOffers(newFragments).catch(onCacheLoadFailed);
      setFragments(newFragments);
    }
  }, [data?.customer]);

  const offers = useMemo(() => {
    return mapFragmentsToSortedOffersList({
      offers: fragments,
      membershipPoints: membership.points,
      membershipIsCompleted: membership.isCompleted,
    });
  }, [membership.isCompleted, membership.points, fragments]);

  return { offers, refetch, loading };
};
