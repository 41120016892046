import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { Capacitor } from '@capacitor/core';
import { possibleTypes } from '@ocx/graphql';
import { AuthService } from './auth-service.type';
import AuthMiddleware from './middlewares/auth-middleware';
import { createGlobalErrorHandlerMiddleware } from './middlewares/create-global-error-handler-middleware';
import { createTracingMiddleware } from './middlewares/create-tracing-middleware';
import { apolloCacheTypePolicies } from './policies/apollo-cache-type-policies';

export type CreateApolloClientParams = {
  api: {
    url: string;
    key: string;
  };
  release: string;
  gitRevision: string;

  authService: AuthService;
  getDeviceId: () => Promise<string>;
  getDevicePlatform: () => 'ios' | 'android' | 'web';
  clearCache: () => Promise<void>;
  logInDev: (message: unknown) => void;
  globalErrorHandler: ErrorLink.ErrorHandler;
};

export const createApolloClient = (params: CreateApolloClientParams) => {
  const httpLink = createHttpLink({
    uri: params.api.url,
    headers: {
      'x-api-key': params.api.key,
    },
  });

  const authMiddleware = new AuthMiddleware({
    clearCache: params.clearCache,
    logInDev: params.logInDev,
  });
  authMiddleware.injectAuthService(params.authService);

  const tracingMiddleware = createTracingMiddleware({
    release: params.release,
    gitRevision: params.gitRevision,
    getDeviceId: params.getDeviceId,
    getDevicePlatform: params.getDevicePlatform,
  });

  const globalErrorHandlerMiddleware = createGlobalErrorHandlerMiddleware(params.globalErrorHandler);

  const link = ApolloLink.from([tracingMiddleware, globalErrorHandlerMiddleware, authMiddleware, httpLink]);

  const cache = new InMemoryCache({
    typePolicies: apolloCacheTypePolicies,
    possibleTypes,
  });

  const apolloClient = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    name: `ocx_${Capacitor.getPlatform()}`,
    version: params.release,
  });

  return apolloClient;
};
