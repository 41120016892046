import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { DeletedUserLoginPopupController } from './deleted-user-login-popup.controller';

const DeletedUserLoginPopup = NiceModal.create(DeletedUserLoginPopupController);

export const useDeletedUserLoginPopup = () => {
  const { show } = useModal(DeletedUserLoginPopup);
  return useMemo(() => ({ open: show }), [show]);
};
