import { addBreadcrumb } from '@sentry/react';
import { ShareOptions } from '@capacitor/share';
import { useCallback } from 'react';

import { exceptionIsShareAbortError, share } from '@ocx/utils';
import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';
import { useSubmitReferralCodeSharedEventMutation } from '@ocx/graphql';

export const useShareUrl = () => {
  const { triggerEvent } = useRudderStack();

  const [submitReferralCodeSharedEvent] = useSubmitReferralCodeSharedEventMutation();

  return useCallback(
    async (options: ShareOptions) => {
      triggerEvent('referral_link_share_started');
      addBreadcrumb({
        message: 'Referrals -> Share.share started.',
      });

      try {
        const result = await share(options);
        addBreadcrumb({
          message: 'Referrals -> Share.share complete.',
        });
        triggerEvent('referral_link_share_complete', {
          app: result.activityType,
        });
        addBreadcrumb({
          message: 'Referrals -> Share -> submitReferralCodeSharedEvent started.',
        });
        submitReferralCodeSharedEvent()
          .catch(() => {
            addBreadcrumb({
              message: 'Referrals -> Share -> submitReferralCodeSharedEvent threw an error.',
            });
          })
          .then(() => {
            addBreadcrumb({
              message: 'Referrals -> Share -> submitReferralCodeSharedEvent complete.',
            });
          });
      } catch (e) {
        if (exceptionIsShareAbortError(e)) {
          addBreadcrumb({
            message: 'Referrals -> Share.share canceled by a user.',
          });
          triggerEvent('referral_link_share_canceled');
        } else {
          addBreadcrumb({
            message: 'Referrals -> Share.share threw an error.',
          });
        }
      }
    },
    [submitReferralCodeSharedEvent, triggerEvent],
  );
};
