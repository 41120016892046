import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const VerifiedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.95 15.55 16.6 9.9l-1.425-1.425L10.95 12.7l-2.1-2.1-1.425 1.425 3.525 3.525ZM12 22c-2.317-.583-4.229-1.913-5.737-3.988C4.754 15.937 4 13.633 4 11.1V5l8-3 8 3v6.1c0 2.533-.754 4.837-2.262 6.912C16.229 20.087 14.317 21.417 12 22Zm0-2.1c1.733-.55 3.167-1.65 4.3-3.3s1.7-3.483 1.7-5.5V6.375l-6-2.25-6 2.25V11.1c0 2.017.567 3.85 1.7 5.5s2.567 2.75 4.3 3.3Z" />
    </SvgIcon>
  );
};
