import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { exceptionIsShareAbortError, logInDev } from '@ocx/utils';

export type DownloadAndShareResult = 'shared' | 'canceled';

export type DownloadAndShareParams = {
  url: string;
  filename: string;
  title?: string;
};

export const downloadAndShare = async ({
  url,
  filename,
  title,
}: DownloadAndShareParams): Promise<DownloadAndShareResult> => {
  let path: string | undefined;

  if (url.startsWith('data:')) {
    // Android’s Filesystem.downloadFile method doesn’t support data: URLs,
    // only standard URLs with protocols like http or https. To resolve this,
    // we manually extracted the base64 data from the data: URL and saved it
    // directly to a file using Filesystem.writeFile
    const base64Data = url.split(',')[1];
    const fileResult = await Filesystem.writeFile({
      path: filename,
      data: base64Data,
      directory: Directory.Cache,
      recursive: true, // Ensures the parent directory is created if it doesn't exist
    });
    path = fileResult.uri;
  } else {
    const fileResult = await Filesystem.downloadFile({
      path: filename,
      directory: Directory.Cache,
      url: url,
      recursive: true, // Ensures the parent directory is created if it doesn't exist
    });
    path = fileResult.path;
  }

  if (!path) {
    throw new Error('Sharing Path not found');
  }

  try {
    await Share.share({
      title,
      url: path,
    });
  } catch (e: unknown) {
    if (exceptionIsShareAbortError(e)) {
      return 'canceled';
    }
    throw e;
  }

  // Android sometimes moves or deletes temporary files automatically after
  // they are shared, meaning the file might no longer exist
  // when you attempt to delete it
  Filesystem.stat({
    path: filename,
    directory: Directory.Cache,
  })
    .then(() =>
      Filesystem.deleteFile({
        path: filename,
        directory: Directory.Cache,
      }).catch(logInDev),
    )
    .catch(logInDev);

  return 'shared';
};
