import { defineMessages } from 'react-intl';

export const referAFriendMessages = defineMessages({
  htmlTitle: {
    id: 'refer-a-friend:html:title',
    defaultMessage: 'Refer a Friend',
  },
  modalTitle: {
    id: 'refer-a-friend:modal:title',
    defaultMessage: 'Refer a Friend!',
  },
  copyLinkButtonTitle: {
    id: 'refer-a-friend:copy-link-button:title',
    defaultMessage: 'Copy Link',
  },
  shareLinkButtonTitle: {
    id: 'refer-a-friend:share-link-button:title',
    defaultMessage: 'Share Link',
  },
  shareDialogTitle: {
    id: 'refer-a-friend:share-dialog:title',
    defaultMessage: 'Refer a Friend!',
  },
  referralReceiverStatusSuccessModalTitle: {
    id: 'referral-receiver-status-success-modal:title',
    defaultMessage: 'All set up!',
  },
  referralReceiverStatusErrorModalTitle: {
    id: 'referral-receiver-status-error-modal:title',
    defaultMessage: 'Referral Code Error',
  },
  referralReceiverStatusErrorModalDescription: {
    id: 'referral-receiver-status-error-modal:description',
    defaultMessage: 'We are sorry, we could not apply your referral code.',
  },
  referralReceiverStatusSuccessModalAction: {
    id: 'referral-receiver-status-success-modal:action',
    defaultMessage: 'Got it!',
  },
});
