import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';
import { Button, Typography } from '@ocx/ui';

import { SupportLink, messages as featSupportMessages } from '@ocx/feat-support';
import { DeletedUserLoginPopupProps } from './deleted-user-login-popup.types';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { logInDev } from '../../../lib/logger/logger';
import { messages } from './deleted-user-login-popup.messages';

export const DeletedUserLoginPopupController = (props: DeletedUserLoginPopupProps) => {
  const intl = useIntl();
  const { cancelOnClickOutsideDisabled = false, onClose, onClosed } = props;

  const { visible, hide, remove } = useModal();

  const handleExited = useCallback(() => {
    onClosed?.();
    remove();
  }, [remove, onClosed]);

  const handleCancel = useCallback(() => {
    onClose?.();
    hide().catch(logInDev);
  }, [hide, onClose]);

  return (
    <ModalDrawer
      open={visible}
      onClose={cancelOnClickOutsideDisabled ? undefined : handleCancel}
      onExited={handleExited}>
      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Stack spacing={SizingMultiplier.md}>
          <Typography variant="h5" component="h1">
            {intl.formatMessage(messages.deletedUserLoginPopupTitle)}
          </Typography>
          <Typography>{intl.formatMessage(messages.deletedUserLoginPopupDescription)}</Typography>
        </Stack>
        <Stack spacing={SizingMultiplier.sm}>
          <SupportLink>
            <Button fullWidth>
              <FormattedMessage {...featSupportMessages['support:support-link:action-text']} />
            </Button>
          </SupportLink>
          <Button onClick={handleCancel} variant="text">
            {intl.formatMessage(buttonTextMessages['button-text:close'])}
          </Button>
        </Stack>
      </Stack>
    </ModalDrawer>
  );
};
