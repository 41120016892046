import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { useMembership } from '@ocx/data-membership';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { PrimaryInternalFuelingStatus } from '../../../../modules/transaction/types';
import { useCreateLoyaltyEvent } from '../../../../hooks/useCreateLoyaltyEvent';
import { useWatchTransaction } from '../../../../modules/transaction/hooks/useWatchTransaction';
import { useMapTransactionStatuses } from './useMapTransactionStatuses';

export interface IUseFuelingStatusControllerReturns {
  transactionId: string;

  loading: boolean;
  isTransactionStatusTimedOut: boolean;
  status: PrimaryInternalFuelingStatus | null;
  pumpNumber: string | null;
}

export interface IUseFuelingStatusControllerParams {
  onError(error?: ApolloError): void;
}

export const useFuelingStatusController = ({
  onError,
}: IUseFuelingStatusControllerParams): IUseFuelingStatusControllerReturns => {
  const { triggerEvent } = useRudderStack();
  const { transactionId = '' } = useParams<{ transactionId: string }>();
  const { membership } = useMembership();
  const [createLoyaltyEvent] = useCreateLoyaltyEvent({
    membershipId: membership.id,
  });

  const onPumpAuthorized = useCallback(() => {
    // TODO: `type` should be payment method type, will be addressed in OCX-536
    triggerEvent('pay_at_pump_authorized', { type: '' });

    createLoyaltyEvent('pay_at_pump_authorized').catch((error) => {
      Sentry.captureException(error, {
        extra: {
          event: 'Create pay at pump loyalty event failed.',
          membershipId: membership.id,
        },
      });
    });
  }, [createLoyaltyEvent, membership.id, triggerEvent]);

  const onTransactionCompleted = useCallback(() => {
    triggerEvent('pay_at_pump_fueling_completed');
  }, [triggerEvent]);

  const { loading, transaction, isTransactionStatusTimedOut } = useWatchTransaction({
    transactionId,
    onError,
    onTransactionCompleted,
    onPumpAuthorized,
  });
  const transactionStatuses = useMapTransactionStatuses({ transaction });

  return {
    transactionId,
    loading,
    isTransactionStatusTimedOut,
    status: transactionStatuses.primaryFuelingStatus,
    pumpNumber: transaction?.fuelingPosition ?? null,
  };
};
