import { SpinWheelGameSectorRenderParams } from '@ocx/spin-wheel';
import { LoyaltyCurrencyChange } from '@ocx/feat-loyalty-currency';

export const SpinWheelSectorContent = (props: SpinWheelGameSectorRenderParams) => {
  const { type, points = 0, placeholder = null } = props;

  if (type === 'VOUCHERS') {
    return placeholder;
  }

  return <LoyaltyCurrencyChange points={points} />;
};
