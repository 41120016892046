import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  choosePaymentMethod: {
    id: 'payAtPump:thereAreSomePaymentMethodsAdded:choosePaymentMethodButtonTitle',
    defaultMessage: 'Choose payment method',
    description: 'Pay at pump - there are some payment methods added - Choose payment method button title',
  },
  chooseAnotherPaymentMethod: {
    id: 'payAtPump:expiredCard:chooseAnotherPaymentMethod',
    defaultMessage: 'This card is expired. Please choose another payment method or add a new one.',
    description: 'Pay at pump - if card is expired. Please choose another payment method or add a new one.',
  },
});
