import { Chip } from '@mui/material';
import { ReactNode } from 'react';
import { AddIcon, DoneIcon } from '@ocx/ui-icons';

type SelectableChipProps = {
  children: ReactNode;
  isSelected: boolean;
  onClick: () => void;
};
export const SelectableChip = (props: SelectableChipProps) => {
  const { children, isSelected, onClick } = props;

  return (
    <Chip
      label={children}
      color={isSelected ? 'primary' : 'default'}
      onClick={onClick}
      onDelete={onClick}
      variant={isSelected ? 'filled' : 'outlined'}
      deleteIcon={isSelected ? <DoneIcon /> : <AddIcon />}
    />
  );
};
