import z, { array, boolean, custom, number, object, string, literal } from 'zod';

import { SmsOptInSchema } from './smsOptIn/sms-opt-in.schema';
import { DynamicCardActionSchema } from './actions/actions.schema';
import { OfferOverridesSchema } from './overrides/overrides.schema';
import { IneligibleIfMembershipHasRewardConstraintSchema } from './constraints/constraint.schema';
import { IneligibleIfMembershipHasRewardConstraint } from './constraints/constraints.types';

export const ConstraintSchema = custom<z.infer<typeof IneligibleIfMembershipHasRewardConstraintSchema>>(
  (value: unknown): value is IneligibleIfMembershipHasRewardConstraint => {
    return !!IneligibleIfMembershipHasRewardConstraintSchema.safeParse(value).success;
  },
);

export const DynamicCardCustomDataSchema = object({
  version: literal(1),
  rank: number().optional(),
  href: string().url().optional(),
  action: DynamicCardActionSchema.optional(),
  overrides: OfferOverridesSchema.optional(),
  constraints: array(ConstraintSchema).optional(),
  smsOptIn: SmsOptInSchema.optional(),
  __debug: boolean().optional(),
  // R TODO: get rid of this or move to a better schema
  completeProfileRestricted: boolean().optional(),
  // R TODO: get rid of Delek Hack
  showMemberPointsAsEntries: boolean().optional(),
}).strict();

export const SpinWheelGameCustomDataSchema = DynamicCardCustomDataSchema.extend({
  spinWheelGame: object({
    backgroundImage: string().nullable().optional(),
    backgroundPrimaryColor: string().nullable().optional(),
    backgroundSecondaryColor: string().nullable().optional(),
    borderColor: string().nullable().optional(),
    buttonColor: string().nullable().optional(),
    buttonTextColor: string().nullable().optional(),
    foregroundImage: string().nullable().optional(),
    headerText: string().nullable().optional(),
    headerTextColor: string().nullable().optional(),
    footerTextColor: string().nullable().optional(),
    disclaimerTextColor: string().nullable().optional(),
    disclaimerBackgroundColor: string().nullable().optional(),
    segments: array(
      object({
        icon: string().nullable().optional(),
        color: string().nullable().optional(),
      }).strict(),
    ),
  }).optional(),
});
