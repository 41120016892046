import * as Sentry from '@sentry/react';

import { wait, loadScript } from '@ocx/utils';

import { createWorldpaySupportedId } from './worldpay.utils';
import {
  EProtectOptions,
  EProtectStatus,
  WorldpayReportGroup,
  EprotectApplePayTokenizationRequest,
  EprotectPaymentInstrumentTokenizationRequest,
  EProtectTokenizationResponse,
} from './worldpay.types';
import { LOADING_ATTEMPT_TIMEOUT, MAX_LOADING_ATTEMPTS } from './worldpay.constants';

// Worldpay Payment Provider utilise eProtect SDK for tokenization of payment instruments.
// So you can see naming like `eProtect` in the code.
export class Worldpay {
  private static status: EProtectStatus = EProtectStatus.INITIAL;

  static options: { paypageId: null | string; sdkApiUrl: null | string; sdkUrl: null | string } = {
    paypageId: null,
    sdkApiUrl: null,
    sdkUrl: null,
  };

  static setOptions(options: EProtectOptions) {
    this.options = options;
  }

  static async load(): Promise<void> {
    try {
      if (this.status !== EProtectStatus.INITIAL) {
        return;
      }
      if (!this.options.sdkUrl) {
        return;
      }
      this.status = EProtectStatus.LOADING;

      // region | Loading dependencies
      await loadScript(this.options.sdkUrl);
      this.status = EProtectStatus.LOADED;
      // endregion

      if (window.eProtect !== undefined) {
        this.status = EProtectStatus.READY;
      } else {
        this.status = EProtectStatus.FAILED;
      }
    } catch (e: unknown) {
      this.status = EProtectStatus.FAILED;
      Sentry.captureMessage('Error loading eProtect SDK', {
        level: 'error',
        extra: { message: e instanceof Error ? e.message : 'Unknown exception' },
      });
    }
  }

  static async waitIsAvailable(attempts = 0): Promise<boolean> {
    await this.load();
    if (this.status === EProtectStatus.READY) {
      return true;
    }
    if (this.status === EProtectStatus.FAILED) {
      throw new Error('eProtect is not available');
    }
    if (attempts >= MAX_LOADING_ATTEMPTS) {
      throw new Error('eProtect not loaded. Max attempts limit reached');
    }
    await wait(LOADING_ATTEMPT_TIMEOUT);
    return this.waitIsAvailable(attempts + 1);
  }

  static async tokenizePaymentInstrument(params: {
    cardNumber: string;
    cvv: string;
    reportGroup: WorldpayReportGroup;
  }): Promise<EProtectTokenizationResponse> {
    await this.waitIsAvailable();

    const card = document.createElement('input');
    card.value = params.cardNumber;
    const cvv = document.createElement('input');
    cvv.value = params.cvv;

    const eProtectRequest: EprotectPaymentInstrumentTokenizationRequest = {
      paypageId: this.options.paypageId,
      url: this.options.sdkApiUrl,
      id: createWorldpaySupportedId(),
      orderId: createWorldpaySupportedId(),
      reportGroup: params.reportGroup,
      checkoutIdMode: false,
    };

    return new Promise<EProtectTokenizationResponse>((resolve, reject) => {
      const { eProtect: EProtectSDK } = window;
      new EProtectSDK().sendToEprotect(
        eProtectRequest,
        {
          cvv2: cvv,
          accountNum: card,
        },
        resolve, // on success
        reject, // on error
        reject, // on timeout
      );
    });
  }

  /**
   * Single method for Native and Web platforms
   * Will be split in future if Native/Web require different approaches
   */
  static async tokenizeApplePay(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentData: any, //ApplePayPaymentToken['paymentData'] -> any
    params: { reportGroup: WorldpayReportGroup },
  ): Promise<EProtectTokenizationResponse> {
    await this.waitIsAvailable();

    const eProtectRequest: EprotectApplePayTokenizationRequest = {
      applepay: paymentData,
      checkoutIdMode: false,
      gateway: 'apple',
      id: createWorldpaySupportedId(),
      orderId: createWorldpaySupportedId(),
      paypageId: this.options.paypageId,
      reportGroup: params.reportGroup,
      url: this.options.sdkApiUrl,
    };

    return new Promise<EProtectTokenizationResponse>((resolve, reject) => {
      const { eProtect: EProtectSDK } = window;
      new EProtectSDK().sendToEprotect(
        eProtectRequest,
        {},
        resolve, // on success
        reject, // on error
        reject, // on timeout
      );
    });
  }
}
