import { createContext, useContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';

import { CurrentCustomerQuery } from '@ocx/graphql';
import { logInDev } from '@ocx/utils';

import { Membership } from '../membership.types';

export type MembershipContextValue = {
  loading: boolean;
  membership: Membership | null;
  refetch(): Promise<ApolloQueryResult<CurrentCustomerQuery>>;
};

const defaultMembershipContextValue: MembershipContextValue = {
  loading: true,
  membership: null,
  // @ts-expect-error This is initial value
  refetch: () => {
    logInDev('MembershipContextValue.refetch is not implemented');
  },
};

export const MembershipContext = createContext<MembershipContextValue>(defaultMembershipContextValue);
export const useMembershipContext = () => useContext(MembershipContext);
