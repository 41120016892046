import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 120 120" {...props}>
      <circle cx="60" cy="60" r="60" fill="#86B6FF" />
      <circle cx="60" cy="60" r="60" fill="url(#a)" />
      <mask id="b" width="120" height="120" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
        <circle cx="60" cy="60" r="60" fill="#6B0707" />
      </mask>
      <g mask="url(#b)">
        <rect
          width="76"
          height="52"
          x="19.899"
          y="46.829"
          fill="#4152EA"
          rx="16"
          transform="rotate(-15 19.899 46.829)"
        />
        <path
          fill="#4152EA"
          d="M47 86.154a2 2 0 0 1 1.415-2.45L65 79.26c1.721-.461 3.14 1.389 2.25 2.932l-12.142 21.031c-.891 1.543-3.203 1.239-3.664-.483L47 86.154Z"
        />
        <path fill="#4152EA" d="m52.272 103.894-4.9-2.828 1.415-2.45 4.899 2.829-1.414 2.449Z" />
        <path
          fill="#ffff"
          d="M42.102 83.325a2 2 0 0 1 1.414-2.45l16.586-4.443c1.721-.462 3.14 1.388 2.25 2.931L50.21 100.394c-.891 1.543-3.203 1.239-3.664-.482l-4.444-16.587Z"
        />
        <path
          fill="#4152EA"
          d="m36.297 90.835-4.899-2.828.707-1.225 4.9 2.828-.708 1.225ZM90.888 35.314l-4.9-2.828.708-1.225 4.899 2.828-.707 1.225Z"
        />
        <rect width="76" height="52" x="15" y="44" fill="#ffff" rx="16" transform="rotate(-15 15 44)" />
        <rect width="52" height="6" x="29.956" y="53.451" fill="#7F8BF8" rx="3" transform="rotate(-15 29.956 53.451)" />
        <rect width="52" height="6" x="32.544" y="63.11" fill="#7F8BF8" rx="3" transform="rotate(-15 32.544 63.11)" />
        <rect width="32" height="6" x="35.132" y="72.769" fill="#7F8BF8" rx="3" transform="rotate(-15 35.132 72.77)" />
      </g>
      <defs>
        <linearGradient id="a" x1="0" x2="120" y1="0" y2="120" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF003D" stopOpacity=".32" />
          <stop offset="1" stopColor="#FF20E9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
