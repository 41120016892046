import { ApolloQueryResult } from '@apollo/client';

import { CurrentCustomerQuery } from '@ocx/graphql';

import { Membership } from './membership.types';
import { useMembershipContext } from './context/membership.context';

export type UseMembershipReturns = {
  membership: Membership;
  loading: boolean;
  refetch(): Promise<ApolloQueryResult<CurrentCustomerQuery>>;
};

export const useMembership = (): UseMembershipReturns => {
  const { loading, membership, refetch } = useMembershipContext();

  if (!membership) {
    throw new Error('Membership is not available in context! Please use MembershipGuard for resolving.');
  }

  return { loading, membership, refetch };
};
