export type ApplePayPaymentRequest = ApplePayJS.ApplePayPaymentRequest;
export type ApplePayLineItem = ApplePayJS.ApplePayLineItem;
export type ApplePayLineItemType = ApplePayJS.ApplePayLineItemType;
export type ApplePayError = ApplePayJS.ApplePayError;
export type ApplePayPaymentToken = ApplePayJS.ApplePayPaymentToken;

export type ApplePaySessionObject = Record<string, unknown>;
export type ApplePaySessionGetter = () => Promise<ApplePaySessionObject | null>;

export interface ApplePayPlugin {
  canMakePayments(options: ApplePayCanMakeRequest): Promise<{ available: boolean }>;
  showPaymentRequest(request: ApplePayPaymentRequest): Promise<ShowPaymentRequestReturns>;
  showPaymentRequestWeb(
    request: ApplePayPaymentRequest,
    getApplePaySession: ApplePaySessionGetter,
  ): Promise<ShowPaymentRequestReturns>;

  updateTransactionStatus(options: {
    requestId: string; // Native Plugin parameter only
    statusCode: ApplePayPaymentStatus;
    errors?: ApplePayError[]; // Not utilized
  }): Promise<void>;
  // Native Plugin required method
  configure(options: { merchantIdentifier: string }): Promise<void>;
}

export type CreateApplePayPaymentRequestParams = {
  merchantName: string;
  supportedNetworks: ApplePayCardNetwork[];
  lineItems: ApplePayLineItem[];
};

export type CreateApplePayCanMakeRequestParams = {
  merchantId: string;
  supportedNetworks: ApplePayCardNetwork[];
};

export type ApplePayCanMakeRequest = {
  merchantId: string;
  merchantCapabilities: string[];
  supportedNetworks: ApplePayCardNetwork[];
};

export type ShowPaymentRequestReturns = { requestId: string; transactionId: string; transactionData: string };

// SUCCESS = 0, FAILURE = 1
export type ApplePayPaymentStatus = 0 | 1;

export const APPLE_PAY_TRANSACTION_CANCELED_CODE = '100' as const;

export type ApplePayCardNetwork =
  | 'amex'
  | 'cartesBancaires'
  | 'chinaUnionPay'
  | 'discover'
  | 'eftpos'
  | 'electron'
  | 'elo'
  | 'interac'
  | 'jcb'
  | 'mada'
  | 'maestro'
  | 'masterCard'
  | 'privateLabel'
  | 'visa'
  | 'vPay';
