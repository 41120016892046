import React, { useCallback, useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';

import { useCommunicationPreferenceUpdateMutation } from '@ocx/graphql';
import { logInDev } from '@ocx/utils';
import { useMembershipContext } from '@ocx/data-membership';
import { useConfiguration } from '../../configuration/useConfiguration';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { useCreateLoyaltyEvent } from '../../../hooks/useCreateLoyaltyEvent';
import { useSmsOptInOffer } from '../hooks/use-sms-opt-in-offer.hook';

import { SmsOptInContext } from './sms-opt-in.context';
import { useSmsOptInPopup } from '../../../components/modals/SMSOptInPopup';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';

export const SmsOptInContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { open: openSmsOptInPopup } = useSmsOptInPopup();
  const { membership } = useMembershipContext();
  const smsOtpInConfig = useConfiguration('smsOptIn');
  const { triggerEvent } = useRudderStack();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();

  const [createLoyaltyEvent] = useCreateLoyaltyEvent({
    membershipId: membership?.id ?? '',
    onError: showGenericErrorSnackbar,
  });

  const [communicationPreferenceUpdate] = useCommunicationPreferenceUpdateMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['currentCustomer', 'offers'],
    onCompleted: () => triggerEvent('sms_opt_in_toggled', { smsOptIn: true }),
    onError: showGenericErrorSnackbar,
  });

  const { offer } = useSmsOptInOffer({
    // We should not fetch SmsOptIn offer if smsOptIn already enabled
    skip: !smsOtpInConfig.enabled || !membership || membership.communicationPreference.smsOptIn,
    loyaltyEventName: smsOtpInConfig.modalOfferLoyaltyEventName,
  });
  const optInRejectedLoyaltyEventName =
    offer?.customData?.smsOptIn?.optInRejectedLoyaltyEventName ?? smsOtpInConfig.optInRejectedLoyaltyEventName;
  const modalAppearanceDelayMs =
    (offer?.customData?.smsOptIn?.modalAppearanceDelaySeconds ?? smsOtpInConfig.modalAppearanceDelaySeconds) * 1000;
  const isSmsOptInModalCanBeOpened = Boolean(offer && offer.customData?.smsOptIn?.enabled !== false);

  const handleAccepted = useCallback(() => {
    addBreadcrumb({ category: 'sms_opt_in', message: 'SmsOptIn accepted' });
    triggerEvent('sms_opt_in_accepted');
    communicationPreferenceUpdate({
      variables: { input: { smsOptInAt: new Date(), smsOptOutAt: null } },
    }).catch(logInDev);
  }, [communicationPreferenceUpdate, triggerEvent]);

  const handleRejected = useCallback(() => {
    addBreadcrumb({ category: 'sms_opt_in', message: 'Open SmsOptIn rejected' });
    triggerEvent('sms_opt_in_rejected');
    createLoyaltyEvent(optInRejectedLoyaltyEventName).catch(logInDev);
  }, [triggerEvent, createLoyaltyEvent, optInRejectedLoyaltyEventName]);

  const handleOpened = useCallback(() => {
    addBreadcrumb({ category: 'sms_opt_in', message: 'Open SmsOptIn modal' });
    triggerEvent('sms_opt_in_modal_appeared');
  }, [triggerEvent]);

  useEffect(() => {
    if (offer?.id) {
      addBreadcrumb({ category: 'sms_opt_in', message: 'SmsOptIn offer available' });
      triggerEvent('sms_opt_in_offer_available');
    }
    if (!offer?.id) {
      addBreadcrumb({ category: 'sms_opt_in', message: 'SmsOptIn offer is not present or not fetched' });
    }
    if (offer?.customData?.smsOptIn?.enabled === false) {
      addBreadcrumb({ category: 'sms_opt_in', message: "SmsOptIn disabled via offer's custom data" });
    }
  }, [offer?.customData?.smsOptIn?.enabled, offer?.id, triggerEvent]);

  useEffect(() => {
    if (!offer?.marketingContent || !isSmsOptInModalCanBeOpened) {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      handleOpened();
      openSmsOptInPopup({
        title: offer.marketingContent.title,
        shortDescription: offer.marketingContent.shortDescription,
        imageUrl: offer.marketingContent.imageUrl,
        terms: offer.marketingContent.terms,
        disclaimer: offer.marketingContent.disclaimer,
        disclaimerUrl: offer.marketingContent.disclaimerUrl,
        onAccepted: handleAccepted,
        onRejected: handleRejected,
      });
    }, modalAppearanceDelayMs);

    return () => clearTimeout(timeoutId);
  }, [
    modalAppearanceDelayMs,
    openSmsOptInPopup,
    offer?.marketingContent,
    isSmsOptInModalCanBeOpened,
    handleOpened,
    handleAccepted,
    handleRejected,
  ]);

  return <SmsOptInContext.Provider value={null}>{children}</SmsOptInContext.Provider>;
};
