import { defineMessages } from 'react-intl';

import { PaymentInstrumentType } from '@ocx/graphql';

export const digitalWalletInstrumentsTitle = defineMessages({
  [PaymentInstrumentType.ApplePay]: {
    id: 'paymentMethodSelect:ApplePayTitle',
    defaultMessage: 'Apple Pay',
    description: 'Apple Pay Instrument Type title',
  },
  [PaymentInstrumentType.GooglePay]: {
    id: 'paymentMethodSelect:GooglePayTitle',
    defaultMessage: 'Google Pay',
    description: 'Google Pay Instrument Type title',
  },
});

export const paymentInstrumentsMessages = defineMessages({
  'payment-instruments:card-created': {
    id: 'payment-instruments:card-created',
    defaultMessage: 'Card Added',
    description: 'Payment Instruments: Create Payment Instrument success message',
  },
  'payment-instruments:card-deleted': {
    id: 'payment-instruments:card-deleted',
    defaultMessage: 'Card Removed',
    description: 'Payment Instruments: Delete Payment Instrument success message',
  },
});
