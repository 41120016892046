// eslint-disable-next-line @nx/enforce-module-boundaries
import { Membership } from '@ocx/data-membership';
import { isBeforeDateTime, calcDateTime, parseDate } from '@ocx-app/lib/date/date';
import { ReceiverStatus } from '@ocx/graphql';

import {
  RECEIVER_STATUS_MODAL_WITHIN_DAYS,
  REFERRAL_CODE_LIQUID,
  REFERRAL_URL_LIQUID,
} from './refer-a-friend.constants';

export type BuildSmsContentParams = {
  smsTemplate: string;
  referralCode: string;
  referralUrl: string;
};
export const buildSmsContent = (params: BuildSmsContentParams) => {
  const { smsTemplate, referralUrl, referralCode } = params;
  return smsTemplate.replaceAll(REFERRAL_URL_LIQUID, referralUrl).replaceAll(REFERRAL_CODE_LIQUID, referralCode);
};

export type ShouldShowReceiverStatusMembershipPayload = Pick<Membership, 'id' | 'createdAt' | 'incomingReferralStatus'>;

export const shouldShowReceiverSuccessModal = (membership: ShouldShowReceiverStatusMembershipPayload): boolean => {
  const { createdAt, incomingReferralStatus } = membership;
  if (incomingReferralStatus !== ReceiverStatus.PendingQualifiedEvent) {
    return false;
  }
  const membershipCreatedAt = parseDate(createdAt);
  const statusModalVisibleEndDate = calcDateTime(membershipCreatedAt, 'add', RECEIVER_STATUS_MODAL_WITHIN_DAYS, 'day');
  return isBeforeDateTime(new Date(), statusModalVisibleEndDate);
};
