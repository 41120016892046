export type KountPluginEnvironment = 'TEST' | 'PRODUCTION';

export enum KountCollectorStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  NOT_STARED = 'not_started',
}

export type KountPluginInitCollectorParams = {
  merchantId: number;

  // web plugin only
  collectorHostname: string;

  // native app plugins only
  environment: KountPluginEnvironment;
};

export interface KountPluginCollectParams {
  sessionId: string;
}

export type KountPlugin = {
  initCollector(params: KountPluginInitCollectorParams): Promise<{ success: boolean }>;
  collect(param: KountPluginCollectParams): Promise<{ success: boolean }>;
  getCollectorStatus(): Promise<{ status: KountCollectorStatus }>;
};
