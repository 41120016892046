import { defineMessages } from 'react-intl';

export const buttonTextMessages = defineMessages({
  'button-text:submit': {
    id: 'button-text:submit',
    defaultMessage: 'Submit',
    description: 'Text for "Submit" button',
  },
  'button-text:confirm': {
    id: 'button-text:confirm',
    defaultMessage: 'Confirm',
    description: 'Text for "Confirm" button',
  },
  'button-text:save': {
    id: 'button-text:save',
    defaultMessage: 'Save',
    description: 'Text for "Save" button',
  },
  'button-text:apply': {
    id: 'button-text:apply',
    defaultMessage: 'Apply',
    description: 'Text for "Apply" button',
  },
  'button-text:cancel': {
    id: 'button-text:cancel',
    defaultMessage: 'Cancel',
    description: 'Text for "Cancel" button',
  },
  'button-text:close': {
    id: 'button-text:close',
    defaultMessage: 'Close',
    description: 'Text for "Close" button',
  },
  'button-text:next': {
    id: 'button-text:next',
    defaultMessage: 'Next',
    description: 'Text for "Next" button',
  },
  'button-text:yes': {
    id: 'button-text:yes',
    defaultMessage: 'Yes',
    description: 'Text for "Yes" button',
  },
  'button-text:no': {
    id: 'button-text:no',
    defaultMessage: 'No',
    description: 'Text for "No" button',
  },
  'button-text:send-email': {
    id: 'button-text:send-email',
    defaultMessage: 'Send email',
    description: 'Text for "Send email" button',
  },
  'button-text:call-phone-number': {
    id: 'button-text:call-phone-number',
    defaultMessage: 'Call {phoneNumber}',
    description: 'Text for "Call phone number" button',
  },
  'button-text:done': {
    id: 'button-text:done',
    defaultMessage: 'Done',
    description: 'Text for "Done" button',
  },
  'button-text:no-thanks': {
    id: 'button-text:no-thanks',
    defaultMessage: 'No, thanks',
    description: 'Text for "No, thanks" button',
  },
  'button-text:ok': {
    id: 'button-text:ok',
    defaultMessage: 'Ok',
    description: 'Text for "Ok" button',
  },
  'button-text:dismiss': {
    id: 'button-text:dismiss',
    defaultMessage: 'Dismiss',
    description: 'Text for "Dismiss" button',
  },
  'button-text:upgrade': {
    id: 'button-text:upgrade',
    defaultMessage: 'Upgrade',
    description: 'Text for "Upgrade" button',
  },
});
