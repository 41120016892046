import { useCallback, useMemo } from 'react';
import { Capacitor } from '@capacitor/core';
import { WorldpayReportGroup, createWorldpaySupportedId } from '@external/payments-worldpay';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PAYMENT_PROVIDER_TYPE } from '@ocx-app/modules/configuration/app-config.types';
import {
  useCreateApplePaySessionMutation,
  useGetFiservClientAccessTokenLazyQuery,
  useCreateParPaySessionMutation,
} from '@ocx/graphql';
import { ApplePaySessionGetter } from '@ocx/cap-apple-pay';

import {
  PaymentProviderSDKBasedApplePayConstructorParams,
  PaymentProviderSDKBasedGooglePayConstructorParams,
} from './payment-provider-sdk-based';
import { FiservClientTokenGetter, FiservPaymentProvider } from './fiserv-payment-provider';
import { WorldpayPaymentProvider } from './worldpay-payment-provider';
import { ParPayPaymentProvider, ParPaySessionGetter } from './parpay-payment-provider';
import { PaymentProvider } from './payment-provider.types';

export const usePaymentProvider = (): PaymentProvider => {
  const { config } = useConfiguration();
  const [createApplePaySessionMutation] = useCreateApplePaySessionMutation();
  const [getFiservClientAccessToken] = useGetFiservClientAccessTokenLazyQuery();
  const [createParPaySession] = useCreateParPaySessionMutation();

  // region Getters
  const getApplePaySession = useCallback<ApplePaySessionGetter>(async () => {
    if (!config.applePay) {
      return null;
    }
    const response = await createApplePaySessionMutation({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          merchantIdentifier: config.applePay.merchantId,
          displayName: config.applePay.merchantName || '',
          initiative: 'web',
          initiativeContext: window.location.hostname,
        },
      },
    });
    return response.data?.session.data || null;
  }, [config.applePay, createApplePaySessionMutation]);

  const getFiservClientToken = useCallback<FiservClientTokenGetter>(async () => {
    const { data } = await getFiservClientAccessToken({ fetchPolicy: 'network-only' });
    if (!data) {
      throw new Error('Failed to fetch Fiserv Client Access Token');
    }
    return data.getFiservClientAccessToken;
  }, [getFiservClientAccessToken]);

  const getParPaySession = useCallback<ParPaySessionGetter>(async () => {
    const { data } = await createParPaySession({ fetchPolicy: 'network-only' });
    if (!data) {
      throw new Error('Failed to create ParPay tokenization session');
    }
    return data.session;
  }, [createParPaySession]);

  // endregion

  const paymentProvider = useMemo<PaymentProvider | null>(() => {
    if (!config.paymentProvider) {
      return null;
    }

    let applePay: PaymentProviderSDKBasedApplePayConstructorParams | null = null;
    let googlePay: PaymentProviderSDKBasedGooglePayConstructorParams | null = null;

    if (config.applePay) {
      applePay = {
        merchantId: config.applePay.merchantId,
        merchantName: config.applePay.merchantName,
        supportedNetworks: config.applePay.supportedNetworks,
        getApplePaySession,
      };
    }

    if (config.googlePay) {
      googlePay = {
        allowCreditCards: config.googlePay.allowCreditCards,
        allowPrepaidCards: config.googlePay.allowPrepaidCards,
        allowedCardNetworks: config.googlePay.allowedCardNetworks,
        environment: config.googlePay.environment,
        merchantId: config.googlePay.merchantId,
        merchantName: config.googlePay.merchantName,
        gatewayParameters: {},
      };
    }

    if (config.paymentProvider.type === PAYMENT_PROVIDER_TYPE.FISERV) {
      if (googlePay && config.googlePay) {
        googlePay.gatewayParameters = {
          gateway: config.googlePay.gateway,
          gatewayMerchantId: config.googlePay.gatewayMerchantId,
        };
      }

      return new FiservPaymentProvider({
        applePay,
        googlePay,
        getFiservClientToken,
      });
    }

    if (config.paymentProvider.type === PAYMENT_PROVIDER_TYPE.WORLDPAY) {
      const reportGroup: WorldpayReportGroup = Capacitor.isNativePlatform() ? '*native' : '*web';
      if (googlePay) {
        googlePay.gatewayParameters = {
          gateway: 'vantiv',
          'vantiv:merchantPayPageId': config.paymentProvider.eProtectGooglePayPageId,
          'vantiv:merchantOrderId': createWorldpaySupportedId(),
          'vantiv:merchantTransactionId': createWorldpaySupportedId(),
          'vantiv:merchantReportGroup': reportGroup,
        };
      }

      return new WorldpayPaymentProvider({
        payPageId: config.paymentProvider.eProtectPayPageId,
        sdkApiUrl: config.paymentProvider.eProtectWebSdkApiUrl,
        sdkUrl: config.paymentProvider.eProtectWebSdkUrl,
        reportGroup,
        applePay,
        googlePay,
      });
    }

    if (config.paymentProvider.type === PAYMENT_PROVIDER_TYPE.PAR_PAY) {
      if (googlePay && config.googlePay) {
        googlePay.gatewayParameters = {
          gateway: config.googlePay.gateway,
          gatewayMerchantId: config.googlePay.gatewayMerchantId,
        };
      }
      return new ParPayPaymentProvider({ getParPaySession, applePay, googlePay });
    }

    return null;
  }, [
    config.applePay,
    config.googlePay,
    config.paymentProvider,
    getApplePaySession,
    getFiservClientToken,
    getParPaySession,
  ]);

  if (!paymentProvider) {
    throw new Error('Payment Provider is not configured');
  }

  return paymentProvider;
};
