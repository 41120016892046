import { useCallback, useMemo } from 'react';
import last from 'lodash/last';

import { LoyaltyActionHistoryQueryVariables, useLoyaltyActionHistoryQuery } from '@ocx/graphql';
import { logInDev } from '@ocx/utils';
import { SanitizedActivateTransactionEdge, SanitizedPointChangeEdge } from './transaction-history.types';
import { PAGINATION_SIZE } from './transaction-history.constants';
import { loyaltyActivitiesFetchMoreResolver } from './transaction-history.utils';

export type SanitizedLoyaltyActionHistoryParams = {
  variables: LoyaltyActionHistoryQueryVariables;
};
export const useSanitizedLoyaltyActionHistory = ({ variables }: SanitizedLoyaltyActionHistoryParams) => {
  const { data, loading, networkStatus, refetch, fetchMore } = useLoyaltyActionHistoryQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables,
  });

  const sanitizedData = useMemo(() => {
    const rawPointChanges = data?.customer?.membership?.pointChanges?.edges || [];
    const rawTransactions = data?.customer?.membership?.transactions?.edges || [];
    return {
      pointChanges: rawPointChanges.filter((edge): edge is SanitizedPointChangeEdge => {
        return !!edge?.cursor && !!edge?.node;
      }),
      transactions: rawTransactions.filter((edge): edge is SanitizedActivateTransactionEdge => {
        return !!edge?.cursor && !!edge?.node;
      }),
    };
  }, [data?.customer?.membership?.pointChanges?.edges, data?.customer?.membership?.transactions?.edges]);

  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: {
        size: PAGINATION_SIZE,
        pointChangeCursor: last(sanitizedData.pointChanges)?.cursor || null,
        transactionCursor: last(sanitizedData.transactions)?.cursor || null,
      },
      updateQuery: (prev, { fetchMoreResult: next }) => loyaltyActivitiesFetchMoreResolver(prev, next),
    }).catch(logInDev);
  }, [fetchMore, sanitizedData.pointChanges, sanitizedData.transactions]);

  return useMemo(() => {
    return {
      networkStatus,
      loading,
      refetch,
      fetchMore: fetchNextPage,
      data: sanitizedData,
    };
  }, [fetchNextPage, loading, networkStatus, refetch, sanitizedData]);
};
