import { FormattedMessage } from 'react-intl';

import { InfoIcon } from '@ocx/ui-icons';
import { walletSizeLimitHintMessage } from './wallet-size-limit-hint.messages';
import { SizingMultiplier } from '../../sizing-multiplier.constant';
import { Typography } from '../../typography/typography.component';

type WalletSizeLimitHintProps = {
  sizeLimit: number;
};

export const WalletSizeLimitHint = ({ sizeLimit }: WalletSizeLimitHintProps) => {
  return (
    <Typography variant="caption" color="textSecondary" display="flex" alignContent="center">
      <InfoIcon sx={{ color: 'text.disabled', mr: SizingMultiplier.xs, width: 16, height: 16 }} />
      <FormattedMessage {...walletSizeLimitHintMessage} values={{ sizeLimit }} />
    </Typography>
  );
};
