interface EProtectSDK {
  sendToEprotect(
    request: EprotectTokenizationRequest,
    data: { cvv2: string; accountNum: string } | NonNullable<unknown>, // NonNullable<unknown> => {}
    onSuccess: (response: EProtectTokenizationResponse) => void,
    onError: () => void,
    onTimeout: () => void,
  ): void;
}

declare global {
  interface Window {
    eProtect: { new (): EProtectSDK };
  }
}
/**
 * It is not clear (based on docs for eProtect) what values should be used as "reportGroup" for eProtect
 * Based on GooglePay example for Vantiv (eProtect) Gateway Merchant example (it uses "*web" in example)
 * Decided use values "*web" for Web and "*native" for native apps
 */
export type WorldpayReportGroup = '*web' | '*native';

export type EprotectPaymentInstrumentTokenizationRequest = {
  paypageId: string | null;
  url: string | null;
  id: string;
  orderId: string;
  reportGroup: WorldpayReportGroup;
  checkoutIdMode: false;
};
export type EprotectApplePayTokenizationRequest = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applepay: any; //ApplePayPaymentToken['paymentData']
  checkoutIdMode: boolean;
  gateway: string | null;
  id: string;
  orderId: string;
  paypageId: null | string;
  reportGroup: WorldpayReportGroup;
  url: string | null;
};
export type EprotectTokenizationRequest =
  | EprotectPaymentInstrumentTokenizationRequest
  | EprotectApplePayTokenizationRequest;

export enum EProtectCardType {
  'VI' = 'VI',
  'MC' = 'MC',
  'AX' = 'AX',
  'DI' = 'DI',
}

export type EProtectTokenizationResponse = {
  /**
   * @name id
   * @description
   * The merchant-assigned unique value representing this transaction in your system
   * (Mirrored back from the request)
   * @summary Max length: 25
   * @type {string}
   * @example "134b462424da4fe5983a98119"
   */
  id: string;
  /**
   * @name orderId
   * @description
   * The merchant-assigned unique value representing the order in your system.
   * (Mirrored back from the request)
   * @summary Max length: 256
   * @type {string}
   * @example "8358f5744bc94030a9be14923"
   */
  orderId: string;
  paypageRegistrationId: string; // Low Value Toke - The temporary identifier used to facilitate the mapping of a token to a card number

  bin: string; // The bank identification number (BIN), which is the first six digits of the credit card number
  firstSix: string; // (Mirrored back from the request) The first six digits of the credit card number
  lastFour: string; // (Mirrored back from the request) The last four digits of the credit card number
  /**
   * @name type
   * @description
   * The method of payment for this transaction (VI=Visa, MC=Mastercard, AX=Amex, DI=Discover).
   * Not returned when the method of payment cannot be determined.
   * @type {EProtectCardType}
   * @example "VI"
   */
  type?: EProtectCardType;

  message: string; // The transaction response returned by Worldpay, corresponding to the response reason code. If the transaction was declined, this message provides a reason
  reportGroup: string;
  response: string; // The three-digit transaction response code returned by Worldpay for this transaction
  /**
   * @name responseTime
   * @description The date and time (GMT) the transaction was processed.
   * @type {string}
   * @example "2021-01-15T11:53:21"
   */
  responseTime: string;
  targetServer: string;
  /**
   * @name vantivTxnId
   * @description The automatically-assigned unique transaction identifier.
   * @summary Max length: 17
   * @type {string}
   * @example "83992797033670967"
   */
  vantivTxnId: string;
};

export type EProtectOptions = {
  paypageId: string; // keep lowercase for native lib props consistent
  sdkApiUrl: string;
  sdkUrl: string;
};

export enum EProtectStatus {
  /**
   * Default value when application started
   */
  INITIAL = 1,
  /**
   * Dependencies loading (eProtect SDK, JQuery) are started
   */
  LOADING = 2,
  /**
   * Dependencies are loaded
   */
  LOADED = 3,
  /**
   * Dependencies are loaded and available
   */
  READY = 4,
  /**
   * Error happened during loading dependencies or API is not available
   */
  FAILED = 5,
}
