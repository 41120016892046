import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.7093 14.8246L16.9493 10.5846C17.043 10.4916 17.1174 10.381 17.1682 10.2591C17.219 10.1373 17.2451 10.0066 17.2451 9.87457C17.2451 9.74256 17.219 9.61185 17.1682 9.49C17.1174 9.36814 17.043 9.25753 16.9493 9.16457C16.762 8.97832 16.5085 8.87378 16.2443 8.87378C15.9801 8.87378 15.7267 8.97832 15.5393 9.16457L11.9993 12.7046L8.45932 9.16457C8.27196 8.97832 8.0185 8.87378 7.75432 8.87378C7.49013 8.87378 7.23668 8.97832 7.04932 9.16457C6.95664 9.25801 6.88331 9.36883 6.83355 9.49067C6.78378 9.6125 6.75856 9.74297 6.75932 9.87457C6.75856 10.0062 6.78378 10.1366 6.83355 10.2585C6.88331 10.3803 6.95664 10.4911 7.04932 10.5846L11.2893 14.8246C11.3823 14.9183 11.4929 14.9927 11.6147 15.0435C11.7366 15.0942 11.8673 15.1204 11.9993 15.1204C12.1313 15.1204 12.262 15.0942 12.3839 15.0435C12.5058 14.9927 12.6164 14.9183 12.7093 14.8246Z" />
    </SvgIcon>
  );
};
