import {
  FiservTokenizePaymentInstrumentCreditParams,
  FiservTokenizePaymentInstrumentPrepaidParams,
} from '@external/payments-fiserv';

import {
  TokenizeCreditPaymentInstrumentParams,
  TokenizePrepaidPaymentInstrumentParams,
} from '../payment-provider-sdk-based';
import { FiservClientAccessToken } from './fiserv-payment-provider.types';

const prepareOptionsParams = (token: FiservClientAccessToken) => ({
  baseUrl: token.baseUrl,
  apiKey: token.apiKey,
  oAuthToken: token.oAuthToken,
  fdCustomerId: token.fdCustomerId,
});

const prepareEncryptionParams = (token: FiservClientAccessToken) => ({
  publicKey: token.publicKey,
  algorithm: token.algorithm,
});

export const prepareCreditTokenizationParams = (params: {
  token: FiservClientAccessToken;
  paymentInstrumentParams: TokenizeCreditPaymentInstrumentParams;
}): FiservTokenizePaymentInstrumentCreditParams => {
  const { token, paymentInstrumentParams } = params;

  return {
    options: prepareOptionsParams(token),
    encryption: prepareEncryptionParams(token),
    payload: paymentInstrumentParams,
  };
};

export const preparePrepaidTokenizationParams = (params: {
  token: FiservClientAccessToken;
  paymentInstrumentParams: TokenizePrepaidPaymentInstrumentParams;
}): FiservTokenizePaymentInstrumentPrepaidParams => {
  const { token, paymentInstrumentParams } = params;

  return {
    options: prepareOptionsParams(token),
    encryption: prepareEncryptionParams(token),
    payload: paymentInstrumentParams,
  };
};
