import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const LocationArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.15 2.86001C20.768 2.47057 20.2835 2.19723 19.7526 2.07158C19.2217 1.94594 18.6661 1.97313 18.15 2.15001L4.00005 6.88001C3.42936 7.06681 2.93074 7.42616 2.573 7.90845C2.21527 8.39073 2.01607 8.97218 2.0029 9.57251C1.98973 10.1728 2.16324 10.7625 2.49948 11.26C2.83572 11.7575 3.31811 12.1384 3.88005 12.35L9.12005 14.35C9.23937 14.3956 9.34801 14.4654 9.43919 14.5548C9.53038 14.6443 9.60216 14.7516 9.65005 14.87L11.65 20.12C11.8536 20.674 12.2231 21.1518 12.708 21.4882C13.193 21.8246 13.7698 22.0033 14.36 22H14.43C15.0311 21.9891 15.6136 21.7903 16.096 21.4317C16.5784 21.073 16.9365 20.5724 17.1201 20L21.85 5.83001C22.0221 5.31895 22.0477 4.76995 21.924 4.2451C21.8003 3.72024 21.5322 3.24046 21.15 2.86001ZM20 5.20001L15.22 19.38C15.1645 19.5595 15.0531 19.7165 14.9019 19.828C14.7508 19.9396 14.5679 19.9999 14.38 20C14.1933 20.0031 14.01 19.9492 13.8546 19.8456C13.6992 19.742 13.579 19.5936 13.51 19.42L11.51 14.17C11.3651 13.7885 11.1414 13.4418 10.8537 13.1524C10.566 12.863 10.2207 12.6373 9.84005 12.49L4.59005 10.49C4.41294 10.4251 4.2607 10.3062 4.15483 10.1501C4.04896 9.99399 3.99482 9.80856 4.00005 9.62001C4.0002 9.43215 4.06047 9.24928 4.17202 9.09813C4.28358 8.94699 4.44058 8.83551 4.62005 8.78001L18.8 4.05001C18.9629 3.98366 19.1414 3.96588 19.3141 3.99883C19.4868 4.03179 19.6462 4.11406 19.7731 4.2357C19.9001 4.35734 19.989 4.51314 20.0293 4.68427C20.0696 4.8554 20.0594 5.03453 20 5.20001Z" />
    </SvgIcon>
  );
};
