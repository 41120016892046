import { useEffect, useRef } from 'react';
import { addBreadcrumb } from '@sentry/react';

import { GooglePayButtonColor } from '@ocx/cap-google-pay';

import { usePaymentProvider } from './use-payment-provider.hook';

export type GooglePayButtonProps = {
  onClick?(): void;
  variant: GooglePayButtonColor;
};

export const GooglePayButton = (props: GooglePayButtonProps) => {
  const { onClick, variant } = props;
  const paymentProvider = usePaymentProvider();

  const root = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const exec = async () => {
      if (!root.current) {
        return;
      }

      const button = await paymentProvider.createGooglePayButton({ onClick, buttonColor: variant });
      if (button === null) {
        return;
      }

      if (root.current.lastChild) {
        root.current.removeChild(root.current.lastChild);
      }
      root.current.appendChild(button);
    };
    exec().catch(() =>
      addBreadcrumb({
        message: '[GooglePayButton] Button is not rendered.',
      }),
    );
  }, [onClick, variant, paymentProvider]);

  return <div style={{ width: '100%' }} ref={root} />;
};
