import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowUpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M11.2951 9.16957L7.05508 13.4096C6.96135 13.5025 6.88695 13.6131 6.83618 13.735C6.78542 13.8569 6.75928 13.9876 6.75928 14.1196C6.75928 14.2516 6.78542 14.3823 6.83618 14.5041C6.88695 14.626 6.96135 14.7366 7.05508 14.8296C7.24244 15.0158 7.49589 15.1204 7.76008 15.1204C8.02426 15.1204 8.27771 15.0158 8.46508 14.8296L12.0051 11.2896L15.5451 14.8296C15.7324 15.0158 15.9859 15.1204 16.2501 15.1204C16.5143 15.1204 16.7677 15.0158 16.9551 14.8296C17.0478 14.7361 17.1211 14.6253 17.1708 14.5035C17.2206 14.3816 17.2458 14.2512 17.2451 14.1196C17.2458 13.988 17.2206 13.8575 17.1708 13.7357C17.1211 13.6138 17.0478 13.503 16.9551 13.4096L12.7151 9.16957C12.6221 9.07584 12.5115 9.00145 12.3897 8.95068C12.2678 8.89991 12.1371 8.87377 12.0051 8.87377C11.8731 8.87377 11.7424 8.89991 11.6205 8.95068C11.4986 9.00145 11.388 9.07584 11.2951 9.16957Z" />
    </SvgIcon>
  );
};
