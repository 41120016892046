import { useCallback, useMemo } from 'react';
import last from 'lodash/last';

import {
  PrimaryTransactionStatus,
  SecondaryTransactionStatus,
  TransactionHistoryQueryVariables,
  useTransactionHistoryQuery,
} from '@ocx/graphql';
import { logInDev } from '@ocx/utils';
import { SanitizedTransactionHistoryEdge } from './transaction-history.types';
import { PAGINATION_SIZE } from './transaction-history.constants';
import { transactionHistoryFetchMoreResolver } from './transaction-history.utils';

export type SanitizedTransactionHistoryParams = {
  variables: TransactionHistoryQueryVariables;
};
export const useSanitizedTransactionHistory = ({ variables }: SanitizedTransactionHistoryParams) => {
  const { data, loading, networkStatus, refetch, fetchMore } = useTransactionHistoryQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables,
  });

  const sanitizedData = useMemo(() => {
    return (
      data?.customer?.transactions?.edges?.filter((edge): edge is SanitizedTransactionHistoryEdge => {
        return !!edge?.node && !!edge?.cursor;
      }) || []
    );
  }, [data?.customer?.transactions?.edges]);

  const fetchNextPage = useCallback(() => {
    fetchMore({
      variables: {
        primaryTransactionStatus: PrimaryTransactionStatus.Completed,
        secondaryTransactionStatus: SecondaryTransactionStatus.Closed,
        size: PAGINATION_SIZE,
        cursor: last(sanitizedData)?.cursor || null,
      },
      updateQuery: (prev, { fetchMoreResult: next }) => transactionHistoryFetchMoreResolver(prev, next),
    }).catch(logInDev);
  }, [fetchMore, sanitizedData]);

  return useMemo(() => {
    return {
      networkStatus,
      loading,
      refetch,
      fetchMore: fetchNextPage,
      data: sanitizedData,
    };
  }, [fetchNextPage, loading, networkStatus, refetch, sanitizedData]);
};
