import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';
import { Button, Typography } from '@ocx/ui';

import { CardExpiredPopupProps } from './card-expired-popup.types';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { logInDev } from '../../../lib/logger/logger';
import { messages } from './card-expired-popup.messages';

export const CardExpiredPopupController = (props: CardExpiredPopupProps) => {
  const intl = useIntl();
  const {
    title = intl.formatMessage(messages.cardExpiredPopupTitle),
    description = intl.formatMessage(messages.cardExpiredPopupDescription),
    confirmButtonText = intl.formatMessage(messages.cardExpiredPopupConfirmButtonText),
    cancelButtonText = intl.formatMessage(buttonTextMessages['button-text:cancel']),
    cancelOnClickOutsideDisabled = false,
    onConfirm,
    onCancel,
    onClosed,
  } = props;

  const { visible, hide, remove } = useModal();

  const handleExited = useCallback(() => {
    onClosed?.();
    remove();
  }, [remove, onClosed]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    hide().catch(logInDev);
  }, [hide, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    hide().catch(logInDev);
  }, [hide, onCancel]);

  return (
    <ModalDrawer
      open={visible}
      onClose={cancelOnClickOutsideDisabled ? undefined : handleCancel}
      onExited={handleExited}>
      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Stack spacing={SizingMultiplier.md}>
          <Typography variant="h5" component="h1">
            {title}
          </Typography>
          {description && <Typography>{description}</Typography>}
        </Stack>
        <Stack spacing={SizingMultiplier.sm}>
          <Button onClick={handleConfirm} color="error">
            {confirmButtonText}
          </Button>
          <Button onClick={handleCancel} variant="text">
            {cancelButtonText}
          </Button>
        </Stack>
      </Stack>
    </ModalDrawer>
  );
};
