import { useReactiveVar } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { isLoggedInVar } from '@ocx-app/modules/auth/authReactiveVariables';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { sharedStorage } from '@ocx-app/modules/shared-storage/shared.storage';
import { logInDev } from '@ocx/utils';

import { REFERRAL_CODE_QUERY_PARAM } from './referral-link.constants';
import { RECEIVER_TRIED_TO_USE_CODE_AT } from './refer-a-friend.constants';

export const ReferralLinkRedirectController = () => {
  const referralProgramConfig = useConfiguration('loyaltyProgram.referralProgramConfig');
  const { code } = useParams<{ code?: string }>();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  useEffect(() => {
    if (isLoggedIn && referralProgramConfig?.referralProgramEnabled) {
      sharedStorage.setItem(RECEIVER_TRIED_TO_USE_CODE_AT, new Date()).catch(logInDev);
    }
  }, [isLoggedIn, referralProgramConfig?.referralProgramEnabled]);
  if (!isLoggedIn && referralProgramConfig?.referralProgramEnabled) {
    return (
      <Navigate
        to={{ pathname: AppRoutePath.Auth, search: code ? `?${REFERRAL_CODE_QUERY_PARAM}=${code}` : '' }}
        replace
      />
    );
  }
  return <Navigate to={AppRoutePath.Home} replace />;
};
