import { useCallback } from 'react';
import { addBreadcrumb } from '@sentry/react';

import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';
import { copyToClipboard } from '@ocx/utils';
import { useSubmitReferralCodeSharedEventMutation } from '@ocx/graphql';

export const useCopyUrl = () => {
  const { triggerEvent } = useRudderStack();

  const [submitReferralCodeSharedEvent] = useSubmitReferralCodeSharedEventMutation();

  return useCallback(
    async (textToCopy: string) => {
      try {
        await copyToClipboard(textToCopy);
        addBreadcrumb({
          message: 'Referrals -> Copy URL complete.',
        });
        triggerEvent('referral_link_copied');
        addBreadcrumb({
          message: 'Referrals -> CopyURL -> submitReferralCodeSharedEvent started.',
        });
        submitReferralCodeSharedEvent()
          .catch(() => {
            addBreadcrumb({
              message: 'Referrals -> CopyURL -> submitReferralCodeSharedEvent threw an error.',
            });
          })
          .then(() => {
            addBreadcrumb({
              message: 'Referrals -> CopyURL -> submitReferralCodeSharedEvent complete.',
            });
          });
      } catch {
        addBreadcrumb({
          message: 'Referrals -> Copy URL was pressed, but navigator.clipboard.writeText threw an error.',
        });
      }
    },
    [submitReferralCodeSharedEvent, triggerEvent],
  );
};
