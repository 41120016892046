import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';
import { Typography, SizingMultiplier, ConfettiAnimation } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { LoyaltyCurrencyChange } from '@ocx/feat-loyalty-currency';
import { RoundedBorderStack } from '../RoundedBorderStack/RoundedBorderStack';
import { messages } from '../../modules/survey/survey.messages';
import { SurveyAward } from '../../modules/survey/survey.types';
import { AwardType } from '../../modules/offers/award.types';

export type SurveySubmitAwardAnimationProps = {
  pointsStrategy: PointsStrategy;
  award: SurveyAward;
  shouldShowVoucher?: boolean;
  onAnimationComplete?: () => void;
  isDisplayed: boolean;
};

const SurveySubmitAwardAnimation = ({
  pointsStrategy,
  award,
  onAnimationComplete,
  isDisplayed,
  shouldShowVoucher = false,
}: SurveySubmitAwardAnimationProps) => {
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);
  const handleAnimationReady = useCallback(() => {
    setShouldPlayAnimation(true);
  }, [setShouldPlayAnimation]);

  const isAwardPoints = !!award && award.type === AwardType.Points;
  const isAwardVouchers = !!award && award.type === AwardType.Vouchers;
  const arePointsUsed = pointsStrategy !== PointsStrategy.NotUsed;

  /* Setting display="none" is necessary because the animation library dynamically imports dependencies, and in order to initialize the library, it must be rendered. */
  return (
    <Box
      display={isDisplayed ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      minHeight={200}
      position="relative"
      overflow="hidden">
      <ConfettiAnimation
        shouldPlay={isDisplayed && shouldPlayAnimation}
        onComplete={onAnimationComplete}
        onReady={handleAnimationReady}
      />

      <Box
        position="relative"
        sx={{
          backgroundImage: 'radial-gradient(ellipse, white 10%, transparent 70%)',
        }}>
        {isAwardPoints && arePointsUsed && (
          <LoyaltyCurrencyChange
            points={award.points}
            iconSize="large"
            iconVariant="multiple"
            typographyProps={{ variant: 'h2', color: 'primary' }}
          />
        )}
        {isAwardVouchers && (!shouldShowVoucher || !award.offer.title) && (
          <Typography variant="h2" color="primary" sx={{ textTransform: 'capitalize' }}>
            <FormattedMessage {...messages['survey:submit-animation:award:title']} />
          </Typography>
        )}
        {isAwardVouchers && shouldShowVoucher && award.offer.title && (
          <RoundedBorderStack
            direction="row"
            spacing={SizingMultiplier.md}
            alignItems="center"
            paddingX={1.5}
            paddingY={SizingMultiplier.xs}>
            {award.offer.imageUrl && (
              <Box
                src={award.offer.imageUrl}
                alt="Award Picture"
                component="img"
                sx={{
                  width: 72,
                  height: 72,
                  borderRadius: 2,
                  flexShrink: 0,
                  objectFit: 'cover',
                  overflow: 'hidden',
                }}
              />
            )}
            <Typography variant="subtitle1">{award.offer.title}</Typography>
          </RoundedBorderStack>
        )}
      </Box>
    </Box>
  );
};

export default SurveySubmitAwardAnimation;
