import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deletedUserLoginPopupTitle: {
    id: 'deleted-user-login-popup:title',
    defaultMessage: 'Membership deleted',
    description: 'Title on deletion placeholder user state login popup',
  },
  deletedUserLoginPopupDescription: {
    id: 'deleted-user-login-popup:description',
    defaultMessage: 'This membership has been deleted. Please contact support for additional help.',
    description: 'Message on deletion placeholder user state login popup',
  },
});
