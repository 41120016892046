import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useMembership } from '@ocx/data-membership';
import { BottomNavigation } from '../../../components/navigation/BottomNavigation';
import { useBottomNavigationController } from '../../../modules/bottom-navigation/useBottomNavigationController';

export const BottomNavigationController: React.FC = () => {
  const location = useLocation();
  const { membership } = useMembership();
  const { tabs } = useBottomNavigationController({
    hasEmail: membership.hasEmail,
  });
  const activeTab = useMemo(
    () => tabs.find((tab) => location.pathname.startsWith(tab.routerPath)),
    [location.pathname, tabs],
  );

  return <BottomNavigation value={activeTab?.key ?? tabs[0].key} tabs={tabs} />;
};
