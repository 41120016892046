import { PaymentInstrumentType } from '@ocx/graphql';

import { ParPayCardType } from './parpay-payment-provider.types';

export const cartTypePaymentInstrumentTypeMap: Record<ParPayCardType, PaymentInstrumentType> = {
  VIC: PaymentInstrumentType.Visa,
  VID: PaymentInstrumentType.Visa,
  MCC: PaymentInstrumentType.Mastercard,
  MCD: PaymentInstrumentType.Mastercard,
  NVC: PaymentInstrumentType.Discover,
  NVD: PaymentInstrumentType.Discover,
  JBC: PaymentInstrumentType.Jcb,
  JBD: PaymentInstrumentType.Jcb,
  JCB: PaymentInstrumentType.Jcb,
  AXC: PaymentInstrumentType.AmericanExpress,
  GCC: PaymentInstrumentType.Prepaid,
  DCC: PaymentInstrumentType.DinersClub,
};

export const IFrameMessages = {
  iFrameLoaded: 'iframeLoaded',
  iFrameHeight: 'iframeHeight',
  submitButtonState: 'enablePlaceOrder',
  validation: 'fieldValidationStatus',
  tokenizationResponse: 'response',
} as const;
