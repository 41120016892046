import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';
import { logInDev } from '@ocx-app/lib/logger/logger';
import { useShowGenericErrorSnackbar } from '@ocx-app/hooks/useShowGenericErrorSnackbar';
import { useShowGraphqlUserVisibleErrorSnackbar } from '@ocx-app/hooks/use-show-graphql-user-visible-error-snackbar.hook';
import { useBlurOverlayStylesWhenAppIsInactive } from '@ocx-app/components/blur/blur-overlay-when-app-is-inactive.component';
import { useWalletPinCodePopup } from '@ocx-app/components/modals';
import { useSnackbar } from '@ocx-app/lib/snackbar/snackbar';
import { Button } from '@ocx/ui';
import { PaymentAccountType, useCreatePaymentInstrumentMutation } from '@ocx/graphql';
import { useMembership } from '@ocx/data-membership';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  PaymentProviderIFrameBasedTokenizedPaymentInstrument,
  usePaymentProviderIFrameBased,
} from '@ocx/data-payment-provider';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { paymentInstrumentsMessages } from '@ocx/data-payment-instruments';

import { CONTAINER_ID, IFRAME_ID } from './add-card-iframe-based.constants';
import { AddCardIFrameBasedLayout } from './add-card-iframe-based.layout';

type AddCardIframeBasedControllerProps = {
  onCardAdded(params: { paymentInstrumentId: string | null }): void;
};

export const AddCardIFrameBasedController = (props: AddCardIframeBasedControllerProps) => {
  const { onCardAdded } = props;

  const [isIFrameLoading, setIsIFrameLoading] = useState(true);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const isIFrameLoadingStarted = useRef(false);

  const paymentProvider = usePaymentProviderIFrameBased();
  const { loading: isMembershipLoading, membership } = useMembership();

  const blurOverlayStyles = useBlurOverlayStylesWhenAppIsInactive();
  const showGraphqlUserVisibleErrorSnackbar = useShowGraphqlUserVisibleErrorSnackbar();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const snackbar = useSnackbar();
  const intl = useIntl();

  const { open: openWalletPinCodePopup } = useWalletPinCodePopup();
  const [createPaymentInstrument, { loading: isPaymentInstrumentCreating }] = useCreatePaymentInstrumentMutation({
    refetchQueries: ['getPaymentInstruments'],
    onCompleted: () => {
      snackbar.open(intl.formatMessage(paymentInstrumentsMessages['payment-instruments:card-created']), {
        variant: 'success',
      });
    },
  });

  const handlePaymentInstrumentTokenized = useCallback(
    async (params: PaymentProviderIFrameBasedTokenizedPaymentInstrument) => {
      try {
        // In case if user HAS passcode, we should not ask to enter it
        if (!membership.hasPasscode) {
          const pinCode = await openWalletPinCodePopup();
          // Handle failed or cancelled pin creation
          if (pinCode === null) {
            return;
          }
        }

        const { data } = await createPaymentInstrument({
          variables: {
            input: {
              paymentType: params.cardType,
              accountType: PaymentAccountType.Credit,
              nonce: params.oneTimeToken,
              last4: params.maskedCardNumber.slice(-4),
              binRange: params.maskedCardNumber.substring(0, 6),
              expiration: {
                expirationMonth: params.cardExpirationDate.substring(0, 2),
                expirationYear: params.cardExpirationDate.slice(-2),
              },
              tokenizationSessionId: params.sessionId,
            },
          },
        });
        if (!data) {
          throw new Error('[AddCardIFrameBasedController] createPaymentInstrument: no data');
        }
        onCardAdded({ paymentInstrumentId: data.customerPaymentInstrumentCreate.uuid });
      } catch (error) {
        logInDev(error);
        showGraphqlUserVisibleErrorSnackbar(error);
      }
    },
    [
      createPaymentInstrument,
      membership.hasPasscode,
      onCardAdded,
      openWalletPinCodePopup,
      showGraphqlUserVisibleErrorSnackbar,
    ],
  );

  const handleSubmitError = useCallback(async () => {
    showGenericErrorSnackbar();
  }, [showGenericErrorSnackbar]);

  const handleIFrameLoaded = useCallback(async () => {
    setIsIFrameLoading(false);
  }, []);

  const handleIsSubmitButtonEnabled = useCallback(async (value: boolean) => {
    setIsSubmitButtonEnabled(value);
  }, []);

  // Render IFrame
  useEffect(() => {
    if (isIFrameLoadingStarted.current) {
      return;
    }
    isIFrameLoadingStarted.current = true;

    paymentProvider
      .render({
        iFrameId: IFRAME_ID,
        containerId: CONTAINER_ID,
      })
      .catch((e) => {
        logInDev(e);
        showGenericErrorSnackbar();
        onCardAdded({ paymentInstrumentId: null });
      });
  }, [onCardAdded, paymentProvider, showGenericErrorSnackbar]);

  // Add/update event listeners for the IFrame events
  useEffect(() => {
    paymentProvider.addEventListeners({
      iFrameId: IFRAME_ID,
      onPaymentInstrumentTokenized: handlePaymentInstrumentTokenized,
      onSubmitError: handleSubmitError,
      onIFrameLoaded: handleIFrameLoaded,
      onIsSubmitButtonEnabled: handleIsSubmitButtonEnabled,
      // "onValidationError" is not used because:
      // 1. We were not able to trigger that event
      // 2. IFrame displays validation errors itself
      // 2. onIsSubmitButtonEnabled cb handles submit button state
    });
    return () => {
      paymentProvider.removeEventListeners();
    };
  }, [
    paymentProvider,
    handlePaymentInstrumentTokenized,
    handleSubmitError,
    handleIFrameLoaded,
    handleIsSubmitButtonEnabled,
  ]);

  const handleSubmit = useCallback(() => {
    paymentProvider.submit({ iFrameId: IFRAME_ID }).catch(logInDev);
  }, [paymentProvider]);

  return (
    <AddCardIFrameBasedLayout
      loading={isIFrameLoading || isPaymentInstrumentCreating || isMembershipLoading}
      sx={blurOverlayStyles}>
      <Stack spacing={2}>
        <div id={CONTAINER_ID} />
        {!isIFrameLoading && (
          <Button fullWidth onClick={handleSubmit} disabled={!isSubmitButtonEnabled}>
            <FormattedMessage {...buttonTextMessages['button-text:submit']} />
          </Button>
        )}
      </Stack>
    </AddCardIFrameBasedLayout>
  );
};
