import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const TimerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66 0.333984C2.98 0.333984 0 3.32065 0 7.00065C0 10.6807 2.98 13.6673 6.66 13.6673C10.3467 13.6673 13.3333 10.6807 13.3333 7.00065C13.3333 3.32065 10.3467 0.333984 6.66 0.333984ZM6.66667 12.334C3.72 12.334 1.33333 9.94732 1.33333 7.00065C1.33333 4.05398 3.72 1.66732 6.66667 1.66732C9.61333 1.66732 12 4.05398 12 7.00065C12 9.94732 9.61333 12.334 6.66667 12.334Z" />
      <path d="M7 3.66602H6V7.66602L9.5 9.76602L10 8.94602L7 7.16602V3.66602Z" />
    </SvgIcon>
  );
};
