import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ShoppingBasketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M5.72357 20.3989C5.31922 20.3989 4.9598 20.2823 4.64532 20.0489C4.33082 19.8156 4.11523 19.5073 3.99857 19.1239L1.12357 9.54893C1.04023 9.26331 1.08848 9.00024 1.26832 8.7597C1.44812 8.51919 1.69153 8.39893 1.99857 8.39893H7.52357L11.0236 2.92393C11.1569 2.74059 11.3111 2.60726 11.4861 2.52393C11.6611 2.44059 11.8402 2.39893 12.0236 2.39893C12.2069 2.39893 12.3861 2.44059 12.5611 2.52393C12.7361 2.60726 12.8819 2.74059 12.9986 2.92393L16.4736 8.39893H21.9986C22.3056 8.39893 22.549 8.51919 22.7288 8.7597C22.9087 9.00024 22.9569 9.26331 22.8736 9.54893L19.9986 19.1239C19.8819 19.5073 19.6663 19.8156 19.3518 20.0489C19.0373 20.2823 18.6779 20.3989 18.2736 20.3989H5.72357ZM12.0039 16.1989C12.5003 16.1989 12.9236 16.0222 13.2736 15.6686C13.6236 15.3151 13.7986 14.8901 13.7986 14.3936C13.7986 13.8972 13.6218 13.4739 13.2683 13.1239C12.9147 12.7739 12.4897 12.5989 11.9933 12.5989C11.4968 12.5989 11.0736 12.7757 10.7236 13.1292C10.3736 13.4828 10.1986 13.9078 10.1986 14.4042C10.1986 14.9007 10.3753 15.3239 10.7289 15.6739C11.0824 16.0239 11.5074 16.1989 12.0039 16.1989ZM9.64857 8.39893H14.3486L11.9986 4.72393L9.64857 8.39893Z" />
    </SvgIcon>
  );
};
