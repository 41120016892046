import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { AppRoutePath } from '../pages/AppRoutePath';

export interface IStoreLocatorPathOptions {
  list?: boolean;
  search?: string | null;
}
export const SEARCH_PARAM_KEY = 'search';

export const buildStoreLocatorRoutePath = ({ list, search }: IStoreLocatorPathOptions = {}) => {
  const query = new URLSearchParams();
  if (search) {
    query.append(SEARCH_PARAM_KEY, search);
  }
  return `${AppRoutePath.StoreLocator}${list ? '/list' : ''}?${query.toString()}`;
};

export const useGetNavigationActions = () => {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      pushTo: (url: string) => navigate(url),
      pushToHome: () => navigate(AppRoutePath.Home),
      pushToSurveys: () => navigate(AppRoutePath.Surveys),
      replaceToHome: () => navigate(AppRoutePath.Home, { replace: true }),
      pushToProfile: () => navigate(AppRoutePath.Profile),
      pushRequestLink: () => navigate(AppRoutePath.AuthWildcard),
      pushToTransactionHistory: () => navigate(AppRoutePath.TransactionHistory),
      pushToStoreLocator: (options: IStoreLocatorPathOptions = {}) => navigate(buildStoreLocatorRoutePath(options)),
      replaceToStoreLocator: (options: IStoreLocatorPathOptions = {}) =>
        navigate(buildStoreLocatorRoutePath(options), { replace: true }),
      pushToStoreDetails: (storeId: string) => navigate(generatePath(AppRoutePath.StoreDetails, { storeId })),
      pushToSelectPump: (storeId: string) => navigate(generatePath(AppRoutePath.PayAtPumpSelectPump, { storeId })),
      replaceToFuelingStatus: (transactionId: string) =>
        navigate(generatePath(AppRoutePath.PayAtPumpFuelingStatus, { transactionId }), { replace: true }),
      replaceToReceipt: (transactionId: string) =>
        navigate(generatePath(AppRoutePath.PayAtPumpReceipt, { transactionId }), { replace: true }),
      pushToPayInside: () => navigate(AppRoutePath.PayInside),
      pushToTransactionDetailsNested: (transactionId: string) =>
        navigate(generatePath(AppRoutePath.TransactionMobileDetailsNested, { transactionId })),
      pushToActivateTransactionReceiptNested: (transactionId: string) =>
        navigate(generatePath(AppRoutePath.TransactionActivateDetails, { transactionId })),
      pushToActivatePointsChangeReceiptNested: (pointsChangeId: string) =>
        navigate(generatePath(AppRoutePath.PointsChangeActivateDetailsNested, { pointsChangeId })),
      pushToWallet: () => navigate(AppRoutePath.Wallet),
      pushToCommunicationPreferences: () => navigate(AppRoutePath.CommunicationPreferences),
      pushToDeleteAccount: () => navigate(AppRoutePath.DeleteAccount),
      pushToAgeVerification: () => navigate(AppRoutePath.AgeVerification),
      pushToIdentityVerification: () => navigate(AppRoutePath.IdentityVerification),
      pushToProductInterests: () => navigate(AppRoutePath.ProductInterests),
      pushToReadyToUseOffers: () => navigate(AppRoutePath.ReadyToUseOffers),
      pushToOfferDetails: (offerId: string, params: { shouldHistoryPopOnClose?: boolean } = {}) =>
        navigate(generatePath(AppRoutePath.OfferDetails, { offerId }), {
          state: { shouldHistoryPopOnClose: params.shouldHistoryPopOnClose || false },
        }),
      pushToTotalAvailableRollbackOffers: () => navigate(AppRoutePath.TotalAvailableRollbackOffers),
      pushToPreferredFuelGrade: () => navigate(AppRoutePath.PreferredFuelGrade),
      pushToRedeemPromocode: () =>
        navigate(
          generatePath(AppRoutePath.RedeemPromocode, {
            code: '',
          }),
        ),
      navigateToApplyReferralCode: (code: string) =>
        navigate(
          generatePath(AppRoutePath.ReferAFriendReceiver, {
            code,
          }),
        ),
    }),
    [navigate],
  );
};
