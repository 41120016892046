import { useCallback } from 'react';

import { useCreatePaymentInstrumentMutation } from '@ocx/graphql';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePaymentProviderSDKBased, TokenizePaymentInstrumentParams } from '@ocx/data-payment-provider';

import { buildCreatePaymentInstrumentPayload } from './payment-instruments.utils';

type UseCreatePaymentInstrumentParams = {
  onCompleted?: () => void;
};

export const useCreatePaymentInstrument = (params: UseCreatePaymentInstrumentParams) => {
  const { onCompleted } = params;

  const paymentProvider = usePaymentProviderSDKBased();
  const [createPaymentInstrument] = useCreatePaymentInstrumentMutation({
    refetchQueries: ['getPaymentInstruments'],
    onCompleted,
  });

  return useCallback(
    async (input: TokenizePaymentInstrumentParams) => {
      const tokenizedData = await paymentProvider.tokenizePaymentInstrument(input);
      const { data } = await createPaymentInstrument({
        variables: {
          input: buildCreatePaymentInstrumentPayload(input, tokenizedData),
        },
      });

      return { paymentInstrumentId: data?.customerPaymentInstrumentCreate.uuid || null };
    },
    [paymentProvider, createPaymentInstrument],
  );
};
