import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';

import { PaymentInstrument, PaymentInstrumentOrder } from './payment-instruments.types';

export const ON_DEVICE_ACCOUNT_TYPES = [PaymentAccountType.ApplePay, PaymentAccountType.GooglePay] as const;
export const TOKENIZABLE_ACCOUNT_TYPES = [PaymentAccountType.Credit, PaymentAccountType.Prepaid] as const;

export const googlePayPaymentInstrumentPlaceholder: PaymentInstrument = {
  uuid: 'GOOGLE_PAY',
  accountType: PaymentAccountType.GooglePay,
  alias: 'GOOGLE_PAY',
  paymentType: PaymentInstrumentType.GooglePay,
  providerNickName: 'GOOGLE_PAY',
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Google Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};

export const applePayPaymentInstrumentPlaceholder: PaymentInstrument = {
  uuid: 'APPLE_PAY',
  accountType: PaymentAccountType.ApplePay,
  alias: 'APPLE_PAY',
  providerNickName: 'APPLE_PAY',
  paymentType: PaymentInstrumentType.ApplePay,
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Apple Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};
