import React, { useCallback, useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';
import { Route, Routes } from 'react-router-dom';

import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
/* eslint-disable @nx/enforce-module-boundaries */
import { useEditProfilePopup } from '@ocx-app/components/modals';
import { useHowDoesItWork } from '@ocx-app/modules/tutorial/howDoesItWork/hooks/useHowDoesItWork';
import { useLogoutController } from '@ocx-app/modules/auth/useLogoutController';
import { useShowGenericErrorSnackbar } from '@ocx-app/hooks/useShowGenericErrorSnackbar';
import { ScreenLoaderBackdrop } from '@ocx/ui';
import { ConfigGuard } from '@ocx-app/modules/configuration/ConfigGuard';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';
import { logInDev } from '@ocx/utils';
import { useMembership } from '@ocx/data-membership';

import { ProfileLayout } from './profile.layout';
import { MemberInformation } from './components/member-information.component';
import { LoyaltyDetailsList } from './components/loyalty-details-list.component';
import { AboutList } from './components/about-list.components';
import { AccountActionsList } from './components/account-actions-list.component';
import { AppVersion } from './components/app-version.component';
import { RedeemPromocodeMenuItem } from './components/redeem-promocode-menu-item.component';
import { ReferAFriendMenuItem } from './components/refer-a-friend-menu-item.component';

const ReferAFriendController = React.lazy(async () => ({
  // eslint-disable-next-line @nx/enforce-module-boundaries
  default: (await import('@ocx/feat-referrals')).ReferAFriendController,
}));

export const ProfileController = () => {
  const { config } = useConfiguration();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { membership, loading, refetch: refetchMembership } = useMembership();
  const { open: openEditProfilePopup } = useEditProfilePopup();
  const { logout } = useLogoutController({ onError: showGenericErrorSnackbar });
  const { handleHowDoesItWorkOfferClick, isHowDoesItWorkAvailable } = useHowDoesItWork();

  const onLogoutClick = useCallback(() => {
    logout().catch(logInDev);
  }, [logout]);

  useEffect(() => {
    addBreadcrumb({
      message: 'Profile: start refetch membership',
    });
    refetchMembership()
      .then((membership) => {
        addBreadcrumb({
          message: 'Profile: refetch membership success',
        });
      })
      .catch(() => {
        addBreadcrumb({
          message: 'Profile: refetch membership fail',
        });
      });
  }, [refetchMembership]);

  if (loading && !membership) {
    return (
      <ProfileLayout>
        <ScreenLoaderBackdrop open />
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <MemberInformation
        username={[membership.firstName, membership.lastName].join(' ').trim()}
        onClick={openEditProfilePopup}
        isLoading={loading}
      />
      {config.loyaltyProgram.isPromocodesEnabled && <RedeemPromocodeMenuItem />}
      <LoyaltyDetailsList
        isWalletEnabled={config.wallet.enabled}
        isCommunicationPreferencesEnabled={config.communicationPreference.enabled}
        isProductInterestsEnabled={config.productInterests.enabled}
        isPreferredFuelGradeEnabled={config.totalAvailableRollback.enabled}
      />
      {config.loyaltyProgram.referralProgramConfig?.referralProgramEnabled && <ReferAFriendMenuItem />}
      <AboutList
        isHowDoesItWorkEnabled={isHowDoesItWorkAvailable}
        onHowDoesItWorkClick={handleHowDoesItWorkOfferClick}
      />
      <AccountActionsList isDeleteAccountEnabled={config.deleteAccount.enabled} onLogoutClick={onLogoutClick} />
      <AppVersion version={config.release} />
      <Routes>
        <Route
          path={AppRoutePath.ReferAFriendProfileNested}
          element={
            <ConfigGuard _isRedirectRequired={(c) => !c.loyaltyProgram.referralProgramConfig?.referralProgramEnabled}>
              <ReferAFriendController />
            </ConfigGuard>
          }
        />
      </Routes>
    </ProfileLayout>
  );
};
