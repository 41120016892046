import React from 'react';
import { ButtonBase } from '@mui/material';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { CarWashIcon, ArrowRightIcon } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../../../../../theme/constants';
import { ListSelector } from '../../../../../../hooks/useListController';
import { IStoreCarWashOption } from '../../../../../../modules/stores/types';
import { useCarWashSelectorPopup, CarWashSelectorPopup } from '../../../../../../components/modals';

export const messages = defineMessages({
  addCarWashButtonText: {
    id: 'payAtPump:addCarWashButtonText',
    defaultMessage: 'Add car wash',
    description: 'Pay At Pump section: Add Car Wash button text',
  },
});

export interface ICarWashSelectorProps {
  carWashListController: ListSelector<IStoreCarWashOption>;
}

export const CarWashSelector = ({ carWashListController }: ICarWashSelectorProps) => {
  const carWashSelectorPopup = useCarWashSelectorPopup();
  const { selectedListItem } = carWashListController;

  return (
    <>
      <ButtonBase
        sx={{
          backgroundColor: selectedListItem ? 'primary.main' : 'action.selected',
          color: selectedListItem ? 'primary.contrastText' : 'text.primary',
          p: SizingMultiplier.md,
          minHeight: 64,
          borderRadius: 2,
        }}
        onClick={() => carWashSelectorPopup.show()}>
        <CarWashIcon sx={{ mr: SizingMultiplier.sm }} />
        <Typography component="span" textAlign="left">
          {selectedListItem?.name || <FormattedMessage {...messages.addCarWashButtonText} />}
        </Typography>
        {selectedListItem && <ArrowRightIcon sx={{ ml: SizingMultiplier.sm }} />}
      </ButtonBase>
      <CarWashSelectorPopup id={carWashSelectorPopup.id} carWashListController={carWashListController} />
    </>
  );
};
