import React from 'react';
import { SvgIconProps } from '@mui/material';
import { TransactionHistoryItemType } from '@ocx-app/modules/transaction-history/transaction-history.types';
import { DollarIcon, ShoppingBasketIcon, ShoppingModeIcon } from '@ocx/ui-icons';
import { TransactionIconBox } from '@ocx/ui-receipts';

const transactionIconMap: { [key in TransactionHistoryItemType]: React.FC<SvgIconProps> } = {
  [TransactionHistoryItemType.Transaction]: ShoppingBasketIcon,
  [TransactionHistoryItemType.PointsChange]: ShoppingModeIcon,
  [TransactionHistoryItemType.LoyaltyEvent]: ShoppingModeIcon,
};

type Props = {
  transactionType: TransactionHistoryItemType;
};

export const TransactionIcon: React.FC<Props> = ({ transactionType }) => {
  const Icon = transactionIconMap[transactionType] || DollarIcon;

  return (
    <TransactionIconBox>
      <Icon sx={{ color: (theme) => theme.palette.Receipts.iconColor }} />
    </TransactionIconBox>
  );
};
