type CryptoParams = {
  importKey: Parameters<SubtleCrypto['importKey']>[2];
  encrypt: Parameters<SubtleCrypto['encrypt']>[0];
};

/**
 * Converts a string-based representation of binary data to an ArrayBuffer.
 */
export const stringToArrayBuffer = (value: string): ArrayBuffer => {
  const result = new ArrayBuffer(value.length);
  const view = new Uint8Array(result);

  for (let i = 0; i < value.length; i += 1) {
    view[i] = value.charCodeAt(i);
  }

  return result;
};

/**
 * Converts a base64-encoded string to an ArrayBuffer.
 */
export const base64ToArrayBuffer = (base64: string): ArrayBuffer => stringToArrayBuffer(atob(base64));

/**
 * Converts an ArrayBuffer containing binary data to a string-based representation.
 */
export const arrayBufferToString = (value: ArrayBuffer): string => {
  let result = '';
  const view = new Uint8Array(value);

  for (let i = 0; i < view.byteLength; i += 1) {
    result += String.fromCharCode(view[i]);
  }

  return result;
};

/**
 * Converts an ArrayBuffer to a base64-encoded string.
 */
export const arrayBufferToBase64 = (value: ArrayBuffer): string => btoa(arrayBufferToString(value));

/**
 * Maps Fiserv-supplied algorithms to Web Crypto API parameters.
 */
const KNOWN_ALGORITHMS: Record<string, CryptoParams> = {
  'RSA/None/OAEPWithSHA512AndMGF1Padding': {
    importKey: {
      name: 'RSA-OAEP',
      hash: {
        name: 'SHA-512',
      },
    },
    encrypt: {
      name: 'RSA-OAEP',
    },
  },
};

declare const ENCRYPTED_STRING: unique symbol;

/**
 * A basic implementation of a 'phantom' type that can be used to enforce compile-time checking that data has been
 * encrypted before transmission. However, at runtime, these are just plain strings.
 */
export type EncryptedString = string & { [ENCRYPTED_STRING]: true };

/**
 * Returns a function that may be used to encrypt plaintext strings using the specified encryption algorithm and public
 * key.
 *
 * `algorithm` must correspond to an entry in `KNOWN_ALGORITHMS`. If not, this function will throw an error.
 * `base64EncodedPublicKeyBody` must be a base-64 encoded string, with `BEGIN/END PUBLIC KEY` headers and footers
 * removed.
 */
export const createEncryptFunction = (algorithm: string, base64EncodedPublicKeyBody: string) => {
  if (!KNOWN_ALGORITHMS[algorithm]) {
    throw new Error(`Unknown algorithm '${algorithm}'`);
  }

  const { importKey, encrypt } = KNOWN_ALGORITHMS[algorithm];

  // The async. import of a public key only needs to be done once per key, so it can be performed outside of the
  // function and the result re-used where required.
  const importPublicKey = crypto.subtle.importKey(
    'spki',
    base64ToArrayBuffer(base64EncodedPublicKeyBody),
    importKey,
    false,
    ['encrypt'],
  );

  return (plaintext: string): Promise<EncryptedString> =>
    importPublicKey
      .then((publicKey) => crypto.subtle.encrypt(encrypt, publicKey, stringToArrayBuffer(plaintext)))
      .then(arrayBufferToBase64 as (buffer: ArrayBuffer) => EncryptedString);
};

export type EncryptFunc = ReturnType<typeof createEncryptFunction>;
