import { Paper, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { Typography, SizingMultiplier } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { homeMessages } from '../../home.messages';
import { LoyaltyCurrencyBalance } from './loyalty-currency-balance.component';

export type UserWelcomeSectionProps = {
  points?: number;
  name?: string;
  loading: boolean;
  pointsStrategy: PointsStrategy;
  handlePointsClick?(): void;
};

export const UserWelcomeSection = (props: UserWelcomeSectionProps) => {
  const { points, name, loading, pointsStrategy, handlePointsClick } = props;
  const intl = useIntl();

  return (
    <Stack
      component={Paper}
      justifyContent="space-between"
      gap={SizingMultiplier.md}
      minHeight={64}
      direction="row"
      elevation={0}>
      <Typography alignSelf="center" pl={SizingMultiplier.md}>
        {name
          ? intl.formatMessage(homeMessages['home:pointsBox:customerGreetingWithName'], { customerName: name })
          : intl.formatMessage(homeMessages['home:pointsBox:customerGreeting'])}
      </Typography>

      {pointsStrategy !== PointsStrategy.NotUsed && points !== null && points !== undefined && (
        <LoyaltyCurrencyBalance
          points={points}
          pointsStrategy={pointsStrategy}
          loading={loading}
          onClick={handlePointsClick}
        />
      )}
    </Stack>
  );
};
