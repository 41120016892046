import { useMemo } from 'react';

import { useReferralProgramSenderConfigQuery } from '@ocx/graphql';

import { buildSmsContent } from '../refer-a-friend.utils';

export type UseSenderConfigParams = Parameters<typeof useReferralProgramSenderConfigQuery>[0];

export const useSenderConfig = (params: UseSenderConfigParams) => {
  const { data, loading } = useReferralProgramSenderConfigQuery({
    fetchPolicy: 'no-cache',
    ...params,
  });

  const senderConfiguration = data?.appConfig.loyaltyProgram.referralProgramConfiguration?.senderConfiguration;
  const instructions = senderConfiguration?.instructions || '';
  const membership = data?.currentCustomer?.membership;
  const referralCode = membership?.referralCode || '';
  const referralUrl = membership?.referralUrl || '';

  const textToShare = useMemo(() => {
    const smsTemplate = senderConfiguration?.smsTemplate;
    if (!smsTemplate || !referralCode || !referralUrl) {
      // TODO: consider adding extra gates, instead of relying on a feature flag for route
      // This is handled using onError. This properties are not nullable
      return '';
    }
    return buildSmsContent({ smsTemplate, referralCode, referralUrl });
  }, [referralCode, referralUrl, senderConfiguration?.smsTemplate]);

  return useMemo(
    () => ({
      config: {
        instructions,
        textToShare,
        referralUrl,
      },
      loading,
    }),
    [instructions, loading, referralUrl, textToShare],
  );
};
