import { useMemo } from 'react';
import { useIntl } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useIsGooglePayAvailable, useIsApplePayAvailable } from '@ocx/data-payment-provider';
import { PaymentInstrumentType } from '@ocx/graphql';

import {
  applePayPaymentInstrumentPlaceholder,
  googlePayPaymentInstrumentPlaceholder,
} from './payment-instruments.constants';
import { PaymentInstrument } from './payment-instruments.types';
import { digitalWalletInstrumentsTitle } from './payment-instruments.messages';

export type UseOnDevicePaymentInstrumentsParams = {
  skip?: boolean;
  isApplePayDefault?: boolean;
  isGooglePayDefault?: boolean;
};

/**
 * Return device (OS) digital wallets if available
 * iOS: Apple Pay, Google Pay
 * Android: Google Pay
 */
export const useOnDevicePaymentInstruments = (params: UseOnDevicePaymentInstrumentsParams = {}) => {
  // TODO: read defaults by platform?
  const { skip = false, isApplePayDefault = false, isGooglePayDefault = false } = params;
  const intl = useIntl();

  const googlePay = useIsGooglePayAvailable();
  const applePay = useIsApplePayAvailable();

  return useMemo(() => {
    if (skip) {
      return {
        loading: false,
        paymentInstruments: [],
      };
    }

    const paymentInstruments: PaymentInstrument[] = [];
    // TODO: seems this adds an order for Apple Pay to be first on supported platform
    // It's a UX side effect
    if (!applePay.loading && applePay.available) {
      paymentInstruments.push({
        ...applePayPaymentInstrumentPlaceholder,
        isDefault: isApplePayDefault,
        displayName: intl.formatMessage(digitalWalletInstrumentsTitle[PaymentInstrumentType.ApplePay]),
      });
    }
    if (!googlePay.loading && googlePay.available) {
      paymentInstruments.push({
        ...googlePayPaymentInstrumentPlaceholder,
        isDefault: isGooglePayDefault,
        displayName: intl.formatMessage(digitalWalletInstrumentsTitle[PaymentInstrumentType.GooglePay]),
      });
    }
    return {
      loading: applePay.loading || googlePay.loading,
      paymentInstruments,
    };
  }, [
    skip,
    applePay.loading,
    applePay.available,
    googlePay.loading,
    googlePay.available,
    isApplePayDefault,
    intl,
    isGooglePayDefault,
  ]);
};
