import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Stack } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoyaltyCurrencyIcon, LoyaltyCurrencyChange } from '@ocx/feat-loyalty-currency';
import { SizingMultiplier, Typography } from '@ocx/ui';

import { ReceiptPointChangeType } from '../../../../receipts.types';
import { TransactionIconBox } from '../../../transaction-icon-box';
import { TwoColsTypography } from '../../../two-cols-typography';
import { messages } from '../../receipt-loyalty-card.messages';

export type PointChangeItemProps = {
  pointsDiff: number;
  appliedOffersNumber: number;
  type: ReceiptPointChangeType;
};

const RECEIPT_LINE_ITEMS: Record<ReceiptPointChangeType, { text: MessageDescriptor; icon?: React.ReactNode }> = {
  spent: {
    text: messages['receipt:loyalty-card:points-spent'],
    icon: <LoyaltyCurrencyIcon variant="multiple" />,
  },
  earned: {
    text: messages['receipt:loyalty-card:points-earned'],
    icon: <LoyaltyCurrencyIcon variant="multiple" />,
  },
  refund: {
    text: messages['receipt:loyalty-card:points-refund'],
    icon: <FileDownloadOutlinedIcon width={24} />,
  },
};

export const PointChangeItem = (props: PointChangeItemProps) => {
  const { type, pointsDiff, appliedOffersNumber } = props;

  return (
    <Stack direction="row" spacing={SizingMultiplier.sm} alignItems="center">
      <TransactionIconBox {...(type !== 'refund' && { backgroundColor: 'Receipts.currencyBackground' })}>
        {RECEIPT_LINE_ITEMS[type].icon}
      </TransactionIconBox>
      <Box flex={1}>
        <TwoColsTypography
          title={<FormattedMessage {...RECEIPT_LINE_ITEMS[type].text} />}
          value={
            <LoyaltyCurrencyChange
              points={pointsDiff}
              template="value-icon"
              typographyProps={{ variant: 'subtitle1' }}
            />
          }
          variant="subtitle1"
        />

        {appliedOffersNumber > 0 && (
          <Typography variant="body2" color="text.disabled" mt={SizingMultiplier.xs}>
            <FormattedMessage {...messages['receipt:loyalty-card:offers-applied']} values={{ appliedOffersNumber }} />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
