import { useCallback, useMemo, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import { Membership, useMembership } from '@ocx/data-membership';
import { isSurveyWasTriggeredOnAppStart } from '../../../modules/survey/survey.reactive-variables';
import { Offer } from '../../../modules/offers/offer.types';
import { useDisplayCategories } from '../../../modules/loyalty/useDisplayCategories';
import { useRegisterPushNotifications } from '../../../modules/notifications/useRegisterPushNotifications';
import { useRefresh } from './useRefresh';
import { useConfigurationContext } from '../../../modules/configuration/useConfigurationContext';
import { isVoucherOffer } from '../../../modules/offers/offer.type-guards';
import { useTriggerSurveyOnMembershipCompleted } from './useTriggerSurveyOnMembershipCompleted';
import { useSyncPushNotificationPermissionState } from '../../../modules/communication-preference/useSyncPushNotificationPermissionState';
import { useAppRatePendingState } from '../../../modules/app-rate/useAppRatePendingState';
import { useAppRateDialog } from '../../../modules/app-rate/useAppRateDialog';
import { useShowAppRatingOnDiscountOfferFulfilled } from '../../../modules/app-rate/useShowAppRatingOnDiscountOfferFulfilled';
import { useOffers } from '../../../modules/offers/use-offers.hook';

import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { useTotalAvailableRollbackMoneyAmount } from '../../../modules/totalAvailableRollback/use-total-available-rollback-money-amount.hook';

export interface IUseHomeControllerReturns {
  isOffersLoading: boolean;
  isMembershipLoading: boolean;
  membership: Membership;
  offers: Offer[];
  rewardsQuantity: number;
  totalAvailableRollbackMoneyAmount: number;
  isTotalAvailableRollbackMoneyAmountLoading: boolean;
  refreshAll: () => Promise<void>;
}

export const useHomeController = (shouldTriggerSurvey: boolean): IUseHomeControllerReturns => {
  const { config } = useConfiguration();
  // region - Membership -
  const { membership, refetch: refetchMembership, loading: isMembershipLoading } = useMembership();
  useEffect(() => {
    refetchMembership();
  }, [refetchMembership]);
  // endregion

  // region - App Rate -
  const { open: openAppRateDialog } = useAppRateDialog();
  const [getIsAppRatePending] = useAppRatePendingState();
  useShowAppRatingOnDiscountOfferFulfilled();
  useEffect(() => {
    let skip = false;
    getIsAppRatePending().then((isPending) => {
      if (isPending && !skip) {
        openAppRateDialog();
      }
    });
    return () => {
      skip = true;
    };
  }, [getIsAppRatePending, openAppRateDialog]);
  // endregion

  // region - Push Notifications
  const { registerPushNotifications } = useRegisterPushNotifications();
  const syncPushNotificationPermissionState = useSyncPushNotificationPermissionState();
  const updateCommunicationPreference = useCallback(
    async (customerId: string, oldIsOptedIn: boolean) => {
      if (!Capacitor.isPluginAvailable('PushNotifications')) {
        return;
      }

      const { receive: newState } = await PushNotifications.checkPermissions();
      syncPushNotificationPermissionState({
        customerId,
        oldIsOptedIn,
        newState,
      });
    },
    [syncPushNotificationPermissionState],
  );

  useEffect(() => {
    updateCommunicationPreference(membership.customerId, membership.communicationPreference.pushOptIn);
  }, [membership, updateCommunicationPreference]);

  useEffect(() => {
    registerPushNotifications();
  }, [registerPushNotifications]);
  // endregion

  // region - Surveys -
  const { fetchAndOpenSurveyModal, triggerSurveyOnMembershipCompleted } = useTriggerSurveyOnMembershipCompleted();
  useEffect(() => {
    if (shouldTriggerSurvey) {
      triggerSurveyOnMembershipCompleted();
    } else {
      isSurveyWasTriggeredOnAppStart(true);
    }
  }, [triggerSurveyOnMembershipCompleted, shouldTriggerSurvey]);
  // endregion

  // region - Offers -
  const { offers, refetch: refetchOffers, loading: isOffersLoading } = useOffers();
  const rewardsQuantity = useMemo(
    () => offers.filter(isVoucherOffer).reduce((acc, offer) => acc + offer.voucherCount, 0),
    [offers],
  );
  // endregion

  // region - Total Available Rollback Money Amount -
  const {
    moneyAmount: totalAvailableRollbackMoneyAmount,
    loading: isTotalAvailableRollbackMoneyAmountLoading,
    refetch: refetchTotalAvailableFuelRollbackMoneyAmount,
  } = useTotalAvailableRollbackMoneyAmount({ skip: !config.totalAvailableRollback.enabled });
  // endregion

  const { refetch: refetchConfig } = useConfigurationContext();
  const { refetch: refetchCategories } = useDisplayCategories();
  const [refreshAll] = useRefresh(
    useMemo(
      () => [
        refetchMembership,
        refetchOffers,
        refetchCategories,
        refetchConfig,
        fetchAndOpenSurveyModal,
        refetchTotalAvailableFuelRollbackMoneyAmount,
      ],
      [
        fetchAndOpenSurveyModal,
        refetchCategories,
        refetchMembership,
        refetchOffers,
        refetchConfig,
        refetchTotalAvailableFuelRollbackMoneyAmount,
      ],
    ),
  );

  return {
    isMembershipLoading,
    membership,
    offers,
    isOffersLoading,
    rewardsQuantity,
    totalAvailableRollbackMoneyAmount,
    isTotalAvailableRollbackMoneyAmountLoading,
    refreshAll,
  };
};
