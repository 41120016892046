import React, { useMemo } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { Config } from 'final-form';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, LoadingButton, Markdown } from '@ocx/ui';

import { InfoIcon, HeartIcon } from '@ocx/ui-icons';
import { IProfileFormFields, ProfileFormFieldsInitialValues } from './types';
import { SimpleDatePickerController } from '../../components/SimpleDatePicker/SimpleDatePickerController';
import { TextField } from '../../components/TextField';
import { FormIconHelperText } from '../../components/Fields/FormIconHelperText/FormIconHelperText';
import { SwitchField } from '../../components/Fields/SwitchField/SwitchField';
import { SizingMultiplier } from '../../theme/constants';
import { phoneNumberFormatNationalForUs } from '../../lib/phone-number';
import { formLabelMessages } from '../../modules/localization/form-label.messages';
import { messages } from './messages';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { CheckboxField } from '../../components/Fields/CheckboxField/CheckboxField';

export interface IEditProfileProps {
  loading: boolean;
  isProfileCompleted: boolean;
  initialValues: ProfileFormFieldsInitialValues;
  updateCustomer: Config<IProfileFormFields>['onSubmit'];
  onCloseClick(): void;
  validationSchema: Config<IProfileFormFields>['validate'];
  maxPossibleYOB: number;
  minPossibleYOB: number;
  termsConditionsLink: string;
  privacyPolicyLink: string;
  isExplicitTermsAcceptanceEnabled: boolean;
  isExplicitSmsPromotionAcceptanceEnabled: boolean;
}

export const EditProfile: React.FC<IEditProfileProps> = ({
  loading,
  initialValues,
  updateCustomer,
  isProfileCompleted,
  onCloseClick,
  validationSchema,
  minPossibleYOB,
  maxPossibleYOB,
  termsConditionsLink,
  privacyPolicyLink,
  isExplicitTermsAcceptanceEnabled,
  isExplicitSmsPromotionAcceptanceEnabled,
}) => {
  const intl = useIntl();
  const isExplicitSmsPromotionAcceptanceVisible = !isProfileCompleted && isExplicitSmsPromotionAcceptanceEnabled;

  const [disclaimerMarkdown, explicitMarketingSmsAcceptanceMarkdown] = useMemo(
    () => [
      intl.formatMessage(messages.disclaimerMarkdown, {
        termsConditionsLink,
      }),
      intl.formatMessage(messages.explicitMarketingSmsAcceptanceToggleLabel, {
        termsConditionsLink,
        privacyPolicyLink,
      }),
    ],
    [intl, privacyPolicyLink, termsConditionsLink],
  );

  const birthdateFieldDescription = useMemo(() => intl.formatMessage(messages.birthdateFieldDescription), [intl]);

  // TODO: refactor, remove Container
  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
      }}>
      <Typography variant="h5">
        <FormattedMessage {...(isProfileCompleted ? messages.completedProfile : messages.notCompletedProfile)} />
      </Typography>
      <Box alignItems="center" marginTop={3}>
        <Form<IProfileFormFields>
          validate={validationSchema}
          onSubmit={updateCustomer}
          // @ts-ignore initialValues
          initialValues={initialValues}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  variant="outlined"
                  name="firstName"
                  fullWidth
                  label={intl.formatMessage(formLabelMessages['form-label:first-name'])}
                  disabled={loading}
                />
                <TextField
                  variant="outlined"
                  name="lastName"
                  fullWidth
                  label={intl.formatMessage(formLabelMessages['form-label:last-name'])}
                  disabled={loading}
                />
                <Stack>
                  <TextField
                    variant="outlined"
                    name="email"
                    fullWidth
                    type="email"
                    label={intl.formatMessage(formLabelMessages['form-label:email'])}
                    disabled={loading}
                  />
                  <FormIconHelperText icon={InfoIcon} text={intl.formatMessage(messages.emailFieldDescription)} />
                </Stack>
                <TextField
                  variant="outlined"
                  name="phoneNumber"
                  fullWidth
                  disabled
                  label={intl.formatMessage(formLabelMessages['form-label:phone-number'])}
                  fieldProps={{ format: phoneNumberFormatNationalForUs }}
                />
                {isExplicitSmsPromotionAcceptanceVisible && (
                  <Box p={1} borderRadius={1} bgcolor="background.box">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography color="textSecondary" variant="caption">
                        <Markdown>{explicitMarketingSmsAcceptanceMarkdown}</Markdown>
                      </Typography>
                      <SwitchField
                        disabled={loading}
                        name="isExplicitMarketingSmsAcceptanceOptIn"
                        formControlLabelProps={{ sx: { marginRight: 0 } }}
                      />
                    </Box>
                  </Box>
                )}
                <Stack>
                  <Typography variant="subtitle1" color="textSecondary" paragraph>
                    <FormattedMessage {...messages.birthdateFieldLabel} />
                  </Typography>
                  <SimpleDatePickerController
                    minYear={minPossibleYOB}
                    maxYear={maxPossibleYOB}
                    fieldNamePrefix="birthdate"
                    disabled={loading}
                  />
                  {birthdateFieldDescription !== 'none' && (
                    <FormIconHelperText icon={HeartIcon} text={birthdateFieldDescription} />
                  )}
                </Stack>
                {disclaimerMarkdown !== 'none' && (
                  <Box p={SizingMultiplier.md} borderRadius={SizingMultiplier.md} bgcolor="background.box">
                    <Markdown>{disclaimerMarkdown}</Markdown>
                  </Box>
                )}
                {isExplicitTermsAcceptanceEnabled && (
                  <CheckboxField
                    name="termsAccepted"
                    label={
                      <Markdown>
                        {intl.formatMessage(messages.termsAcceptanceCheckboxLabel, {
                          termsConditionsLink,
                          privacyPolicyLink,
                        })}
                      </Markdown>
                    }
                    disabled={loading}
                    formControlLabelProps={{
                      sx: { '& .MuiCheckbox-root': { alignSelf: 'flex-start' } },
                    }}
                  />
                )}
                <LoadingButton
                  loading={loading}
                  disabled={props.hasValidationErrors || props.submitting || loading || !props.dirty}
                  type="submit"
                  fullWidth>
                  <FormattedMessage {...buttonTextMessages['button-text:submit']} />
                </LoadingButton>
                <Button variant="text" onClick={onCloseClick}>
                  <FormattedMessage {...buttonTextMessages['button-text:close']} />
                </Button>
              </Stack>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};
