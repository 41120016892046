import { v4 as uuid } from 'uuid';

import { FISERV_TOKEN_TYPE_CLAIM_CHECK_NONCE } from './fiserv.constants';
import {
  FiservTokenizePaymentInstrumentRequestBody,
  FiservTokenizePaymentInstrumentCreditParams,
  FiservTokenizePaymentInstrumentPrepaidParams,
  FiservTokenizePaymentInstrumentReturns,
} from './fiserv.types';
import { encryptCreditPaymentInstrumentPayload, encryptPrepaidPaymentInstrumentPayload } from './fiserv.utils';
import { createEncryptFunction } from './fiserv-encryption.utils';

export class Fiserv {
  /**
   * Submits an HTTP request to the `/v1/account-tokens` endpoint of Fiserv's API, using the specified base URL, request
   * body, API Key and oAuth Token.
   *
   */
  static async tokenizeCreditPaymentInstrument(
    params: FiservTokenizePaymentInstrumentCreditParams,
  ): Promise<FiservTokenizePaymentInstrumentReturns> {
    const { options, encryption, payload } = params;
    const encrypt = createEncryptFunction(encryption.algorithm, encryption.publicKey);
    const encryptedPayload = await encryptCreditPaymentInstrumentPayload(encrypt, payload);

    const body: FiservTokenizePaymentInstrumentRequestBody = {
      fdCustomerId: options.fdCustomerId,
      referenceToken: { tokenType: FISERV_TOKEN_TYPE_CLAIM_CHECK_NONCE },
      account: {
        type: 'CREDIT',
        credit: encryptedPayload,
      },
    };

    const res = await fetch(this.getRequestUrl(options.baseUrl), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.getRequestHeaders({ apiKey: options.apiKey, oAuthToken: options.oAuthToken }),
    });
    const json = await res.json();
    return { token: JSON.stringify(json.token) };
  }

  static async tokenizePrepaidPaymentInstrument(
    params: FiservTokenizePaymentInstrumentPrepaidParams,
  ): Promise<FiservTokenizePaymentInstrumentReturns> {
    const { options, encryption, payload } = params;
    const encrypt = createEncryptFunction(encryption.algorithm, encryption.publicKey);
    const encryptedPayload = await encryptPrepaidPaymentInstrumentPayload(encrypt, payload);

    const body: FiservTokenizePaymentInstrumentRequestBody = {
      fdCustomerId: options.fdCustomerId,
      referenceToken: { tokenType: FISERV_TOKEN_TYPE_CLAIM_CHECK_NONCE },
      account: {
        type: 'PREPAID',
        prepaid: encryptedPayload,
      },
    };

    const res = await fetch(this.getRequestUrl(options.baseUrl), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.getRequestHeaders({ apiKey: options.apiKey, oAuthToken: options.oAuthToken }),
    });
    const json = await res.json();
    return { token: JSON.stringify(json.token) };
  }

  private static getRequestUrl = (baseUrl: string) => `${baseUrl}/v1/account-tokens`;

  private static getRequestHeaders = (params: { apiKey: string; oAuthToken: string }) => ({
    'Api-Key': params.apiKey,
    Authorization: `Bearer ${params.oAuthToken}`,
    'Client-Request-Id': uuid(),
    'Content-Type': 'application/json',
    Timestamp: Date.now().toString(),
  });
}
