import { WorldpayReportGroup, Worldpay } from '@external/payments-worldpay';

import { PaymentAccountType } from '@ocx/graphql';
import { ApplePayPaymentToken } from '@ocx/cap-apple-pay';

import {
  TokenizePaymentInstrumentParams,
  TokenizePaymentInstrumentReturns,
  PaymentProviderSDKBased,
  PaymentProviderSDKBasedInterface,
} from '../payment-provider-sdk-based';
import {
  WorldpayPaymentProviderConstructorParams,
  WorldpayTokenizationExternalInfo,
} from './worldpay-payment-provider.types';
import { PaymentItem } from '../payment-provider.types';
import { getPaymentInstrumentTypeByCardType } from '../payment-provider.utils';

export class WorldpayPaymentProvider extends PaymentProviderSDKBased implements PaymentProviderSDKBasedInterface {
  private reportGroup: WorldpayReportGroup;
  constructor(params: WorldpayPaymentProviderConstructorParams) {
    super({
      applePay: params.applePay,
      googlePay: params.googlePay,
    });
    this.reportGroup = params.reportGroup;
    Worldpay.setOptions({
      paypageId: params.payPageId,
      sdkApiUrl: params.sdkApiUrl,
      sdkUrl: params.sdkUrl,
    });
  }

  override async tokenizePaymentInstrument(
    params: TokenizePaymentInstrumentParams,
  ): Promise<TokenizePaymentInstrumentReturns> {
    if (params.accountType === PaymentAccountType.Prepaid) {
      throw new Error('[Worldpay] Account type PREPAID is not supported');
    }
    const response = await Worldpay.tokenizePaymentInstrument({
      cardNumber: params.cardNumber,
      cvv: params.cvv,
      reportGroup: this.reportGroup,
    });
    const externalRequestData: WorldpayTokenizationExternalInfo = {
      id: response.id,
      responseTime: response.responseTime,
      orderId: response.orderId,
      type: response.type,
      vantivTxnId: response.vantivTxnId,
    };
    return {
      accountType: params.accountType,
      nonce: response.paypageRegistrationId,
      paymentInstrumentType: getPaymentInstrumentTypeByCardType(params.cardType),
      oAuthToken: null, // Not needed, Fiserv field only
      externalRequestData,
    };
  }

  /**
   * Based on eProtect integration guide - "low value token" is "token" field in GooglePay PaymentDate
   * Page 64, section 2.3.5, "Using the Worldpay Mobile API for Google Pay"
   * Doc: https://developer.worldpay.com/assets/pdf/us-ecom-pdf/Worldpay_eProtect_Integration_Guide_V4.16.pdf
   */
  override async requestGooglePayPayment(params: { paymentItems: PaymentItem[] }) {
    const { token, ...rest } = await super.requestGooglePayPayment(params);
    return {
      ...rest,
      token,
      nonce: token,
    };
  }

  /**
   * eProtect supports tokenization for ApplePay payments
   * Doc: https://developer.worldpay.com/assets/pdf/us-ecom-pdf/Worldpay_eProtect_Integration_Guide_V4.16.pdf
   */
  override async requestApplePayPayment(params: { paymentItems: PaymentItem[] }) {
    const { token, ...rest } = await super.requestApplePayPayment(params);
    const tokenized = await this.tokenizeApplePay(JSON.parse(token));
    return {
      ...rest,
      token,
      nonce: tokenized ? tokenized.nonce : null,
    };
  }

  private async tokenizeApplePay(paymentData: ApplePayPaymentToken['paymentData']): Promise<{ nonce: string }> {
    const { paypageRegistrationId } = await Worldpay.tokenizeApplePay(paymentData, {
      reportGroup: this.reportGroup,
    });
    return {
      nonce: paypageRegistrationId,
    };
  }
}
