export const REFERRAL_CODE_LIQUID = '{{ referral_code }}';
export const REFERRAL_URL_LIQUID = '{{ referral_link }}';

// TODO: it's probably a good idea to move this to an environment variable
export const RECEIVER_STATUS_MODAL_WITHIN_DAYS = 7;

export const RECEIVER_STATUS_SUCCESS_MODAL_CLOSED_AT = 'referral-receiver-status-success-modal-closed-at';
export const RECEIVER_STATUS_ERROR_MODAL_CLOSED_AT = 'referral-receiver-status-error-modal-closed-at';

export const RECEIVER_EXISTING_MEMBER_ERROR_MODAL_WITHIN_MINUTES = 2;
export const RECEIVER_TRIED_TO_USE_CODE_AT = 'referral-receiver-tried-to-use-code-at';
