import React from 'react';
import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { LoadingButton } from '@ocx/ui';
import { LoyaltyCurrencyChange } from '@ocx/feat-loyalty-currency';
import { SizingMultiplier } from '../../../../theme/constants';
import { messages } from '../../messages';

const PointsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  position: 'absolute',
  right: theme.spacing(SizingMultiplier.md),

  '& img': {
    opacity: disabled ? 0.5 : 1,
  },
}));

export interface IBuyEntriesButtonProps {
  entries: number;
  price: number;
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
}

export const BuyEntriesButton: React.FC<IBuyEntriesButtonProps> = (props) => {
  const { entries, price, onClick, loading, disabled = false } = props;
  return (
    <LoadingButton fullWidth disabled={disabled} onClick={onClick} loading={loading}>
      <FormattedMessage {...messages.buyEntriesButtonText} values={{ entries }} />
      {!loading && (
        <PointsWrapper disabled={disabled}>
          <LoyaltyCurrencyChange points={-price} template="value-icon" />
        </PointsWrapper>
      )}
    </LoadingButton>
  );
};
