import React, { useCallback } from 'react';

import { useMembershipContext } from '@ocx/data-membership';
import { ISurveyContextValue } from './survey.types';

import { useSurveyPopup } from '../../components/modals/SurveyPopup/survey-popup.hook';
import { useFetchEligibleSurvey } from './hooks/fetch-eligible-survey.hook';

const defaultContextValue: ISurveyContextValue = {
  fetchAndOpenSurveyModal: async () => {},
};

export const SurveysContext = React.createContext<ISurveyContextValue>(defaultContextValue);

export const SurveysContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { membership } = useMembershipContext();
  const { open: openSurvey } = useSurveyPopup();

  const { fetch, isLoading } = useFetchEligibleSurvey();

  const fetchAndOpenSurveyModal = useCallback(
    async (guestSurveyId?: string): Promise<void> => {
      if (isLoading) {
        return;
      }
      const membershipId = membership?.id || '';
      const survey = await fetch(membershipId, guestSurveyId);

      if (survey) {
        openSurvey(survey);
      }
    },
    [fetch, isLoading, membership?.id, openSurvey],
  );

  const value: ISurveyContextValue = React.useMemo(
    () => ({
      fetchAndOpenSurveyModal,
    }),
    [fetchAndOpenSurveyModal],
  );

  return <SurveysContext.Provider value={value}>{children}</SurveysContext.Provider>;
};
