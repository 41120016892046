import React, { useCallback } from 'react';
import { Stack, styled, ButtonBase } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { PlusIcon, MinusIcon } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../../../theme/constants';
import { messages } from '../../messages';

const SquareButton = styled(ButtonBase)(({ theme, disabled }) => ({
  width: 56,
  height: 56,
  color: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  borderColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
  border: '2px solid',
  borderRadius: 8,
}));

export type EntriesCounterProps = {
  /** Entries count */
  value: number;
  /**
   * Called when + or - button is clicked
   * @param value is a new number of entries
   */
  onChange(value: number): void;
  /** Hides or shows - button */
  minusButtonEnabled: boolean;
  /** Hides or shows + button */
  plusButtonEnabled: boolean;
};

export const EntriesCounter = (props: EntriesCounterProps) => {
  const { value, onChange, minusButtonEnabled, plusButtonEnabled } = props;
  const handleDecrement = useCallback(() => onChange(value - 1), [value, onChange]);
  const handleIncrement = useCallback(() => onChange(value + 1), [value, onChange]);

  return (
    <Stack direction="row" alignItems="center" bgcolor="background.box" sx={{ borderRadius: 2 }}>
      <SquareButton onClick={handleDecrement} disabled={!minusButtonEnabled}>
        <MinusIcon sx={{ width: 14, height: 4 }} />
      </SquareButton>
      <Stack px={SizingMultiplier.md} minWidth={136} alignItems="center">
        <Typography variant="body2" component="span" color="textSecondary">
          <FormattedMessage {...messages.entriesCounterTitle} />
        </Typography>
        <Typography variant="h6" component="span">
          <FormattedMessage {...messages.entriesAmount} values={{ entries: value }} />
        </Typography>
      </Stack>
      <SquareButton onClick={handleIncrement} disabled={!plusButtonEnabled}>
        <PlusIcon />
      </SquareButton>
    </Stack>
  );
};
