import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useShowGenericErrorSnackbar } from '@ocx-app/hooks/useShowGenericErrorSnackbar';

import { ReferAFriendLayout } from './refer-a-friend.layout';
import { ReferAFriend } from './refer-a-friend.component';
import { referAFriendMessages } from './refer-a-friend.messages';
import { useShareUrl, useCopyUrl, useBrowserCanShare, useSenderConfig } from './hooks';

export type ReferAFriendController = {
  handleClose?: () => void;
};

export const ReferAFriendController = (props: ReferAFriendController) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const handleClose = useCallback(() => {
    if (props.handleClose) {
      props.handleClose();
      return;
    }
    setIsOpen(false);
  }, [props]);
  const handleExited = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const onRetrieveConfigError = useCallback(() => {
    showGenericErrorSnackbar();
    handleClose();
  }, [handleClose, showGenericErrorSnackbar]);
  const { loading, config } = useSenderConfig({ fetchPolicy: 'cache-and-network', onError: onRetrieveConfigError });
  const browserCanShare = useBrowserCanShare();
  const shareUrl = useShareUrl();
  const copyToClipboard = useCopyUrl();

  const onShareLinkClick = useCallback(async () => {
    shareUrl({
      title: intl.formatMessage(referAFriendMessages.shareDialogTitle),
      dialogTitle: intl.formatMessage(referAFriendMessages.shareDialogTitle),
      text: config.textToShare,
      url: config.referralUrl,
    });
    // TODO: implement sending oc-activate metrics
  }, [config.referralUrl, config.textToShare, intl, shareUrl]);

  const onCopyLinkClick = useCallback(async () => {
    copyToClipboard(config.textToShare);
    // TODO: implement sending oc-activate metrics
  }, [config.textToShare, copyToClipboard]);

  return (
    <ReferAFriendLayout isOpen={isOpen} onClose={handleClose} onExited={handleExited}>
      <ReferAFriend
        loading={loading}
        instructions={config.instructions}
        canShare={browserCanShare}
        onShareLinkClick={onShareLinkClick}
        onCopyLinkClick={onCopyLinkClick}
        onCloseClick={handleClose}
      />
    </ReferAFriendLayout>
  );
};
