import { LoyaltyCurrencyConfig, PointsStrategy } from './loyalty-currency.types';
import { loyaltyCurrencyIconDefaultConfig } from './loyalty-currency.constants';

export const createDefaultCurrencyConfig = (pointsStrategy: PointsStrategy): LoyaltyCurrencyConfig => {
  if (pointsStrategy === PointsStrategy.NotUsed) {
    return {
      iconConfig: null,
      pointsStrategy: PointsStrategy.NotUsed,
    };
  }

  return {
    pointsStrategy,
    iconConfig: loyaltyCurrencyIconDefaultConfig[pointsStrategy],
  };
};
