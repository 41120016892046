import { ApplePayLineItem } from '@ocx/cap-apple-pay';
import { GooglePayDisplayItem } from '@ocx/cap-google-pay';

import { applePayPaymentStatusMap, googlePayPaymentStatusMap } from './payment-provider-sdk-based.constants';
import { PaymentItem } from '../payment-provider.types';

export const mapPaymentItemToApplePayLineItem = (item: PaymentItem): ApplePayLineItem => {
  return {
    label: item.label,
    amount: item.price,
    type: applePayPaymentStatusMap[item.status],
  };
};

export const mapPaymentItemToGooglePayDisplayItem = (item: PaymentItem): GooglePayDisplayItem => {
  return {
    type: 'LINE_ITEM',
    label: item.label,
    price: item.price,
    status: googlePayPaymentStatusMap[item.status],
  };
};
