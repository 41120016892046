import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.71 20.29L18 16.61A9 9 0 1016.61 18l3.68 3.68a1.002 1.002 0 001.42 0 1 1 0 000-1.39zM11 18a7 7 0 110-14 7 7 0 010 14z" />
    </SvgIcon>
  );
};
