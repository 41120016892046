import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';
import React from 'react';

export type ButtonProps = {
  component?: MUIButtonProps['component'];
  /**
   * The content of the button.
   * @defaultValue undefined
   */
  children: MUIButtonProps['children'];
  /**
   * The HTML type attribute specifies the type of button.
   * @defaultValue undefined
   */
  type?: 'submit' | 'reset' | 'button';
  /**
   * The variant of the button.
   * @defaultValue 'contained'
   */
  variant?: MUIButtonProps['variant'];
  /**
   * The size of the button.
   * @defaultValue 'medium'
   */
  size?: 'small' | 'medium';
  /**
   * The color of the button.
   * @defaultValue 'primary'
   */
  color?: 'inherit' | 'primary' | 'secondary' | 'error';
  /**
   * If true, the button will take up the full width of its container.
   * @defaultValue false
   */
  fullWidth?: boolean;
  /**
   * If true, the button is disabled.
   * @defaultValue false
   */
  disabled?: boolean;
  /**
   * Specifies that a button should automatically get focus when the page loads.
   * @defaultValue false
   */
  autoFocus?: boolean;
  /**
   * The click action.
   * @defaultValue undefined
   */
  onClick?: MUIButtonProps['onClick'];
  /**
   * The URL to link to when the button is clicked. If defined, an a element will be used as the root node.
   * @defaultValue undefined
   */
  href?: HTMLAnchorElement['href'];
  /**
   * The HTML rel attribute specifies the relationship between the current document and the linked document.
   * @defaultValue undefined
   */
  rel?: HTMLAnchorElement['rel'];
  /**
   * The HTML target attribute specifies where to open the linked document.
   * @defaultValue undefined
   */
  target?: HTMLAnchorElement['target'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * @defaultValue undefined
   */
  sx?: MUIButtonProps['sx'];
  /**
   * Element placed after the children.
   * @defaultValue undefined
   */
  endIcon?: MUIButtonProps['endIcon'];
};

export const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant = 'contained', size = 'medium', color = 'primary', ...rest } = props;
  return <MUIButton variant={variant} color={color} size={size} {...rest} ref={ref} />;
});
