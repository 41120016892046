import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { Button, Typography, SizingMultiplier } from '@ocx/ui';

import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { ModalDrawer, AchievementDecoration, NotVerifiedDecoration, VerifiedDecoration } from '../components';
import { ServiceLink } from '../components/ServiceLink';
import { InfoActionSheetProps } from './info-action-sheet.types';
import { isVariantWithStatusIcon } from './info-action-sheet.utils';

export const InfoActionSheet = (props: InfoActionSheetProps) => {
  const intl = useIntl();
  const {
    title,
    description,
    variant,
    isServiceLinkEnabled,
    isCloseButtonEnabled,
    isVisible = true,
    onExited,
    onClose,
    children,
    renderActionButton,
  } = props;

  return (
    <ModalDrawer open={isVisible} onClose={onClose} onExited={onExited} isDecorated={!!variant}>
      {variant === 'congrats' && <AchievementDecoration />}
      {variant === 'not-verified' && <NotVerifiedDecoration />}
      {variant === 'verified' && <VerifiedDecoration />}

      <Stack spacing={SizingMultiplier.lg} p={SizingMultiplier.lg} pt={SizingMultiplier.xl}>
        <Stack textAlign={isVariantWithStatusIcon(variant) ? 'center' : 'left'} spacing={SizingMultiplier.md}>
          {title && (
            <Typography variant="h5" component="h1">
              {title}
            </Typography>
          )}
          {description && <Typography>{description}</Typography>}
          {children}
        </Stack>
        {renderActionButton?.()}
        {isCloseButtonEnabled && (
          <Button onClick={onClose} variant="text">
            {intl.formatMessage(buttonTextMessages['button-text:close'])}
          </Button>
        )}
        {isServiceLinkEnabled && <ServiceLink />}
      </Stack>
    </ModalDrawer>
  );
};
