import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { useIntl } from 'react-intl';
import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';
import { logInDev } from '@ocx/utils';
import { Button } from '@ocx/ui';
import { InfoActionSheetControllerProps } from './info-action-sheet.types';
import { InfoActionSheet } from './info-action-sheet';

export const InfoActionSheetController = (props: InfoActionSheetControllerProps) => {
  const intl = useIntl();
  const { title, description, variant, onExited, isCloseButtonEnabled, isServiceLinkEnabled, content } = props;
  const { visible, hide, remove } = useModal();

  const handleExited = useCallback(() => {
    onExited?.();
    remove();
  }, [remove, onExited]);

  let renderAction;
  // eslint-disable-next-line react/destructuring-assignment
  if ('actionButtonProps' in props && props.actionButtonProps) {
    const {
      actionButtonProps: {
        onActionButtonClick,
        actionButtonVariant = 'contained',
        actionButtonText = intl.formatMessage(buttonTextMessages['button-text:close']),
      },
    } = props;
    renderAction = () => {
      return (
        <Button
          onClick={() => {
            onActionButtonClick();
            hide().catch(logInDev);
          }}
          variant={actionButtonVariant}>
          {actionButtonText}
        </Button>
      );
    };
  }

  if ('renderActionButton' in props) {
    const { renderActionButton } = props;
    renderAction = () => renderActionButton(hide);
  }

  return (
    <InfoActionSheet
      title={title}
      description={description}
      variant={variant}
      isVisible={visible}
      onClose={hide}
      onExited={handleExited}
      renderActionButton={renderAction}
      isCloseButtonEnabled={isCloseButtonEnabled}
      isServiceLinkEnabled={isServiceLinkEnabled}>
      {content}
    </InfoActionSheet>
  );
};
