import { PaymentInstrumentType } from '@ocx/graphql';

// Key - string from Payment.fns.cardType
// node_modules/@types/payment/index.d.ts
const cardTypeToPaymentInstrumentTypeMapping: { [key: string]: PaymentInstrumentType } = {
  visa: PaymentInstrumentType.Visa,
  mastercard: PaymentInstrumentType.Mastercard,
  discover: PaymentInstrumentType.Discover,
  amex: PaymentInstrumentType.AmericanExpress,
  jcb: PaymentInstrumentType.Jcb,
  dinersclub: PaymentInstrumentType.DinersClub,
  maestro: PaymentInstrumentType.Maestro,
  laser: PaymentInstrumentType.Laser,
  elo: PaymentInstrumentType.Elo,
  // unknowns
  unionpay: PaymentInstrumentType.Unknown,
};

/**
 * Get PaymentInstrumentType based on string
 */
export const getPaymentInstrumentTypeByCardType = (cardType: string): PaymentInstrumentType =>
  cardTypeToPaymentInstrumentTypeMapping[cardType.toLowerCase()] || PaymentInstrumentType.Unknown;
