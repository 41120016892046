import {
  ApplePayLineItem,
  ApplePayCardNetwork,
  ApplePayPaymentRequest,
  ApplePayCanMakeRequest,
  CreateApplePayCanMakeRequestParams,
  CreateApplePayPaymentRequestParams,
  APPLE_PAY_TRANSACTION_CANCELED_CODE,
} from './apple-pay-plugin.types';

const calculateLineItemsTotal = (lineItems: ApplePayLineItem[]): number => {
  return lineItems.reduce((previousValue, currentValue) => {
    return previousValue + parseFloat(currentValue.amount);
  }, 0);
};

export const createPaymentRequest = (params: CreateApplePayPaymentRequestParams): ApplePayPaymentRequest => {
  const { lineItems } = params;

  const total = calculateLineItemsTotal(lineItems);

  return {
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: params.supportedNetworks satisfies ApplePayCardNetwork[],
    countryCode: 'US',
    currencyCode: 'USD',
    total: { label: params.merchantName, amount: total.toString(), type: 'pending' },
    lineItems,
  };
};

export const createCanMakeRequest = (params: CreateApplePayCanMakeRequestParams): ApplePayCanMakeRequest => {
  return {
    merchantId: params.merchantId,
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: params.supportedNetworks,
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const isApplePayTransactionCanceled = (e: unknown) => e?.code === APPLE_PAY_TRANSACTION_CANCELED_CODE;
