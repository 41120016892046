import { FetchResult } from '@apollo/client';

export const isOperationFailedWithExtensions = (result: FetchResult, extensionCodes: string[]) => {
  if (!result.errors) {
    return false;
  }
  const error = result.errors.find((error) => {
    if (error.extensions && error.extensions.code) {
      return extensionCodes.includes(error.extensions.code);
    }
    return false;
  });

  return error !== undefined;
};

export const getErrorWithExtensionInfo = (
  errors: FetchResult['errors'],
): { extensionCode: string; message: string } | null => {
  if (!errors) {
    return null;
  }
  const error = errors[0];
  if (!error) {
    return null;
  }
  return { extensionCode: error.extensions?.code ?? 'UNKNOWN_EXTENSION_CODE', message: error.message };
};
