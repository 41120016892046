import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';

import { REFERRAL_CODE_QUERY_PARAM } from './referral-link.constants';

export const useReferralCode = () => {
  const [searchParams] = useSearchParams();
  const referralProgramConfig = useConfiguration('loyaltyProgram.referralProgramConfig');
  return useMemo(() => {
    if (!referralProgramConfig?.referralProgramEnabled) {
      return null;
    }
    return searchParams.get(REFERRAL_CODE_QUERY_PARAM) || null;
  }, [referralProgramConfig?.referralProgramEnabled, searchParams]);
};
