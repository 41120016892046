import { makeVar } from '@apollo/client';
import { ReactiveListener } from '@apollo/client/cache/inmemory/reactiveVars';
import { Preferences } from '@capacitor/preferences';

import { sentrySetUser } from '@ocx/data-membership';
import { reset as resetRudderStack } from '../../lib/rudderStack/rudderStack.utils';

export const ACCESS_TOKEN_STORAGE_KEY = 'atk';
export const REFRESH_TOKEN_STORAGE_KEY = 'rtk';

export const accessTokenVar = makeVar<string>('');
const accessTokenOnNextChange: ReactiveListener<string> = async (token) => {
  isLoggedInVar(!!token);
  if (token) {
    await Preferences.set({ key: ACCESS_TOKEN_STORAGE_KEY, value: token });
  } else {
    await Preferences.remove({ key: ACCESS_TOKEN_STORAGE_KEY });
  }
  accessTokenVar.onNextChange(accessTokenOnNextChange);
};
accessTokenVar.onNextChange(accessTokenOnNextChange);

export const refreshTokenVar = makeVar<string>('');
const refreshTokenOnNextChange: ReactiveListener<string> = async (token) => {
  if (token) {
    await Preferences.set({ key: REFRESH_TOKEN_STORAGE_KEY, value: token });
  } else {
    await Preferences.remove({ key: REFRESH_TOKEN_STORAGE_KEY });
  }
  refreshTokenVar.onNextChange(refreshTokenOnNextChange);
};
refreshTokenVar.onNextChange(refreshTokenOnNextChange);

export const isLoggedInVar = makeVar(!!accessTokenVar());
const isLoggedInVarOnChange: ReactiveListener<boolean> = (isLoggedIn) => {
  if (!isLoggedIn) {
    sentrySetUser(null);
    resetRudderStack();
  }
  isLoggedInVar.onNextChange(isLoggedInVarOnChange);
};
isLoggedInVar.onNextChange(isLoggedInVarOnChange);

export const syncReactiveAuthVariables = async () => {
  const { value: accessToken } = await Preferences.get({ key: ACCESS_TOKEN_STORAGE_KEY });
  const { value: refreshToken } = await Preferences.get({ key: REFRESH_TOKEN_STORAGE_KEY });
  accessTokenVar(accessToken || '');
  refreshTokenVar(refreshToken || '');
};
