import { ReactNode } from 'react';

import { ModalDrawer } from '@ocx-app/components/modals';
import { HtmlTitle } from '@ocx-app/components/router/HtmlTitle';

import { referAFriendMessages } from './refer-a-friend.messages';

export type ReferAFriendLayoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  onExited(): void;
};

export const ReferAFriendLayout = (props: ReferAFriendLayoutProps) => {
  const { children, isOpen, onClose, onExited } = props;
  return (
    <ModalDrawer open={isOpen} onClose={onClose} onExited={onExited}>
      <HtmlTitle title={referAFriendMessages.htmlTitle} />
      {children}
    </ModalDrawer>
  );
};
