import { GooglePayButtonColor } from '@ocx/cap-google-pay';

import {
  PaymentProviderIFrameBased as PaymentProviderIFrameBasedInterface,
  PaymentProviderIFrameBasedAddEventListenersParams,
  PaymentProviderIFrameBasedRenderParams,
  PaymentProviderIFrameBasedSubmitParams,
  RequestDigitalWalletPaymentParams,
  RequestDigitalWalletPaymentReturns,
} from './payment-provider-iframe-based.types';
import {
  PaymentProviderSDKBasedApplePay,
  PaymentProviderSDKBasedApplePayConstructorParams,
} from '../payment-provider-sdk-based/payment-provider-sdk-based.apple-pay';
import {
  PaymentProviderSDKBasedGooglePay,
  PaymentProviderSDKBasedGooglePayConstructorParams,
} from '../payment-provider-sdk-based/payment-provider-sdk-based.google-pay';
import {
  mapPaymentItemToApplePayLineItem,
  mapPaymentItemToGooglePayDisplayItem,
} from '../payment-provider-sdk-based/payment-provider-sdk-based.utils';

export abstract class PaymentProviderIFrameBased implements PaymentProviderIFrameBasedInterface {
  public tokenizationMechanism = 'IFRAME' as const;
  private applePay: PaymentProviderSDKBasedApplePay | null = null;
  private googlePay: PaymentProviderSDKBasedGooglePay | null = null;

  constructor(params: {
    applePay: PaymentProviderSDKBasedApplePayConstructorParams | null;
    googlePay: PaymentProviderSDKBasedGooglePayConstructorParams | null;
  }) {
    if (params.applePay) {
      this.applePay = new PaymentProviderSDKBasedApplePay(params.applePay);
    }
    if (params.googlePay) {
      this.googlePay = new PaymentProviderSDKBasedGooglePay(params.googlePay);
    }
  }

  // region | Wallet (payment instrument tokenization)
  abstract render(params: PaymentProviderIFrameBasedRenderParams): Promise<void>;
  abstract addEventListeners(params: PaymentProviderIFrameBasedAddEventListenersParams): void;
  abstract removeEventListeners(): void;
  abstract submit(params: PaymentProviderIFrameBasedSubmitParams): Promise<void>;
  // endregion

  // region | ApplePay
  async getIsApplePayAvailable(): Promise<boolean> {
    if (!this.applePay) {
      return false;
    }
    return this.applePay.getIsAvailable();
  }

  async requestApplePayPayment(params: RequestDigitalWalletPaymentParams): Promise<RequestDigitalWalletPaymentReturns> {
    if (!this.applePay) {
      throw new Error('[PaymentProviderSDKBased.requestApplePayPayment] ApplePay is not configured');
    }
    return this.applePay.requestPayment({ lineItems: params.paymentItems.map(mapPaymentItemToApplePayLineItem) });
  }
  // endregion

  // region | GooglePay
  async getIsGooglePayAvailable(): Promise<boolean> {
    if (!this.googlePay) {
      return false;
    }
    return this.googlePay.getIsAvailable();
  }

  async requestGooglePayPayment(
    params: RequestDigitalWalletPaymentParams,
  ): Promise<RequestDigitalWalletPaymentReturns> {
    if (!this.googlePay) {
      throw new Error('[PaymentProviderSDKBased.requestGooglePayPayment] GooglePay is not configured');
    }
    return this.googlePay.requestPayment({
      displayItems: params.paymentItems.map(mapPaymentItemToGooglePayDisplayItem),
    });
  }

  async createGooglePayButton(params: {
    onClick?: () => void;
    buttonColor?: GooglePayButtonColor;
  }): Promise<HTMLElement | null> {
    if (!this.googlePay) {
      return null;
    }
    return this.googlePay.createButton(params);
  }
  // endregion
}
