import { v4 as uuid } from 'uuid';

import { wait } from '@ocx/utils';

import { KountPlugin } from './kount-plugin';
import { KountPluginInitCollectorParams, KountCollectorStatus } from './kount-plugin.types';

export type KountInitParams = Omit<KountPluginInitCollectorParams, 'sessionId'>;

const kountCollectorStatusAttemptsLimit = 10;
const kountCollectorStatusAttemptsInterval = 1000; // 1sec

export class Kount {
  // sessionId used during starting transaction
  static sessionId: string | null = null;

  static isEnabled = false;

  // Max sessionID length is 32. UUIDv4 length without dashes is 32
  static generateSessionId = () => uuid().replace(/-/g, '');

  static async init(params: KountInitParams): Promise<{ success: boolean }> {
    const result = await KountPlugin.initCollector({
      merchantId: params.merchantId,
      collectorHostname: params.collectorHostname,
      environment: params.environment,
    });

    if (result.success) {
      Kount.isEnabled = true;
    }
    return result;
  }

  static async collect(): Promise<{ sessionId: string | null }> {
    if (!Kount.isEnabled) {
      return { sessionId: null };
    }

    // Max sessionID length is 32. UUIDv4 length without dashes is 32
    Kount.sessionId = Kount.generateSessionId();
    await KountPlugin.collect({ sessionId: Kount.sessionId });
    const isReady = await Kount.isCollectorReady();

    if (!isReady) {
      throw new Error('Kount DDC can not collect data');
    }

    return { sessionId: Kount.sessionId };
  }

  static async isCollectorReady(count: number = 0): Promise<boolean> {
    const { status } = await KountPlugin.getCollectorStatus();
    if (status === KountCollectorStatus.COMPLETED) {
      return true;
    }
    if (status === KountCollectorStatus.FAILED) {
      return false;
    }
    if (count >= kountCollectorStatusAttemptsLimit) {
      return false;
    }
    await wait(kountCollectorStatusAttemptsInterval);
    return Kount.isCollectorReady(count + 1);
  }
}
