import { Share, ShareOptions, ShareResult } from '@capacitor/share';

export type { ShareOptions, ShareResult };

export const canShare = async (): Promise<boolean> => {
  try {
    return (await Share.canShare()).value;
  } catch {
    return false;
  }
};

export const share = (options: ShareOptions): Promise<ShareResult> => {
  return Share.share(options);
};

export const exceptionIsShareAbortError = (e: unknown): boolean => {
  if (e instanceof DOMException && e.name === 'AbortError') {
    return true;
  }
  if (e instanceof Error && e.message.includes('Share canceled')) {
    return true;
  }
  return false;
};
