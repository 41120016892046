import { WebPlugin } from '@capacitor/core';
import { addBreadcrumb } from '@sentry/react';

import { wait, loadScript } from '@ocx/utils';

import {
  GooglePayPlugin,
  PaymentsClient,
  GooglePayEnvironmentWeb,
  IsReadyToPayParams,
  IsReadyToPayReturns,
  PaymentRequestParams,
  PaymentRequestReturns,
} from './google-pay-plugin.types';
import { GPAY_SCRIPT_URL, MAX_LOAD_ATTEMPTS, LOAD_ATTEMPT_TIMEOUT_MS } from './google-pay-plugin.constants';

export class GooglePayPluginWeb extends WebPlugin implements GooglePayPlugin {
  loaded: boolean = false;
  // is global.google.payments exists and available
  isGooglePaymentsApiAvailable: boolean = false;
  paymentsClient: PaymentsClient | null = null;
  environment: GooglePayEnvironmentWeb | null = null;

  async init(environment: GooglePayEnvironmentWeb) {
    // Handle reinitialization only on environment change
    if (environment === this.environment) {
      return;
    }
    this.environment = environment;
    try {
      await this.initLoadScript();
    } catch (e: unknown) {
      addBreadcrumb({
        category: 'google_pay_plugin_web',
        message: 'Failed to load GooglePay script',
      });
    }
    try {
      await this.initPaymentsClient();
    } catch (e: unknown) {
      addBreadcrumb({
        category: 'google_pay_plugin_web',
        message: 'GooglePay script script is loaded but no api available',
      });
    }
  }

  async isReadyToPay({ isReadyToPayRequest }: IsReadyToPayParams): Promise<IsReadyToPayReturns> {
    const paymentsClient = await this.getPaymentsClient();
    const { result } = await paymentsClient.isReadyToPay(isReadyToPayRequest);
    return { success: result };
  }

  async requestPay(params: PaymentRequestParams): Promise<PaymentRequestReturns> {
    const paymentsClient = await this.getPaymentsClient();
    return await paymentsClient.loadPaymentData(params.paymentRequest);
  }

  async getPaymentsClient(): Promise<PaymentsClient> {
    const isLoaded = await this.isLoaded();
    const isAvailable = await this.isAvailable();

    if (!isLoaded || !isAvailable || !this.paymentsClient) {
      throw new Error(
        `[GooglePayPluginWeb] Failed to get PaymentsClient. isLoaded:${isLoaded}, isAvailable:${isAvailable}`,
      );
    }

    return this.paymentsClient;
  }

  // region Initiation Helpers
  private async initLoadScript() {
    if (!this.loaded) {
      await loadScript(GPAY_SCRIPT_URL);
      this.loaded = true;
    }
  }

  private async initPaymentsClient() {
    if (!this.loaded || !this.environment) {
      return;
    }
    this.isGooglePaymentsApiAvailable = Boolean(global.google?.payments);
    if (!this.isGooglePaymentsApiAvailable) {
      throw new Error('[GooglePayPluginWeb] SDK is loaded but no api available');
    }
    this.paymentsClient = new global.google.payments.api.PaymentsClient({
      environment: this.environment,
    });
  }

  private async isLoaded(loadAttempts = 0): Promise<boolean> {
    if (!this.loaded && loadAttempts < MAX_LOAD_ATTEMPTS) {
      await wait(LOAD_ATTEMPT_TIMEOUT_MS);
      return this.isLoaded(loadAttempts + 1);
    }
    return this.loaded;
  }

  private async isAvailable(loadAttempts = 0): Promise<boolean> {
    if (this.isGooglePaymentsApiAvailable && !this.paymentsClient && loadAttempts < MAX_LOAD_ATTEMPTS) {
      await wait(LOAD_ATTEMPT_TIMEOUT_MS);
      return this.isAvailable(loadAttempts + 1);
    }
    return this.isGooglePaymentsApiAvailable;
  }
  // endregion
}
