import React from 'react';
import { ButtonBase, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  paymentInstrumentBalanceLabel,
  paymentInstrumentExpiredMessages,
} from '@ocx-app/modules/wallet/wallet.messages';
import { Typography } from '@ocx/ui';

import { CardIcon, ArrowRightIcon } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../../../../../theme/constants';

const Button = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  color: theme.palette.text.primary,
  padding: `12px ${theme.spacing(SizingMultiplier.md)}`,
  width: '100%',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export type PaymentMethodButtonProps = {
  onClick: () => void;
  startIcon?: React.ReactElement;
  withEndArrow?: boolean;
  children: React.ReactNode;
  balance?: string | null;
  hiddenCardNumber?: string | null;
  isExpired?: boolean;
};

type RenderActiveCardInfoProps = {
  balance?: string | null;
  hiddenCardNumber?: string | null;
};

const RenderActiveCardInfo = ({ balance, hiddenCardNumber }: RenderActiveCardInfoProps) => {
  if (balance || hiddenCardNumber) {
    return (
      <Typography variant="body2" component="span" color="textSecondary">
        {balance ? (
          <FormattedMessage
            {...paymentInstrumentBalanceLabel['payments:payment-instrument:balance']}
            values={{ balance }}
          />
        ) : (
          hiddenCardNumber
        )}
      </Typography>
    );
  }
  return null;
};

export const PaymentMethodButton = ({
  onClick,
  startIcon = <CardIcon />,
  withEndArrow = false,
  children,
  balance,
  isExpired,
  hiddenCardNumber,
}: PaymentMethodButtonProps) => {
  return (
    <Button onClick={onClick}>
      {startIcon}
      <Stack mx={SizingMultiplier.sm} alignItems="start">
        <Typography component="span">{children}</Typography>
        {isExpired ? (
          <Typography variant="body2" color="error">
            <FormattedMessage {...paymentInstrumentExpiredMessages.paymentInstrumentExpiredErrorMessages} />
          </Typography>
        ) : (
          <RenderActiveCardInfo balance={balance} hiddenCardNumber={hiddenCardNumber} />
        )}
      </Stack>
      {withEndArrow && <ArrowRightIcon sx={{ ml: 'auto', mr: -SizingMultiplier.sm }} />}
    </Button>
  );
};
