import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const DoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="m18.7099 7.2101c-.0929-.09372-.2035-.16812-.3254-.21889-.1218-.05077-.2525-.0769-.3846-.0769-.132 0-.2627.02613-.3845.0769-.1219.05077-.2325.12517-.3254.21889l-7.45005 7.46-3.13-3.14c-.09652-.0932-.21046-.1665-.33532-.2158-.12485-.0492-.25818-.0733-.39236-.071-.13418.0024-.26659.0311-.38967.0846-.12307.0535-.23441.1307-.32765.2272s-.16655.2105-.21576.3353c-.0492.1249-.07333.2582-.07101.3924s.03105.2666.08454.3897c.0535.123.13071.2344.22723.3276l3.84 3.84c.09296.0937.20356.1681.32542.2189s.25257.0769.38458.0769.26275-.0261.38455-.0769c.1219-.0508.2325-.1252.3255-.2189l8.1599-8.15999c.1016-.09365.1826-.2073.238-.3338s.084-.2631.084-.4012c0-.13811-.0286-.27471-.084-.40121s-.1364-.24015-.238-.3338z" />
    </SvgIcon>
  );
};
