import singlePointCoin from '@ocx/buildtime-retailer-configuration/assets/coin-icon.svg';
import multiplePointCoins from '@ocx/buildtime-retailer-configuration/assets/coins-icon.svg';
import singleCentCoin from '@ocx/buildtime-retailer-configuration/assets/dollar-coin-icon.svg';
import multipleCentCoins from '@ocx/buildtime-retailer-configuration/assets/dollars-coins-icon.svg';

import { LoyaltyCurrencyIconConfig, PointsStrategy } from './loyalty-currency.types';

// This should allow making storybook stories to reflect the actual Retailer, e.g. Yesway with smiles
export const loyaltyCurrencyIconDefaultConfig: Record<
  Exclude<PointsStrategy, PointsStrategy.NotUsed>,
  LoyaltyCurrencyIconConfig
> = {
  [PointsStrategy.Points]: {
    multiple: multiplePointCoins,
    single: singlePointCoin,
  },
  [PointsStrategy.Cents]: {
    multiple: multipleCentCoins,
    single: singleCentCoin,
  },
};
