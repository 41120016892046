import React from 'react';
import * as Sentry from '@sentry/react';
import { useReactiveVar } from '@apollo/client';

import { useCustomerLogoutMutation } from '@ocx/graphql';
import { sentrySetUser } from '@ocx/data-membership';
import { clearCache } from '../cache-layer/utils';
import { authService } from './instances/authService';
import { useConfigurationContext } from '../configuration/useConfigurationContext';

import { isLoggedInVar } from './authReactiveVariables';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';

interface IUseLogoutControllerReturns {
  isLoggedIn: boolean;
  logout(params?: { skipMutation?: boolean }): Promise<void>;
}

interface IUseLogoutControllerParams {
  onError?(error: Error): any;
}

export const useLogoutController = (params?: IUseLogoutControllerParams): IUseLogoutControllerReturns => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [customerLogout] = useCustomerLogoutMutation();
  const { setMembershipOverrides } = useConfigurationContext();

  const { reset: resetRudderStack } = useRudderStack();

  const logout = React.useCallback<IUseLogoutControllerReturns['logout']>(
    async ({ skipMutation } = {}) => {
      try {
        setMembershipOverrides(null);
        if (!skipMutation) {
          await customerLogout();
        }

        sentrySetUser(null);
        resetRudderStack();

        await authService.clearSession();
        await clearCache();
      } catch (e: any) {
        Sentry.captureException(e);
        params?.onError?.(e);
      }
    },
    [customerLogout, params, setMembershipOverrides, resetRudderStack],
  );

  return {
    isLoggedIn,
    logout,
  };
};
