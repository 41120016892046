import { useState, useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';

import { usePaymentProvider } from './use-payment-provider.hook';

export const useIsApplePayAvailable = (): { loading: boolean; available: boolean } => {
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(false);
  const paymentProvider = usePaymentProvider();

  useEffect(() => {
    const exec = async () => {
      const isApplePayAvailable = await paymentProvider.getIsApplePayAvailable();
      setAvailable(isApplePayAvailable);
    };
    exec()
      .catch(() => addBreadcrumb({ message: '[useIsApplePayAvailable] failed to check ApplePay availability' }))
      .finally(() => setLoading(false));
  }, [paymentProvider]);

  return { loading, available };
};
