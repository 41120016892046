import { captureMessage } from '@sentry/react';
import { useEffect } from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';

import { PointsStrategy } from './loyalty-currency.types';

type Size = 'small' | 'medium' | 'large';

const sizeMap: Record<Size, number> = {
  small: 16,
  medium: 24,
  large: 32,
};

type LoyaltyCurrencyIconProps = {
  variant?: 'single' | 'multiple';
  size?: Size;
};

export const LoyaltyCurrencyIcon = (props: LoyaltyCurrencyIconProps) => {
  const { variant = 'single', size = 'medium' } = props;
  const { pointsStrategy, iconConfig } = useConfiguration('loyaltyCurrency');

  useEffect(() => {
    if (pointsStrategy === PointsStrategy.NotUsed) {
      captureMessage('PointsStrategy.NotUsed encountered in LoyaltyCurrencyIcon');
    }
  }, [pointsStrategy]);

  if (pointsStrategy === PointsStrategy.NotUsed) {
    return null;
  }

  return <img src={iconConfig[variant]} alt="coins" width={sizeMap[size]} height={sizeMap[size]} />;
};
