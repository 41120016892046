import { useEffect, useState } from 'react';
import { addBreadcrumb } from '@sentry/react';

import { canShare } from '@ocx/utils';
import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';

export const useBrowserCanShare = (): boolean | null => {
  const { triggerEvent } = useRudderStack();
  const [browserCanShare, setBrowserCanShare] = useState<null | boolean>(null);
  useEffect(() => {
    (async () => {
      try {
        const result = await canShare();
        if (!result) {
          throw new Error('This browser does not support ShareAPI');
        }
        setBrowserCanShare(true);
      } catch {
        addBreadcrumb({
          message: 'Referrals -> Share.share unsupported.',
        });
        triggerEvent('referral_link_share_unsupported');
        setBrowserCanShare(false);
      }
    })();
  }, [triggerEvent]);
  return browserCanShare;
};
