import { GooglePayCardNetwork } from '@ocx/graphql';
import {
  GooglePay,
  GooglePayDisplayItem,
  GooglePayEnvironmentWeb,
  GooglePayPaymentGatewayTokenizationParameters,
  GooglePayCardNetwork as GooglePayPluginCardNetwork,
  GooglePayButtonColor,
} from '@ocx/cap-google-pay';

import { RequestDigitalWalletPaymentReturns } from './payment-provider-sdk-based.types';
import { googlePayCardNetworkMap } from './payment-provider-sdk-based.constants';

export type PaymentProviderSDKBasedGooglePayConstructorParams = {
  allowCreditCards: boolean;
  allowPrepaidCards: boolean;
  allowedCardNetworks: GooglePayCardNetwork[];
  environment: GooglePayEnvironmentWeb;
  merchantId: string;
  merchantName: string | null;
  gatewayParameters: GooglePayPaymentGatewayTokenizationParameters;
};

export class PaymentProviderSDKBasedGooglePay {
  private readonly options: {
    allowCreditCards: boolean;
    allowPrepaidCards: boolean;
    allowedCardNetworks: GooglePayPluginCardNetwork[];
    environment: GooglePayEnvironmentWeb;
    merchantId: string;
    merchantName: string | null;
    gatewayParameters: GooglePayPaymentGatewayTokenizationParameters;
  };

  constructor(params: PaymentProviderSDKBasedGooglePayConstructorParams) {
    this.options = {
      allowCreditCards: params.allowCreditCards,
      allowPrepaidCards: params.allowPrepaidCards,
      allowedCardNetworks: params.allowedCardNetworks.map((network) => googlePayCardNetworkMap[network]),
      environment: params.environment,
      merchantId: params.merchantId,
      merchantName: params.merchantName,
      gatewayParameters: params.gatewayParameters,
    };
  }

  async getIsAvailable(): Promise<boolean> {
    return GooglePay.isReadyToPay({ allowedCardNetworks: this.options.allowedCardNetworks });
  }

  async requestPayment(params: { displayItems: GooglePayDisplayItem[] }): Promise<RequestDigitalWalletPaymentReturns> {
    const result = await GooglePay.requestPay({
      displayItems: params.displayItems,
      merchantInfo: this.options,
    });

    if (result.isCanceled) {
      return {
        token: '',
        nonce: null,
        canceled: true,
      };
    }

    const {
      paymentMethodData: {
        tokenizationData: { token },
      },
    } = result;

    return {
      token,
      nonce: null,
      canceled: false,
    };
  }

  async createButton(params: {
    onClick?: () => void;
    buttonColor?: GooglePayButtonColor;
  }): Promise<HTMLElement | null> {
    return GooglePay.createButton(params);
  }
}
