import { PaymentInstrumentType } from '@ocx/graphql';

export const paymentTypeToPaymentTitleMap: {
  [key in PaymentInstrumentType]: string;
} = {
  [PaymentInstrumentType.AmazonPay]: 'Amazon Pay',
  [PaymentInstrumentType.AmericanExpress]: 'American Express',
  [PaymentInstrumentType.ApplePay]: 'Apple Pay',
  [PaymentInstrumentType.ApplePayAmericanExpress]: 'Apple Pay American Express',
  [PaymentInstrumentType.ApplePayDiscover]: 'Apple Pay Discover',
  [PaymentInstrumentType.ApplePayMastercard]: 'Apple Pay Mastercard',
  [PaymentInstrumentType.ApplePayVisa]: 'Apple Pay Visa',
  [PaymentInstrumentType.CartesBancaires]: 'Cartes Bancaires',
  [PaymentInstrumentType.CarteBlanche]: 'Carte Blanche',
  [PaymentInstrumentType.ChasePay]: 'Chase Pay',
  [PaymentInstrumentType.ChinaUnionPay]: 'China Union Pay',
  [PaymentInstrumentType.CitiPay]: 'Citi Pay',
  [PaymentInstrumentType.Credit]: 'Credit',
  [PaymentInstrumentType.Debit]: 'Debit',
  [PaymentInstrumentType.DinersClub]: 'Diners Club',
  [PaymentInstrumentType.Discover]: 'Discover',
  [PaymentInstrumentType.Eftpos]: 'Eftpos',
  [PaymentInstrumentType.Elo]: 'Elo',
  [PaymentInstrumentType.Gift]: 'Gift',
  [PaymentInstrumentType.GooglePay]: 'Google Pay',
  [PaymentInstrumentType.GooglePayAmericanExpress]: 'Google Pay American Express',
  [PaymentInstrumentType.GooglePayDiscover]: 'Google Pay Discover',
  [PaymentInstrumentType.GooglePayMastercard]: 'Google Pay Mastercard',
  [PaymentInstrumentType.GooglePayVisa]: 'Google Pay Visa',
  [PaymentInstrumentType.GoogleWallet]: 'Google Wallet',
  [PaymentInstrumentType.IdCredit]: 'ID Credit',
  [PaymentInstrumentType.Interac]: 'Interac',
  [PaymentInstrumentType.Jcb]: 'JCB',
  [PaymentInstrumentType.Laser]: 'Laser',
  [PaymentInstrumentType.Maestro]: 'Maestro',
  [PaymentInstrumentType.Mastercard]: 'Mastercard',
  [PaymentInstrumentType.Masterpass]: 'Masterpass',
  [PaymentInstrumentType.Paypal]: 'PayPal',
  [PaymentInstrumentType.Prepaid]: 'Prepaid',
  [PaymentInstrumentType.PrivateLabel]: 'Private Label',
  [PaymentInstrumentType.QuicPay]: 'Quic Pay',
  [PaymentInstrumentType.Solo]: 'Solo',
  [PaymentInstrumentType.Suica]: 'Suica',
  [PaymentInstrumentType.Switch]: 'Switch',
  [PaymentInstrumentType.Unknown]: 'Unknown',
  [PaymentInstrumentType.Venmo]: 'Venmo',
  [PaymentInstrumentType.Visa]: 'Visa',
  [PaymentInstrumentType.VisaCheckout]: 'Visa Checkout',
  [PaymentInstrumentType.VisaVpay]: 'Visa Vpay',
};
