import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PumpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M22.54 6.29L21 4.75l-1.41-1.41a1.003 1.003 0 10-1.42 1.42l1 1-.83 2.49a3 3 0 00.73 3.07l2.95 3V19a1 1 0 01-2 0v-2a3 3 0 00-3-3H16V5a3 3 0 00-3-3H7a3 3 0 00-3 3v14a3 3 0 003 3h6a3 3 0 003-3v-3h1a1 1 0 011 1v2a3 3 0 006 0V9.83a5 5 0 00-1.46-3.54zM14 19a1 1 0 01-1 1H7a1 1 0 01-1-1v-7h8v7zm0-9H6V5a1 1 0 011-1h6a1 1 0 011 1v5zm8 1.42l-1.54-1.54a1 1 0 01-.24-1l.51-1.54.39.4A3 3 0 0122 9.83v1.59z" />
    </SvgIcon>
  );
};
