import { useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';

import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { isFreshdeskSupportWidgetEnabled } from '@ocx-app/modules/configuration/app-config.type-guards';
import { useMembershipContext } from '@ocx/data-membership';

import { FreshdeskSupportWidget } from './feat/freshdesk/freshdesk-support-widget';

export const useSupportWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    config: { freshdesk },
  } = useConfiguration();
  const { membership } = useMembershipContext();

  const isEnabled = useMemo(() => isFreshdeskSupportWidgetEnabled(freshdesk.supportWidget), [freshdesk.supportWidget]);

  const open = useCallback(async () => {
    if (!isFreshdeskSupportWidgetEnabled(freshdesk.supportWidget)) {
      return;
    }

    setIsLoading(true);
    FreshdeskSupportWidget.open({
      token: freshdesk.supportWidget.token,
      widgetUuid: freshdesk.supportWidget.id,
      host: freshdesk.supportWidget.host,
      sdkUrl: freshdesk.supportWidget.sdkUrl,
      firstName: membership?.firstName,
      lastName: membership?.lastName,
      email: membership?.email,
      externalId: membership?.id,
    })
      .catch(Sentry.captureException)
      .finally(() => setIsLoading(false));
  }, [freshdesk.supportWidget, membership?.email, membership?.firstName, membership?.id, membership?.lastName]);

  return { isEnabled, isLoading, open };
};
