import { ButtonBase } from '@mui/material';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListItemNavLinkProps = {
  to: LinkProps['to'];
  children?: ReactNode;
  onClick?: LinkProps['onClick'];
};

export const ListItemNavLink = (props: ListItemNavLinkProps) => {
  const { children, ...rest } = props;
  return (
    <ButtonBase
      component={Link}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
      }}
      {...rest}>
      {children}
    </ButtonBase>
  );
};
