import { EncryptFunc } from './fiserv-encryption.utils';
import {
  FiservEncryptedCreditPaymentInstrumentPayload,
  FiservEncryptedPrepaidPaymentInstrumentPayload,
  FiservTokenizeCreditPaymentInstrumentPayload,
  FiservTokenizePrepaidPaymentInstrumentPayload,
} from './fiserv.types';

export const encryptCreditPaymentInstrumentPayload = async (
  encrypt: EncryptFunc,
  payload: FiservTokenizeCreditPaymentInstrumentPayload,
): Promise<FiservEncryptedCreditPaymentInstrumentPayload> => {
  const [cardNumber, month, year, securityCode] = await Promise.all([
    encrypt(payload.cardNumber),
    encrypt(payload.expirationDateMonth),
    // Fiserv tokenization works fine with YY format only
    encrypt(payload.expirationDateYear.slice(-2)),
    encrypt(payload.cvv),
  ]);

  return {
    cardNumber,
    securityCode,
    expiryDate: { month, year },
    cardType: payload.cardType,
    billingAddress: {
      postalCode: payload.zipCode,
    },
    nameOnCard: payload.cardholderName,
  };
};

export const encryptPrepaidPaymentInstrumentPayload = async (
  encrypt: EncryptFunc,
  payload: FiservTokenizePrepaidPaymentInstrumentPayload,
): Promise<FiservEncryptedPrepaidPaymentInstrumentPayload> => {
  const [cardNumber, securityCode] = await Promise.all([encrypt(payload.cardNumber), encrypt(payload.securityCode)]);
  return {
    cardNumber: `ENC_[${cardNumber}]`,
    securityCode: `ENC_[${securityCode}]`,
    alias: payload.alias,
  };
};
