import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { CardExpiredPopupController } from './card-expired-popup.controller';

const CardExpiredPopup = NiceModal.create(CardExpiredPopupController);

export const useCardExpiredPopup = () => {
  const { show } = useModal(CardExpiredPopup);
  return useMemo(() => ({ open: show }), [show]);
};
