import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useMembershipStorageNoMembershipHook } from '@ocx/data-membership';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useInfoActionSheet } from '@ocx-app/components/modals/InfoActionSheet';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';
import { logInDev } from '@ocx/utils';
import { SupportButton } from '@ocx/feat-support';
import { ReceiverStatus } from '@ocx/graphql';
import { sharedStorage } from '@ocx-app/modules/shared-storage/shared.storage';
import { diffDateTime, parseDate } from '@ocx-app/lib/date/date';

import { ShouldShowReceiverStatusMembershipPayload, shouldShowReceiverSuccessModal } from '../refer-a-friend.utils';
import { referAFriendMessages } from '../refer-a-friend.messages';
import {
  RECEIVER_EXISTING_MEMBER_ERROR_MODAL_WITHIN_MINUTES,
  RECEIVER_STATUS_ERROR_MODAL_CLOSED_AT,
  RECEIVER_STATUS_MODAL_WITHIN_DAYS,
  RECEIVER_STATUS_SUCCESS_MODAL_CLOSED_AT,
  RECEIVER_TRIED_TO_USE_CODE_AT,
} from '../refer-a-friend.constants';

const useReferralReceiverSuccessModal = () => {
  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const { open: openInfoActionSheet } = useInfoActionSheet();

  const referralProgramConfig = useConfiguration('loyaltyProgram.referralProgramConfig');
  const storage = useMembershipStorageNoMembershipHook();

  const shouldShowModal = useCallback(
    async (payload: ShouldShowReceiverStatusMembershipPayload) => {
      if (!referralProgramConfig?.referralProgramEnabled) {
        return false;
      }
      const closedSuccessModalAt = await storage.get(payload.id, RECEIVER_STATUS_SUCCESS_MODAL_CLOSED_AT);
      if (closedSuccessModalAt) {
        // Membership has already seen this modal
        return false;
      }
      return shouldShowReceiverSuccessModal(payload);
    },
    [referralProgramConfig?.referralProgramEnabled, storage],
  );

  const openModal = useCallback(
    async (payload: ShouldShowReceiverStatusMembershipPayload) => {
      try {
        // It cleans up the storage for potential overlap with error handling
        const membershipCreatedAt = parseDate(payload.createdAt);
        const isMembershipCreatedWithinShortThreshold =
          diffDateTime(new Date(), membershipCreatedAt, 'minute') <=
          RECEIVER_EXISTING_MEMBER_ERROR_MODAL_WITHIN_MINUTES;
        if (isMembershipCreatedWithinShortThreshold) {
          await sharedStorage.setItem(RECEIVER_TRIED_TO_USE_CODE_AT, null);
        }
      } catch (e) {
        logInDev(e);
      }
      triggerEvent('referral_receiver_success_modal_visible');
      openInfoActionSheet({
        title: intl.formatMessage(referAFriendMessages.referralReceiverStatusSuccessModalTitle),
        description: referralProgramConfig?.receiverConfiguration.instructions,
        variant: 'verified',
        actionButtonProps: {
          actionButtonText: intl.formatMessage(referAFriendMessages.referralReceiverStatusSuccessModalAction),
          onActionButtonClick: () => {
            triggerEvent('referral_receiver_success_modal_got_it_clicked');
          },
        },
        onExited: () => {
          storage.set(payload.id, RECEIVER_STATUS_SUCCESS_MODAL_CLOSED_AT, Date.now()).then(logInDev);
          triggerEvent('referral_receiver_success_modal_closed');
        },
      });
    },
    [intl, openInfoActionSheet, referralProgramConfig?.receiverConfiguration.instructions, storage, triggerEvent],
  );

  return useMemo(
    () => ({
      openModal,
      shouldShowModal,
    }),
    [openModal, shouldShowModal],
  );
};

const useReferralReceiverErrorModal = () => {
  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const { open: openInfoActionSheet } = useInfoActionSheet();

  const storage = useMembershipStorageNoMembershipHook();
  const referralProgramConfig = useConfiguration('loyaltyProgram.referralProgramConfig');

  const shouldShowModal = useCallback(
    async (payload: ShouldShowReceiverStatusMembershipPayload) => {
      if (!referralProgramConfig?.referralProgramEnabled) {
        return false;
      }

      // When was the last time when user tried to close error modal
      const errorModalClosedAt = await storage.get(payload.id, RECEIVER_STATUS_ERROR_MODAL_CLOSED_AT);
      // When was the last time when the user of this device tried to provide referral code
      const triedToUseCodeAt = await sharedStorage.getItem<number>(RECEIVER_TRIED_TO_USE_CODE_AT);
      const membershipCreatedAt = parseDate(payload.createdAt);

      const isCodeUsedWithinShortThreshold = triedToUseCodeAt
        ? diffDateTime(new Date(), new Date(triedToUseCodeAt), 'minute') <=
          RECEIVER_EXISTING_MEMBER_ERROR_MODAL_WITHIN_MINUTES
        : false;
      const isMembershipCreatedWithinShortThreshold =
        diffDateTime(new Date(), membershipCreatedAt, 'minute') <= RECEIVER_EXISTING_MEMBER_ERROR_MODAL_WITHIN_MINUTES;

      if (
        isMembershipCreatedWithinShortThreshold &&
        payload.incomingReferralStatus === ReceiverStatus.CodeNotRecognized
      ) {
        // This is a case for a membership registration (creation!) with an unrecognized code
        return !errorModalClosedAt;
      }

      // This is a case for a graceful period to show error for first 7 days
      // Member might go web -> native, that's why we have a threshold here
      // We check membership creation and incoming referral status
      if (payload.incomingReferralStatus === ReceiverStatus.CodeNotRecognized) {
        if (isCodeUsedWithinShortThreshold) {
          // User tried to enter one more code one more time within 7 days period
          triggerEvent('referral_code_used_by_existing_member');
          return true;
        }

        const isMembershipCreatedWithinStatusPeriod =
          diffDateTime(new Date(), membershipCreatedAt, 'day') <= RECEIVER_STATUS_MODAL_WITHIN_DAYS;
        if (isMembershipCreatedWithinStatusPeriod && !errorModalClosedAt) {
          return true;
        }
      }

      if (isCodeUsedWithinShortThreshold) {
        if (
          isMembershipCreatedWithinShortThreshold &&
          payload.incomingReferralStatus === ReceiverStatus.PendingQualifiedEvent
        ) {
          return false;
        }
        triggerEvent('referral_code_used_by_existing_member');
        return true;
      }
      return false;
    },
    [referralProgramConfig?.referralProgramEnabled, storage, triggerEvent],
  );

  const openModal = useCallback(
    async (payload: ShouldShowReceiverStatusMembershipPayload) => {
      triggerEvent('referral_receiver_error_modal_visible');
      try {
        await sharedStorage.setItem(RECEIVER_TRIED_TO_USE_CODE_AT, null);
      } catch (e) {
        logInDev(e);
      }
      openInfoActionSheet({
        variant: 'not-verified',
        title: intl.formatMessage(referAFriendMessages.referralReceiverStatusErrorModalTitle),
        description: intl.formatMessage(referAFriendMessages.referralReceiverStatusErrorModalDescription),
        isCloseButtonEnabled: true,
        renderActionButton: (onClose) => {
          return (
            <SupportButton
              onClick={() => {
                triggerEvent('referral_receiver_error_modal_contact_support_clicked');
                onClose();
              }}
            />
          );
        },
        onExited: () => {
          storage.set(payload.id, RECEIVER_STATUS_ERROR_MODAL_CLOSED_AT, Date.now()).then(logInDev);
          triggerEvent('referral_receiver_error_modal_closed');
        },
      });
    },
    [intl, openInfoActionSheet, storage, triggerEvent],
  );

  return useMemo(
    () => ({
      openModal,
      shouldShowModal,
    }),
    [openModal, shouldShowModal],
  );
};

export const useReferralReceiverStatusModal = () => {
  const successModal = useReferralReceiverSuccessModal();
  const errorModal = useReferralReceiverErrorModal();
  const showStatusModalIfNeeded = useCallback(
    async (payload: ShouldShowReceiverStatusMembershipPayload) => {
      if (await successModal.shouldShowModal(payload)) {
        await successModal.openModal(payload);
        return;
      }
      // Error modal relies on checks from success modal and at this moment can't be used standalone
      if (await errorModal.shouldShowModal(payload)) {
        await errorModal.openModal(payload);
      }
    },
    [errorModal, successModal],
  );
  return useMemo(
    () => ({
      showStatusModalIfNeeded,
    }),
    [showStatusModalIfNeeded],
  );
};
