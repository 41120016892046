import { useCallback } from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { formatPrice } from '@ocx-app/lib/formatters/formatPrice';

import { PointsStrategy } from './loyalty-currency.types';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#signdisplay
export type LoyaltyCurrencySignDisplay = Exclude<Intl.NumberFormatOptionsSignDisplay, 'never' | 'always'>;

export type FormatParams = {
  signDisplay?: LoyaltyCurrencySignDisplay;
};
export type FormatLoyaltyCurrencyParams = FormatParams & {
  pointsStrategy: PointsStrategy;
  signDisplay?: LoyaltyCurrencySignDisplay;
};

export const formatLoyaltyCurrency = (points: number, params: FormatLoyaltyCurrencyParams): string => {
  if (params.pointsStrategy === PointsStrategy.Cents) {
    return formatPrice(points, {
      signDisplay: params?.signDisplay,
    });
  }
  if (params?.signDisplay === 'exceptZero') {
    return `${points > 0 ? '+' : ''}${points}`;
  }
  return `${points}`;
};

export const useLoyaltyCurrencyFormat = () => {
  const pointsStrategy = useConfiguration('loyaltyCurrency.pointsStrategy');
  return useCallback(
    (points: number, params?: FormatParams) => formatLoyaltyCurrency(points, { pointsStrategy, ...params }),
    [pointsStrategy],
  );
};
