import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { IReceipt, IReceiptOption } from '../types';
import { TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS } from '../utils';
import { formatDateTime } from '../../../lib/date/date';
import { DateTimeFormat } from '../../../lib/date/date.types';

const messages = defineMessages({
  date: {
    id: 'receiptPage:receiptOptionDateText',
    defaultMessage: 'Date',
    description: 'Receipt page: receipt date option title',
  },
  carWash: {
    id: 'receiptPage:receiptOptionCarWashText',
    defaultMessage: 'Car Wash',
    description: 'Receipt page: receipt car wash option title',
  },
  gallons: {
    id: 'receiptPage:receiptOptionGallonsText',
    defaultMessage: 'Gallons',
    description: 'Receipt page: receipt gallons option title',
  },
  fuelTotal: {
    id: 'receiptPage:receiptOptionFuelTotal',
    defaultMessage: 'Fuel total',
    description: 'Receipt page: receipt fuel total price',
  },
  tax: {
    id: 'receiptPage:receiptOptionTaxText',
    defaultMessage: 'Tax',
    description: 'Receipt page: receipt tax option title',
  },
  paymentMethod: {
    id: 'receiptPage:paymentMethodText',
    defaultMessage: 'Payment method',
    description: 'Receipt page: payment method option title',
  },
});

export const useGetTransactionReceiptOptions = () => {
  const intl = useIntl();
  const formatPrice = useCallback(
    (price: number) => intl.formatNumber(price, TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS),
    [intl],
  );

  return useCallback(
    (receipt: IReceipt | null): IReceiptOption[] => {
      if (receipt === null) {
        return [];
      }
      const list: IReceiptOption[] = [];
      list.push({
        title: intl.formatMessage(messages.date),
        value: formatDateTime(new Date(receipt.date), DateTimeFormat.DateSlashedTimeFull),
      });
      if (receipt.carWash) {
        list.push({
          title: intl.formatMessage(messages.carWash),
          value: formatPrice(receipt.carWash.price),
        });
      }

      if (receipt.gallons) {
        list.push({
          title: intl.formatMessage(messages.gallons),
          value: intl.formatNumber(receipt.gallons, { style: 'decimal', maximumFractionDigits: 4 }),
        });
      }

      if (receipt.fuelTotal) {
        list.push({
          title: intl.formatMessage(messages.fuelTotal),
          value: formatPrice(receipt.fuelTotal),
        });
      }

      if (receipt.tax !== null) {
        list.push({
          title: intl.formatMessage(messages.tax),
          value: formatPrice(receipt.tax),
        });
      }

      if (receipt.paymentInstrument) {
        const { paymentType, last4 } = receipt.paymentInstrument;

        list.push({
          title: intl.formatMessage(messages.paymentMethod),
          value: last4 ? `•••• •••• •••• ${last4}` : undefined,
          icon: paymentType ? <PaymentInstrumentIcon type={paymentType} /> : undefined,
        });
      }
      return list;
    },
    [intl, formatPrice],
  );
};
