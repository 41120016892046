import { formatDecimal } from './formatDecimal';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#signdisplay
export type FormatPriceSignDisplay = Exclude<Intl.NumberFormatOptionsSignDisplay, 'never' | 'always'>;

export type FormatPriceOptions = {
  displayCentsWhenLessThanDollar?: boolean;
  signDisplay?: FormatPriceSignDisplay;
};

export const formatPrice = (cents: number, options?: FormatPriceOptions) => {
  if (options?.displayCentsWhenLessThanDollar && cents < 100) {
    if (options.signDisplay === 'exceptZero') {
      return `${cents > 0 ? '+' : ''}${cents}¢`;
    }
    return `${cents}¢`;
  }

  const dollars = convertCentsToDollars(cents);
  const maximumFractionDigits = 2;
  const formattedPrice = formatDecimal(dollars, maximumFractionDigits);

  return new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    signDisplay: options?.signDisplay,
  }).format(formattedPrice);
};

export const convertCentsToDollars = (cents: number): number => cents / 100;

export const convertDollarsToCents = (dollars: number): number => dollars * 100;
