// TODO: consider if that's appropriate to remove dependency on @ocx/graphql
// It's a copy-paste from @ocx/graphql, that's why it's an enum
export enum PointsStrategy {
  Cents = 'CENTS',
  NotUsed = 'NOT_USED',
  Points = 'POINTS',
}

type LoyaltyCurrencyIconVariant = 'single' | 'multiple';
type LoyaltyCurrencyIconConfig = Record<LoyaltyCurrencyIconVariant, string>;

type LoyaltyCurrencyConfig =
  | {
      pointsStrategy: PointsStrategy.NotUsed;
      iconConfig: null;
    }
  | {
      pointsStrategy: PointsStrategy.Points | PointsStrategy.Cents;
      iconConfig: LoyaltyCurrencyIconConfig;
    };

export type { LoyaltyCurrencyIconVariant, LoyaltyCurrencyIconConfig, LoyaltyCurrencyConfig };
