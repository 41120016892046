import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const AlertIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="m12.0001 22.0251c-.2667 0-.5207-.05-.762-.15-.242-.1-.463-.2417-.663-.425l-8.025-8.025c-.18334-.2-.325-.421-.425-.663-.1-.2413-.15-.4953-.15-.762s.05-.525.15-.775.24166-.4667.425-.65l8.025-8.025c.2-.2.421-.346.663-.438.2413-.09134.4953-.137.762-.137s.525.04566.775.137c.25.092.4667.238.65.438l8.025 8.025c.2.1833.346.4.438.65.0913.25.137.5083.137.775s-.0457.5207-.137.762c-.092.242-.238.463-.438.663l-8.025 8.025c-.1833.1833-.4.325-.65.425s-.5083.15-.775.15zm0-2 8.025-8.025-8.025-8.025-8.025 8.025zm-1-7.025h2v-6h-2zm1 3c.2833 0 .521-.096.713-.288.1913-.1913.287-.4287.287-.712s-.0957-.521-.287-.713c-.192-.1913-.4297-.287-.713-.287s-.5207.0957-.712.287c-.192.192-.288.4297-.288.713s.096.5207.288.712c.1913.192.4287.288.712.288z" />
    </SvgIcon>
  );
};
