import { Box } from '@mui/material';

import { Typography, TypographyProps, SizingMultiplier } from '@ocx/ui';

import { LoyaltyCurrencyIconVariant } from './loyalty-currency.types';
import { LoyaltyCurrencyIcon } from './loyalty-currency-icon.component';
import { useLoyaltyCurrencyFormat } from './use-loyalty-currency-format.hook';

export type LoyaltyCurrencyChangeProps = {
  points: number;
  template?: 'icon-value' | 'value-icon';
  iconSize?: 'small' | 'large';
  iconVariant?: LoyaltyCurrencyIconVariant;
  typographyProps?: TypographyProps;
};

export const LoyaltyCurrencyChange = ({
  points,
  template = 'icon-value',
  iconSize = 'small',
  iconVariant = 'single',
  typographyProps,
}: LoyaltyCurrencyChangeProps) => {
  const format = useLoyaltyCurrencyFormat();
  const isPositionRight = template === 'value-icon';

  // TODO: should this component return null if points are not used?

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <LoyaltyCurrencyIcon variant={iconVariant} size={iconSize} />
      <Typography
        variant="subtitle2"
        component="span"
        sx={{
          order: isPositionRight ? -1 : 1,
          wordBreak: 'keep-all',
          mr: isPositionRight ? SizingMultiplier.xs : 0,
          ml: !isPositionRight ? SizingMultiplier.xs : 0,
        }}
        {...typographyProps}>
        {format(points, { signDisplay: 'exceptZero' })}
      </Typography>
    </Box>
  );
};
