import React from 'react';
import { Box } from '@mui/material';

export type TabPanelProps = {
  children: React.ReactNode;
  name: string;
  value: string;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, name } = props;
  return (
    <Box
      display={value === name ? 'flex' : undefined}
      flex="1"
      flexDirection="column"
      role="tabpanel"
      hidden={value !== name}
      id={`transaction-history-tabpanel-${name}`}
      aria-labelledby={`transaction-history-tabpanel-${name}`}>
      {value === name && children}
    </Box>
  );
};
